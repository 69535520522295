import React, { Component, createRef } from 'react'
import { throttle } from 'throttle-debounce'
import PropTypes from 'prop-types'

import { isSupported } from 'utils/feature-detection'

class InView extends Component {
  state = { inview: false }

  events = ['scroll', 'resize']
  target = createRef()

  constructor(props) {
    super(props)
    this.throttledScroll = throttle(125, this.handleEvent)
  }

  componentDidMount = () => {
    const { progressive } = this.props

    if (isSupported('intersection')) {
      return this.registerObserver()
    }

    if (!progressive) {
      this.registerEventListeners()
      this.handleEvent()
    } else {
      this.setState({ inview: true })
    }
  }
  componentWillUnmount = () => {
    this.removeEventListeners()
    this.removeObserver()
  }

  detectTargetInViewport = () => {
    const node = this.target.current
    if (!node) return false

    const { top, bottom } = node.getBoundingClientRect()

    return bottom > 0 && window.innerHeight - top > 0
  }
  handleTargetInViewport = () => {
    const { onEnterView, once } = this.props
    this.setState({ inview: true })

    if (typeof onEnterView === 'function') {
      onEnterView()
    }

    if (once) {
      this.removeEventListeners()
      this.removeObserver()
    }
  }

  registerObserver = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
      ...this.props.observer
    }
    this.observer = new IntersectionObserver(this.handleObservation, options)
    this.observer.observe(this.target.current)
  }
  handleObservation = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.handleTargetInViewport()
        this.observer.disconnect()
      }
    })
  }

  registerEventListeners = () => {
    this.events.forEach(event =>
      window.addEventListener(event, this.throttledScroll, false)
    )
  }
  handleEvent = () => {
    if (this.detectTargetInViewport()) {
      this.handleTargetInViewport()
    }
  }

  removeObserver = () => {
    if (this.observer) this.observer.disconnect()
  }
  removeEventListeners = () => {
    this.events.forEach(event =>
      window.removeEventListener(event, this.throttledScroll, false)
    )
  }

  render() {
    const { children } = this.props

    if (typeof children === 'function') {
      return children({
        ...this.state,
        target: this.target
      })
    }

    return <div ref={this.target} />
  }
}

InView.propTypes = {
  observer: PropTypes.shape({
    root: PropTypes.node,
    rootMargin: PropTypes.string,
    threshold: PropTypes.number
  }),
  once: PropTypes.bool,
  onEnterView: PropTypes.func,
  progressive: PropTypes.bool
}

InView.defaultProps = {
  progressive: false
}

export default InView
