import React from 'react'

const IconUser = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.688"
    height="26.445"
    viewBox="0 0 22.688 26.445"
  >
    <g transform="translate(-4.382 -1.643)">
      <path
        d="M13.227,12.69a8.348,8.348,0,0,1,6.681-8.217c-2.5-.881-5.345-1-7.136.142C9.247,6.89,15.587,14.055,10.1,13.941c0,0,.739,1.791,3.81,2.161A9.129,9.129,0,0,1,13.227,12.69Z"
        transform="translate(-5.718 -2.144)"
      />
      <path
        d="M35.829,38.23c-3.042,0-5.459-4.009-5.629-7.62,3.213-.256,7.961-3.1,8.757-3.611a2.991,2.991,0,0,1,2.474,3.184C41.459,33.909,39.014,38.23,35.829,38.23Z"
        transform="translate(-20.104 -18.757)"
      />
      <g transform="translate(8.362 3.04)">
        <path
          d="M24.981,17.087a.437.437,0,0,1,.426-.426c3.184,0,8.814-3.526,8.871-3.554a.306.306,0,0,1,.227-.057c1.9,0,3.469,1.82,3.469,4.066a10.824,10.824,0,0,1-.625,3.611,7.364,7.364,0,1,0-11.771,0A11.761,11.761,0,0,1,24.981,17.087Z"
          transform="translate(-24.1 -8.7)"
        />
      </g>
      <g transform="translate(4.382 19.018)">
        <path
          d="M24.714,64.9a4.967,4.967,0,0,1-3.27,1.336,5.113,5.113,0,0,1-3.27-1.336c-1.876,2.729-8.075.938-8.075,6.6V73.97H32.788V71.5C32.788,65.81,26.59,67.6,24.714,64.9Z"
          transform="translate(-10.1 -64.9)"
        />
      </g>
    </g>
  </svg>
)

export default IconUser
