import React, { Component } from 'react'
import loadable from '@loadable/component'
import Helmet from 'react-helmet'
import { parse } from 'query-str-es5'

import { Box, Cardiff } from 'design-system'

import { ComponentErrorBoundary } from '_utility/error-boundary'

import { Wrapper, Grid, Col } from 'partials/grid'
import Base from 'partials/_base'
import CardExtended from 'partials/card-extended'
import IconLoading from 'partials/icon-loading'
import ListPostsLoader from 'partials/list-posts-loader'
import SectionWrapper from 'partials/section-wrapper'
import SubHeading from 'partials/sub-heading'

const SiteSidebar = loadable(() => import('../../partials/site-sidebar'))

const metaData = {
  name: 'Stylist Search Results',
  description: 'Search Results | Stylist, the online home of Stylist Magazine'
}

class Search extends Component {
  state = {
    q: ''
  }

  componentDidMount() {
    if (/(\?)q(=$)/.test(window.location.search)) return false

    const { q } = parse(window.location.search)
    this.setState({ q }, () => window.scrollTo(0, 0))
  }

  render() {
    return (
      <Base>
        <Helmet
          title={metaData.name}
          meta={[
            {
              name: 'twitter:title',
              property: 'og:title',
              content: metaData.name
            },
            {
              name: 'twitter:description',
              property: 'og:description',
              content: metaData.description
            },
            {
              name: 'robots',
              content: 'noindex, follow'
            }
          ]}
        />
        <Wrapper width="sm">
          <SectionWrapper tag="header">
            <SubHeading large tag="h1" text="Search results" />
          </SectionWrapper>
        </Wrapper>
        <Wrapper>
          <SectionWrapper>
            <Grid>
              <Col md={2 / 3}>
                {this.state.q ? (
                  <ListPostsLoader
                    baseApi="search"
                    fetchOnMount
                    params={{
                      page: 1,
                      per_page: 24,
                      s: this.state.q
                    }}
                  >
                    {({ posts, status }) => {
                      if (status === 'error') {
                        return (
                          <Box display="flex" height={80}>
                            <Cardiff m="auto">{'No results'}</Cardiff>
                          </Box>
                        )
                      }
                      return posts.map((post, i) => (
                        <CardExtended {...post} key={i} />
                      ))
                    }}
                  </ListPostsLoader>
                ) : (
                  <Box display="flex" height={80} overflow="hidden">
                    <IconLoading color="primary" size={8} />
                  </Box>
                )}
              </Col>
              <ComponentErrorBoundary>
                <SiteSidebar />
              </ComponentErrorBoundary>
            </Grid>
          </SectionWrapper>
        </Wrapper>
      </Base>
    )
  }
}

export default Search
