import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'
import { layout } from 'style/tokens'

const Svg = styled('svg')(
  ({ theme }) => `
    color: ${theme.colors.white};
    display: block;
    height: 1rem;
    margin-left: auto;
    margin-right: auto;
    max-width: ${layout.trunk.xs};
    stroke-width: 2;
    stroke: ${theme.colors.primary};
    width: 60%;
  `
)

const SquigglyLine = () => (
  <Svg viewBox="0 0 311 8" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1c4 0 4 6 8.2 6 4 0 4-6 8.1-6s4.1 6 8.2 6c4 0 4-6 8.2-6s4 6 8.1 6S45 1 49 1s4.1 6 8.2 6c4 0 4-6 8.2-6 4 0 4 6 8.1 6s4.1-6 8.2-6c4 0 4 6 8.2 6 4 0 4-6 8.1-6s4.1 6 8.2 6c4 0 4-6 8.2-6 4 0 4 6 8.1 6s4.1-6 8.2-6c4 0 4 6 8.2 6 4 0 4-6 8.1-6s4.1 6 8.2 6 4.1-6 8.2-6 4 6 8.2 6c4 0 4-6 8.1-6s4.1 6 8.2 6c4 0 4-6 8.2-6s4 6 8.1 6 4.1-6 8.2-6 4 6 8.2 6 4-6 8.2-6c4 0 4 6 8.1 6s4.1-6 8.2-6 4 6 8.2 6 4-6 8.1-6 4.1 6 8.2 6 4.1-6 8.2-6c4 0 4 6 8.2 6 4 0 4-6 8.1-6s4.1 6 8.2 6 4.1-6 8.2-6" />
  </Svg>
)

SquigglyLine.propTypes = {
  category: PropTypes.string
}

export default SquigglyLine
