// Regexes for capturing the following html content

// <hr/>
// <hr />
export const hrElement = /^<hr\s?\/>$/

// <meta charset=“utf-8” />
// <p><meta charset=“utf-8” /></p>
export const metaTag = /^(<p>)?<meta charset=“utf-8”\s?\/>(<\/p>)?$/

// <!-- IFRAME WRAP -->
// <!--[endif]-->
export const htmlComments = /^<!--\s?[?[a-zA-Z]*\s?[a-zA-Z]*]?\s?-->$/

// &nbsp;
// <div>&nbsp;</div>
// <div>&nbsp;&nbsp;</div>
// <div>&nbsp;&nbsp;....</div>
export const htmlEntitySpace = /^(<div>)?(&nbsp;)*(<\/div>)?$/

export default [hrElement, metaTag, htmlComments, htmlEntitySpace]
