import { css } from 'emotion'

import { color } from 'style/tokens'
import rem from 'style/utils/rem'
import ratio from 'style/utils/ratio'

import { getPlaceholderSize, getSlotType } from './_helpers'

const handleViewport = ({ style = '', viewport }) => {
  // no need to wrap in a media-query
  if (!viewport) return style
  // utilise min and max width media queries
  const [min, max] = viewport
  const mediaMin = min ? `(min-width: ${rem(min, true)})` : ''
  const mediaMax = max ? `(max-width: ${rem(max - 1, true)})` : ''
  // return styles wrapped in a media-query
  return `
    @media ${mediaMin} ${min && max ? 'and' : ''} ${mediaMax} {
      ${style};
    }
  `
}
const placeholderSize = ({ context, size, viewport, type }) => {
  let style
  const centering = ({ context, width }) => {
    if (context !== 'in-content' || width <= 700) return ''
    return `
      margin-left: 50%;
      max-width: none;
      transform: translateX(-50%);
    `
  }
  // empty
  if (type === 'empty') {
    style = `
      display: none;
    `
  }
  // standard ads will render to a set size
  // we expect a single height and width array
  // size = [height, width]
  if (type === 'standard') {
    const [width, height] = size
    style = `
      ${centering({ context, width })}
      height: ${rem(height)};
      width: ${rem(width)};
    `
  }
  // fluid ads will render to a min and max ratio container
  // we expect a nested height and width array
  // size = [[min-height, min-width], [max-height, max-width]]
  if (type === 'fluid') {
    const [min, max] = getPlaceholderSize(size)
    style = `
      ${centering({ context, width: min[0] })}
      height: 100%;
      max-width: ${rem(max[0])};
      min-width: ${rem(min[0])};
      width: 100%;
    `
  }
  // use method to add a media-query if required
  return handleViewport({ style, viewport })
}

const root = empty => css`
  background-color: ${empty ? color.white : color.tonal[0]};
  display: flex;
  margin: auto;
  overflow: hidden;
  position: relative;
  ${!empty &&
    `
    ::before {
      color: #a0a0a0;
      content: 'Advertisement';
      font-family: -apple-system, sans-serif;
      font-size: ${rem(8)};
      left: 50%;
      margin-top: ${rem(10)};
      position: absolute;
      text-transform: uppercase;
      transform: translateX(-50%);
      z-index: 0;
    }
  `};
`

const placeholder = ({ sizes, context }) =>
  css(
    sizes.map(({ slot, viewport, placeholder }, i) =>
      placeholderSize({
        context,
        size: placeholder || slot,
        type: getSlotType(placeholder || slot),
        viewport: [viewport, sizes[i + 1] && sizes[i + 1].viewport]
      })
    )
  )

const render = ({ renderedSize, context }) => {
  if (!renderedSize) return null

  const centering = ({ context, width, ...rest }) => {
    if (context !== 'in-content' || width <= 700) return {}
    return {
      marginLeft: '50%',
      transform: 'translateX(-50%)',
      WebkitTransform: 'translateX(-50%)',
      ...rest
    }
  }

  if (getSlotType(renderedSize) === 'fluid') {
    const [min, max] = renderedSize
    return {
      height: '100%',
      maxWidth: max[0],
      minWidth: min[0],
      width: '100%',
      ...centering({ context, width: min[0] })
    }
  }
  const [width, height] = renderedSize
  return {
    height,
    maxHeight: '100%',
    maxWidth: '100%',
    width,
    ...centering({ context, width, maxWidth: 'none' })
  }
}

const container = ({ sizes, renderedSize }) => {
  const style = size => {
    const type = getSlotType(size)
    if (type === 'standard') {
      return `
        height: 100%;
        width: 100%;
      `
    }
    if (type === 'fluid') {
      const [width, height] = getPlaceholderSize(size)[0]
      return `
        padding-top: ${ratio(height / width)};
        width: 100%;
      `
    }
    return ''
  }
  // styles to apply to the ad itself
  const styleAdvert = `
    div,
    iframe {
      height: 0px !important;
      left: 0;
      min-height: 100% !important;
      min-width: 100% !important;
      position: absolute;
      top: 0;
      width: 0px !important;
    }
  `
  if (renderedSize) {
    // renderedSize may return a nested array
    // with min/max widths. If so, we know it's fluid
    return css(styleAdvert, style(renderedSize))
  }

  if (!renderedSize) {
    return css(
      styleAdvert,
      sizes.filter(Boolean).map(({ slot, viewport, placeholder }, i) =>
        handleViewport({
          style: style(placeholder || slot),
          viewport: [viewport, sizes[i + 1] && sizes[i + 1].viewport]
        })
      )
    )
  }
}

export default { root, placeholder, render, container }
