import { convertToUTC } from 'utils/date'
import { isPost } from 'utils/croissant'
import pruneFalsyValues from 'utils/prune-falsy-values'
import { hasEntries } from 'utils/array'

export default props => {
  /*
    Expected output
    {
      type: '<STRING>',
      article: {
        type: '<STRING>',
        id: '<STRING>',
        author: '<STRING>',
        slug: '<STRING>',
        headline: '<STRING>',
        parent_category: '<STRING>',
        category: '<STRING>',
        tags: ['<STRING>'],
        published_at: '<DATE/TIME>',
        series: '<STRING>',
        paid: '<BOOL>',
        project_id: '<STRING>'
      },
      tagbot_data: {
        emotions: {
          anger: '<FLOAT>',
          disgust: '<FLOAT>',
          fear: '<FLOAT>',
          joy: '<FLOAT>',
          sadness: '<FLOAT>'
        },
        sentiment: {
          name: '<STRING>',
          score: '<FLOAT>'
        },
        categories: [{
          name: '<STRING>',
          score: '<FLOAT>'
        }],
      }
    }
  */

  const isArticle = isPost(props.type)

  let data = {
    type: props.type,
    id: parseInt(props.id, 10),
    slug: props.slug
  }
  let tagbot_data = {}
  const series = props.acf?.series?.name || null

  if (isArticle) {
    data = {
      ...data,
      author: props._embedded?.author?.[0]?.slug, // NEEDS DOING
      category: props.acf?.category?.slug,
      headline: props.title.rendered,
      parent_category: props.acf?.category?.parent?.slug,
      published_at: convertToUTC(props.date_gmt),
      series,
      paid: hasEntries(props.acf?.package_ids)
    }
    if (props.acf?.tags?.[0]) {
      data.tags = props.acf.tags.map(tag => tag.slug)
    }
    if (props.acf?.project_id) {
      data.project_id = props.acf.project_id
    }
  }

  if (props.acf?.tagbot_data) {
    const { sentiment, emotions, categories } = props.acf.tagbot_data

    tagbot_data = pruneFalsyValues(
      {
        emotions,
        category_list: categories,
        sentiment: sentiment
      },
      { pruneEmpty: true }
    )
  }

  return {
    type: isArticle ? 'article' : 'home',
    article: data,
    tagbot_data
  }
}
