import { isTaxonomy, isFrontPage } from 'utils/croissant'

import getPermutiveTargeting from './get-permutive-targeting'
import getGptTargeting from './get-gpt-targeting'
import getRelevantBidders from './get-relevant-bidders'

export { getPermutiveTargeting, getGptTargeting, getRelevantBidders }

const isMultiSize = size => size && Array.isArray(size[0])

const adRef = ([width, height] = []) => `${width}x${height}`

const fluidSlots = {
  '1x1': { sizes: [[300, 300], [450, 450]], ids: [11824119, 11815262] },
  '9x16': { sizes: [[300, 533], [450, 800]], ids: [11815265, 11824122] },
  '4x1': { sizes: [[900, 225], [1800, 450]], ids: [11817036, 11821500] },
  '10x1': { sizes: [[900, 90], [1800, 180]], ids: [11815259, 11823097] }
}
const maxFluidSlotSizes = {
  '4x1': [1800, 450],
  '10x1': [1800, 180],
  '1x1': [450, 450],
  '9x16': [450, 800]
}

export const getMaxSlotSize = size => maxFluidSlotSizes[size]

export const getSlotType = size => {
  const [width, height] = size
  if (width === 0 || height === 0) return 'empty'
  if (Array.isArray(width) || (width <= 20 && height <= 20)) return 'fluid'
  return 'standard'
}

export const getPlaceholderSize = size =>
  fluidSlots[adRef(size)] ? fluidSlots[adRef(size)].sizes : size

export const isFluid = ([width, height]) => width < 100 && height < 100

export const getRenderSizeFromTemplate = id => {
  const size = Object.keys(fluidSlots).filter(
    key => fluidSlots[key].ids.indexOf(id) > -1
  )
  return fluidSlots[size].sizes
}

export const getGPTSlotSize = ({ slotSize }) => {
  // convert all fluid slot sizes to the string "fluid"
  const fluidise = size => (isFluid(size) ? 'fluid' : size)
  // return array with unique options, no dupes of "fluid"
  return isMultiSize(slotSize)
    ? slotSize.map(fluidise).filter((val, i, self) => self.indexOf(val) === i)
    : fluidise(slotSize)
}

export const getGPTTargeting = ({ targeting, slotSize }) => ({
  ...targeting,
  fluidSlotSize: isMultiSize(slotSize)
    ? slotSize.map(adRef).join(',')
    : adRef(slotSize)
})

const getGPTZone = (data = {}) => {
  if (isFrontPage(data)) return 'home'
  if (isTaxonomy(data.taxonomy)) return 'channel'
  return data.type || 'default'
}

export const getAdUnitPath = data => `${__DFP_NETWORK__}/${getGPTZone(data)}`
