import { useState, useCallback } from 'react'

import { bugsnag } from '_utility/error-boundary'

const usePostRequest = (initialPath, returnRegardlessOfResult = false) => {
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(false)

  const submitPostRequest = async (args, path) =>
    await fetch(initialPath || path, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(args)
    }).then(res => res.json())

  const submitRequest = useCallback(
    (args, path) => {
      let error = ''
      let response = false
      setIsPending(true)
      setError(false)

      return submitPostRequest(args, path)
        .then(postResponse => {
          if (
            postResponse.success ||
            postResponse.status === 200 ||
            returnRegardlessOfResult
          ) {
            response = { ...postResponse, success: true }
          } else {
            response = { success: false }
            error = postResponse.error || 'An error occurred'
            bugsnag.notify(
              JSON.stringify({
                type: 'Post request error',
                error: postResponse
              })
            )
          }
          return response
        })
        .catch(e => {
          error = 'An error occurred'
          bugsnag.notify(
            JSON.stringify({
              type: 'Post request error',
              stackTrace: e.stack,
              error: e
            })
          )
        })
        .finally(() => {
          setError(error)
          setIsPending(false)
          setResponse(response)
        })
    },
    [submitPostRequest]
  )

  return { submitRequest, isPending, error, response }
}

export default usePostRequest
