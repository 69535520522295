import React from 'react'
import isPlainObject from 'lodash.isplainobject'
import PropTypes from 'prop-types'

import Anchor from 'partials/anchor'
import Image from 'partials/image'

import styles from './style'

const WidgetImage = ({
  image,
  mobile_image,
  crop,
  link,
  width,
  postType,
  schema,
  isSeries
}) => {
  const hasImage = isPlainObject(image)

  if (!hasImage) {
    return null
  }

  let img
  const hasMobileImage = isPlainObject(mobile_image)

  if (hasMobileImage) {
    img = (
      <>
        <div className={styles.imageDesktop}>
          <Image image={image} crop={crop} schema={schema} />
        </div>
        <div className={styles.imageMobile}>
          <Image image={mobile_image} />
        </div>
      </>
    )
  } else {
    img = <Image image={image} crop={crop} />
  }

  if (link) {
    img = <Anchor url={link}>{img}</Anchor>
  }

  // hardcoded fix, need to do this better after
  return isSeries ? (
    img
  ) : (
    <figure className={styles.root(postType, width)}>
      {img}
      {image.caption && (
        <figcaption
          className={styles.caption(postType, width)}
          dangerouslySetInnerHTML={{ __html: image.caption }}
        />
      )}
    </figure>
  )
}

WidgetImage.propTypes = {
  caption: PropTypes.string,
  width: PropTypes.oneOf(['small', 'medium', 'large', 'full'])
}

export default WidgetImage
