import React, { useState, useEffect } from 'react'
import { withTheme } from 'emotion-theming'
import { Box } from 'design-system'

import Anchor from 'partials/anchor'

import DownArrow from 'assets/down-arrow'
import UpArrow from 'assets/up-arrow'

import siteData from 'config/data.json'

import { trackEvent } from 'utils/analytics-trackers'

import styles from './style'

const SWTCDropDownMenu = ({ theme }) => {
  const { channels } = siteData.stongWomenTrainingClubBannerMenu

  const [open, setOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState({})
  const [pages, setPages] = useState(channels)
  const [hideNav, setHideNav] = useState(false)

  const trackCategoryClick = (e, item) => {
    e.preventDefault()
    trackEvent(
      `Clicked on SWTC Site Navigation Dropdown category: ${item.text}`,
      'SWTC Site Navigation Dropdown',
      item.text
    )
    window.location.href = item.url
  }

  const handleToggle = e => {
    e.preventDefault()
    setOpen(!open)
  }

  useEffect(
    () => {
      if (open) {
        trackEvent('Open', 'SWTC dropdown menu', `SWTC dropdown menu expanded`)
      }

      const getEndpoint = () => {
        const currentPath = window.location.pathname
        const findEndpoint = path => channels.find(item => item.url === path)

        return findEndpoint(currentPath, false)
      }

      const page = getEndpoint()
      if (page) {
        setCurrentPage({
          text: page.text,
          url: page.url
        })
        setPages(pages.filter(item => item !== page))
      } else {
        setHideNav(true)
      }
    },
    [open]
  )

  if (hideNav) return null

  return (
    <div className={styles.toggleContainer}>
      <Box display="flex" flexDirection="row" width="100%" alignItems="center">
        <Box
          css={`
            margin-left: auto;
            margin-right: auto;
            line-height: 40px;
          `}
        >
          <Anchor
            className={styles.link(theme)}
            url={currentPage.url}
            onClick={() => trackCategoryClick(currentPage.url)}
          >
            {currentPage.text}
          </Anchor>
        </Box>
        <Box
          css={`
            position: absolute;
            top: 0;
            right: 0;
          `}
        >
          <button
            aria-expanded={open}
            className={styles.toggle}
            onClick={e => handleToggle(e)}
          >
            {open ? <UpArrow /> : <DownArrow />}
          </button>
        </Box>
      </Box>
      {open && (
        <div>
          {pages.map(item => (
            <>
              <Box display="flex" flexDirection="column" width="100%">
                <Anchor
                  className={styles.link(theme)}
                  url={item.url}
                  onClick={e => {
                    trackCategoryClick(e, item)
                    setCurrentPage(item)
                    setOpen(!open)
                  }}
                >
                  {item.text}
                </Anchor>
              </Box>
            </>
          ))}
        </div>
      )}
    </div>
  )
}

export default withTheme(SWTCDropDownMenu)
