import React from 'react'
import { generate as uid } from 'shortid'
import PropTypes from 'prop-types'

import Image from 'widgets/image'
import Embed from 'widgets/embed'
import itemOrder from './helpers/item-order'
import Button from 'partials/button'
import LoopingVideo from 'widgets/looping-video'

import styles from './style'

const Listicle = props => {
  const Tag = props.numbered ? 'ol' : 'ul'
  const { items } = itemOrder(props)
  return (
    <Tag className={styles.list}>
      {items.map(item => {
        const index = item.index ? `${item.index}.` : null
        const renderButton = item.url && item.label
        return (
          <li className={styles.listItem} key={uid()}>
            {index && <span className={styles.index}>{index}</span>}
            {item.title && (
              <h3
                className={styles.title}
                dangerouslySetInnerHTML={{
                  __html: item.title
                }}
              />
            )}
            {item.media_type === 'image' && (
              <>
                <figure style={{ position: 'relative' }}>
                  <Image
                    className={styles.listicleImage}
                    image={item.image}
                    mobile_image={item.mobile_image}
                    postType={props.postType}
                    crop={item.image_crop}
                    width={item.width}
                  />
                </figure>
                {item.paragraph && (
                  <div
                    className={styles.paragraph}
                    dangerouslySetInnerHTML={{
                      __html: item.paragraph
                    }}
                  />
                )}
              </>
            )}
            {item.media_type === 'embed' && (
              <Embed
                embed={item.embed}
                mobile_embed={item.mobile_embed}
                caption={item.paragraph}
                postType={props.postType}
                width={item.width}
              />
            )}
            {item.media_type === 'loop' && (
              <>
                <LoopingVideo
                  video={item.video}
                  placeholder={item.placeholder}
                  mobile_video={item.mobile_video || false}
                  mobile_placeholder={item.mobile_placeholder || false}
                  width={item.width}
                  postType={props.postType}
                />
                {item.paragraph && (
                  <div
                    className={styles.paragraph}
                    dangerouslySetInnerHTML={{
                      __html: item.paragraph
                    }}
                  />
                )}
              </>
            )}
            {renderButton && (
              <div className={styles.button}>
                <Button url={item.url} postType={props.postType}>
                  {item.label}
                </Button>
              </div>
            )}
          </li>
        )
      })}
    </Tag>
  )
}

Listicle.propTypes = {
  item: PropTypes.arrayOf(
    PropTypes.shape({
      paragraph: PropTypes.string.isRequired,
      embed: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
      image_crop: PropTypes.string,
      media_type: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired,
  list_options: PropTypes.array
}

export default Listicle
