import React from 'react'
import PropTypes from 'prop-types'

import styles from './style'

const Missing = props => {
  if (NODE_ENV === 'production') return null
  return (
    <div className={styles.root}>
      <h3 className={styles.title}>Missing Widget: {props.acf_fc_layout}</h3>
    </div>
  )
}

Missing.propTypes = {
  acf_fc_layout: PropTypes.string.isRequired
}

export default Missing
