import React, { Component } from 'react'
import isEqual from 'react-fast-compare'

import AssetsContext from '_utility/context-assets'
import CMPContext from '_utility/context-cmp'
import getUserData from '_utility/get-user-data'

import {
  getAdUnitPath,
  getGptTargeting,
  getPermutiveTargeting
} from 'partials/advert-slot/_helpers'

import detectStorageSupport from 'utils/detect-storage-support'
import { trackEvent } from 'utils/analytics-trackers'

const getPbjsConfig = tcString => {
  return {
    debug: true,
    priceGranularity: {
      buckets: [
        {
          precision: 2,
          min: 0,
          max: 3,
          increment: 0.01
        },
        {
          precision: 2,
          min: 3,
          max: 8,
          increment: 0.05
        },
        {
          precision: 2,
          min: 8,
          max: 20,
          increment: 0.5
        }
      ]
    },
    consentManagement: {
      gdpr: {
        cmpApi: 'iab',
        timeout: 8000,
        allowAuctionWithoutConsent: false,
        consentData: {
          getTCData: {
            tcString,
            gdprApplies: true
          }
        }
      }
    },
    userSync: {
      /* http://prebid.org/dev-docs/publisher-api-reference.html */
      filterSettings: {
        iframe: {
          bidders: '*', // '*' means all bidders
          filter: 'include'
        }
      }
    },
    currency: {
      adServerCurrency: 'GBP',
      granularityMultiplier: 1,
      defaultRates: { USD: { GBP: 0.8 } }
    },
    realTimeData: {
      dataProviders: [
        {
          name: 'permutive',
          waitForIt: false,
          params: {
            acBidders: ['appnexus']
          }
        }
      ]
    }
  }
}

const AdvertContext = React.createContext({ shouldRender: true })

export const shouldDisplayAdvert = (props = {}) => {
  if (props.acf?.disable_ads) return false

  const hideUntil = props.acf?.disable_advert_slots
  // parse hideUntil, if integer then assume its a "date"
  // ACF returns seconds, so we're adding some milliseconds on the end
  // this is bad, however I don't know how we can get around this
  const hideEndDate = parseInt(hideUntil + '000', 10)
  // if hideUntil is falsey or the parseInt returns NaN then lets not hide
  if (!hideUntil || !hideEndDate) return true
  // if our hideEndDate is after curent time, show children
  return new Date().getTime() >= hideEndDate
}

class Advert extends Component {
  state = {
    ready: false,
    permutiveLogicDone: false
  }

  constructor(props) {
    super(props)

    if (typeof window !== 'undefined') {
      // define global apis for gpt & prebid
      window.pbjs = window.pbjs || {}
      window.pbjs.que = window.pbjs.que || []
      window.googletag = window.googletag || {}
      window.googletag.cmd = window.googletag.cmd || []

      window.googletag.cmd.push(() => {
        const gptTargeting = getGptTargeting(props)
        Object.keys(gptTargeting).forEach(key => {
          window.googletag.pubads().setTargeting(key, gptTargeting[key])
        })

        if (!window.googletag.pubads().isInitialLoadDisabled()) {
          window.googletag.pubads().disableInitialLoad()
        }
      })
    }
  }

  componentDidMount = () => {
    this.props.loadAsset(
      'https://www.googletagservices.com/tag/js/gpt.js',
      'gpt'
    )
  }

  shouldComponentUpdate = (nextProps, nextState) =>
    !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)

  componentDidUpdate() {
    const { purposeConsents, assets = {}, loadAsset, consentSaved } = this.props
    const { adId } = getUserData()

    if (window.permutive && !this.state.permutiveLogicDone) {
      this.setState({ permutiveLogicDone: true })

      // must be the first thing to be called
      adId &&
        window?.permutive?.identify([
          {
            id: adId,
            tag: 'email_sha256'
          }
        ])

      // create a "page" addon for permutive
      window.permutive.addon('web', {
        page: getPermutiveTargeting(this.props)
      })

      const permutiveId = window.permutive?.context?.user_id

      permutiveId && trackEvent('permutiveID', 'Permutive', permutiveId)

      // update googletag key-value pairs from local storage
      window.googletag.cmd.push(() => {
        // dont even do anything without local storage
        if (!detectStorageSupport('localStorage')) return

        let kvs = window.localStorage.getItem('_pdfps')
          ? JSON.parse(window.localStorage.getItem('_pdfps'))
          : []

        // only send to googletag if local storage looks legit
        if (kvs.length > 0) {
          window.googletag.pubads().setTargeting('permutive', kvs)
        }
      })
      return
    }

    if (!consentSaved && this.state.ready) return

    // load GPT if consent has not been given to `Create a personalised ads profile`
    if (consentSaved && !parseInt(purposeConsents[3 - 1], 10)) {
      return this.setState({ ready: true })
    }

    // load Prebid (for Ozone bidder)
    if (assets.gpt && assets.permutive && !assets.prebid) {
      loadAsset('/public/prebid6.26.0.js', 'prebid')
      return
    }

    // once all assets are loaded, get loadin' dem ads
    if (assets.gpt && assets.permutive && assets.prebid) {
      // if data collector loaded, run a helper to retain first time visitors
      this.setPbjsConfig()
      this.setIntoItAdapter()
      this.setGooglePersonalisation()
      this.setState({ ready: true })
    }
  }

  setPbjsConfig = () => {
    if (!window.pbjs.setConfig) return

    const config = getPbjsConfig(this.props.tcString)

    return window.pbjs.setConfig(config)
  }

  setIntoItAdapter = () => {
    if (!window.pbjs.adUnits) return

    const adUnits = window.pbjs.adUnits

    if (window.chrome) {
      try {
        const extensionId = 'pnmflfcfccejbkgpccclgpldbapjjddn'

        window.chrome.runtime.sendMessage(
          extensionId,
          { getSomeData: true },
          function(response) {
            if (
              response &&
              response.data &&
              response.data.prefs_ids &&
              response.data.prefs_ids.length > 0
            ) {
              const idToFind =
                response.data.prefs_ids[
                  Math.floor(Math.random() * response.data.prefs_ids.length)
                ]
              const extensionPref = response.data.allPref.find(
                x => x.id == idToFind
              )
              updatePrebid(extensionPref)
            }
          }
        )
      } catch (e) {
        console.log(e)
      }
    }

    function updatePrebid(extensionPref) {
      if (adUnits) {
        adUnits.forEach(adUnit => {
          if (adUnit.bids) {
            adUnit.bids.forEach(bid => {
              if (bid.bidder == 'appnexus') {
                console.log(
                  'appnexus bidder update with keyword ',
                  extensionPref.keyword
                )
                bid.params.keywords = {
                  intoit_preference: [extensionPref.keyword]
                }
              }
            })
          }
        })
      }
    }
  }

  // NOTE - this may not be required long-term as Google will read the TCString directly from the CMP
  setGooglePersonalisation = () => {
    const { purposeConsents } = this.props

    // Store and/or access information on a device (Purpose 1)
    // Create a personalized ads profile (Purposes 3)
    // Select personalized ads (Purposes 4)
    // Select basic ads (Purpose 2)
    // Measure ad performance (Purpose 7)
    // Apply market research to generate audience insights (Purpose 9)
    // Develop and improve products (Purpose 10)
    const requiredConsents = [1, 3, 4, 2, 7, 9, 10]
    const hasAllConsentsRequired = requiredConsents.every(consent =>
      Boolean(parseInt(purposeConsents[consent - 1]), 10)
    )

    // If the user doesn't have all of the consents required, set non-personalised ads
    if (!hasAllConsentsRequired) {
      window.googletag.pubads().setRequestNonPersonalizedAds()
    }
  }

  getOzoneConsent = () =>
    Boolean(parseInt(this.props.vendorConsents[524 - 1]), 10)

  render = () => (
    <AdvertContext.Provider
      value={{
        ...this.state,
        adUnitPath: getAdUnitPath(this.props),
        shouldRender: shouldDisplayAdvert(this.props),
        gptTargeting: getGptTargeting(this.props),
        ozoneBidderConsent: this.getOzoneConsent()
      }}
    >
      {this.props.children}
    </AdvertContext.Provider>
  )
}

export const AdvertProvider = props => (
  <AssetsContext.Consumer>
    {({ loadAsset, assets }) => (
      <CMPContext.Consumer>
        {({ purposeConsents, vendorConsents, tcString, consentSaved }) => (
          <Advert
            assets={assets}
            loadAsset={loadAsset}
            purposeConsents={purposeConsents}
            vendorConsents={vendorConsents}
            tcString={tcString}
            consentSaved={consentSaved}
            {...props}
          />
        )}
      </CMPContext.Consumer>
    )}
  </AssetsContext.Consumer>
)

export default AdvertContext
