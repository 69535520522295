const base = {
  black: '#1d1d1d',
  white: '#fff'
}

const tonal = ['#f3f3f3', '#e2e2e2', '#a0a0a0', '#717171', '#43464b', '#262728']

/**
 * Core brand colors
 */
const core = {
  primary: '#3dd2be',
  secondary: '#4c6085'
}

const coreDark = {
  primary: '#2abeaa',
  secondary: '#3c5178'
}

const coreLight = {
  primary: '#57ecd8',
  secondary: '#667a9f'
}

const coreFaint = {
  primary: '#ebfaf8',
  secondary: '#edeff2'
}

/**
 * Feedback colors
 */
const feedback = {
  error: '#fa4643'
}

/**
 * Transparency colors
 */
const transparency = {
  black: 'rgba(0, 0, 0, 0.5)',
  white: 'rgba(255, 255, 255, 0.5)',
  primary: 'rgba(61, 210, 190, 0.5)'
}

/**
 * Channel colors
 */
const channel = {
  people: '#a5f32f',
  beauty: '#f968c2',
  life: '#f8a238',
  travel: '#6bd2ff',
  books: '#9d8df1',
  fashion: '#ff5e5b',
  win: '#59f2dd'
}

const channelDark = {
  people: '#98e424',
  beauty: '#f250b4',
  life: '#ec962c',
  travel: '#5cc3f0',
  books: '#8875ec',
  fashion: '#f33733',
  win: '#40d9c4'
}

/**
 * Newsletter Sign Up
 */
const newsletter = {
  background: '#4c6085',
  text: '#fff',
  ctatext: core.primary,
  button: '#f968c2',
  buttonInteract: '#f4ae58'
}

/**
 * Social platforms
 */
const social = {
  email: '#4a4a4a',
  facebook: '#3b5998',
  instagram: '#6e61b1',
  twitter: '#00aced',
  whatsapp: '#43d854'
}

const socialDark = {
  email: '#1d1d1d',
  facebook: '#22407f',
  instagram: '#63579f',
  twitter: '#0093d4',
  whatsapp: '#2abf3b'
}

const quiz = {
  correct: '#87ce68',
  incorrect: '#e02e44',
  selected: '#0093d4',
  progress: {
    current: '#ffbcfc',
    remaining: '#2abeaa'
  },
  gradient: ['#28B2C7', '#ffbcfc']
}

/**
 * Series
 */
const series = {
  loves: '#acd2df',
  'remarkable-women': '#245083',
  'strong-women': '#ff8088',
  'a-womans-worth': '#41185e',
  'working-from-home': '#28a6a8',
  'under-her-eye': '#e60078',
  'international-womens-day': '#555eaa',
  'stylist-10-year-anniversary': '#000000',
  'live-luxe': '#000000',
  'love-women': '#a22741',
  'olympic-games-2020-paralympics': '#1c3175',
  'nobody-told-me': '#ffcc00',
  restival: '#00354e',
  'adwoa-aboah': '#ff0064',
  'billie-piper-takes-over': '#565656',
  'brie-larson-takes-over': '#f27254',
  'caitlin-moran-takes-over': '#262728',
  'yara-shahidi': '#980644',
  'claudia-winkleman': '#1a6236',
  'hillary-clinton-chelsea-clinton-stylist-magazine-guest-editors': '#b30726',
  'jameela-jamil-takes-over': '#ff0000',
  'lily-allen-takes-over': '#c93829',
  'roald-dahl': '#0a4cb1',
  'roxane-gay': '#902321'
}

const seriesDark = {
  loves: '#799FAC',
  'remarkable-women': '#174376',
  'strong-women': '#F2737B',
  'a-womans-worth': '#280045',
  'working-from-home': '#1B999B',
  'under-her-eye': '#CD005F',
  'international-womens-day': '#222B77',
  'stylist-10-year-anniversary': '#000000',
  'live-luxe': '#000000',
  'love-women': '#6F000E',
  'olympic-games-2020-paralympics': '#000042',
  'nobody-told-me': '#CC9900',
  restival: '#00021B',
  'adwoa-aboah': '#CC0031',
  'billie-piper-takes-over': '#232323',
  'brie-larson-takes-over': '#BF3F21',
  'caitlin-moran-takes-over': '#0D0E0F',
  'yara-shahidi': '#650011',
  'claudia-winkleman': '#002F03',
  'hillary-clinton-chelsea-clinton-stylist-magazine-guest-editors': '#800000',
  'jameela-jamil-takes-over': '#CC0000',
  'lily-allen-takes-over': '#960500',
  'roald-dahl': '#00197E',
  'roxane-gay': '#5D0000'
}

/**
 * Verticals
 */
const verticals = {
  black: '#000',
  white: '#fff',
  teal: '#00cfbe'
}

/**
 * Banners
 */
const banners = {
  loves: '#ACD2DF',
  theDrop: '#1D1D1D',
  stylistLive: '#fbbefc'
}

export default {
  banners,
  base,
  channel,
  channelDark,
  core,
  coreDark,
  coreFaint,
  coreLight,
  feedback,
  newsletter,
  quiz,
  social,
  socialDark,
  tonal,
  transparency,
  series,
  seriesDark,
  verticals
}
