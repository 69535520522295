import { css } from 'emotion'
import * as blocks from 'style/blocks'
import { anim, color, layout } from 'style/tokens'
import rem from 'style/utils/rem'
import { transformImage } from 'utils/image'
import media from './media'

const hoverStyles = css({
  opacity: 0,
  transition: `opacity ${anim.duration.quick} ${anim.easing.initial}`
})

const bgImageOverlay = css(
  blocks.display.transparentOverlay('after', hoverStyles, 'primary')
)

const bgImage = sizes => {
  const hasValidSize = Boolean(sizes)

  return css(
    {
      backgroundImage: hasValidSize
        ? `url(${transformImage(sizes.landscape, { w: 220 })})`
        : 'none',
      backgroundColor: color.tonal[0],
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'relative',
      minHeight: '30vw', // 4:3
      width: '40%'
    },
    media('(min-width: 25rem)', {
      minHeight: '26vw' // 3:2
    }),
    media(layout.mq.smUp, {
      backgroundImage: hasValidSize
        ? `url(${transformImage(sizes.landscape, { w: 300 })})`
        : 'none',
      minHeight: `calc((100vw - ${rem(24)}) * 0.26)` // 3:2 (negating padding)
    }),
    media(layout.mq.mdUp, {
      minHeight: `calc((100vw - ${rem(48)}) * 0.2)` // 3:2 (negating padding and container width)
    }),
    media(layout.mq.lgUp, {
      minHeight: `calc((100vw - ${rem(48)}) * 0.15)` // 3:2 (negating padding and container width)
    }),
    media('(min-width: 71.75rem)', {
      minHeight: rem(168) // 3:2
    })
  )
}

export { bgImageOverlay, bgImage }
