import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import AssetsContext from '_utility/context-assets'
import CMPContext from '_utility/context-cmp'

import snippet from './_snippet-permutive'

const Permutive = ({ apikey, id }) => {
  const { loadAsset } = useContext(AssetsContext)
  const { purposeConsents } = useContext(CMPContext)

  useEffect(() => snippet(id, apikey), [])
  useEffect(
    () => {
      if ((parseInt(purposeConsents[3 - 1], 10))) {
        loadAsset(`https://cdn.permutive.com/${id}-web.js`, 'permutive')
      }
    },
    [purposeConsents]
  )

  return null
}

Permutive.propTypes = {
  apikey: PropTypes.string,
  id: PropTypes.string
}

export default Permutive
