import { css } from 'emotion'
import * as blocks from 'style/blocks'
import { anim, color, layout, type } from 'style/tokens'
import rem from 'style/utils/rem'
import media from 'style/utils/media'

const root = equalCardSizes =>
  css(
    {
      position: 'relative'
    },
    media(layout.mq.smOnly, {
      display: 'flex',
      flexDirection: 'column'
    }),
    !equalCardSizes &&
      css(
        media(layout.mq.smOnly, {
          flexDirection: 'row',
          'li:nth-child(1) &': {
            display: 'block'
          }
        })
      )
  )

const hoverStyles = css({
  opacity: 0,
  transition: `opacity ${anim.duration.quick} ${anim.easing.initial}`
})

const image = equalCardSizes =>
  css(
    {
      width: !equalCardSizes ? '48%' : '100%',
      position: 'relative'
    },
    media(layout.mq.smUp, {
      width: '100%'
    }),
    !equalCardSizes &&
      css({
        'li:nth-child(1) &': css(
          media(layout.mq.smOnly, {
            width: '100%'
          })
        )
      }),
    {
      '& div': css(
        blocks.display.transparentOverlay('after', hoverStyles, 'primary')
      )
    }
  )

const content = equalCardSizes =>
  css(
    {
      padding: layout.sizes.sm,
      textAlign: !equalCardSizes ? 'left' : 'center',
      width: !equalCardSizes ? '52%' : '100%'
    },
    media(layout.mq.smUp, {
      padding: `${layout.sizes.sm} 0`,
      textAlign: 'center',
      width: '100%'
    }),
    !equalCardSizes &&
      css({
        'li:nth-child(1) &': css(
          media(`${layout.mq.smUp} and ${layout.mq.mdDown}`, {
            padding: layout.sizes.sm
          }),
          media(layout.mq.mdDown, {
            backgroundColor: color.base.white,
            margin: `-${layout.sizes.md} auto 0`,
            position: 'relative',
            textAlign: 'center',
            width: `calc(100% - ${layout.sizes.md})`
          })
        )
      })
  )

const category = css({
  marginBottom: layout.sizes.xs,
  position: 'relative',
  zIndex: 1
})

const title = equalCardSizes =>
  css(blocks.type.brevier, {
    letterSpacing: rem(0.5, true),
    lineHeight: 1.25,
    fontWeight: type.weight.bold,
    fontSize: rem(14),
    textTransform: 'none',
    transition: `color ${anim.duration.quick} ${anim.easing.initial}`,
    'li:nth-child(1) &':
      !equalCardSizes &&
      css(
        media(layout.mq.mdDown, {
          fontSize: type.sizes.sm
        })
      )
  })

const blockLink = equalCardSizes =>
  css(
    blocks.layout.fill,
    blocks.display.absoluteAnchor,
    {
      zIndex: 1
    },
    blocks.state.interact({
      [`& ~ .${content(equalCardSizes).toString()} .${title(
        equalCardSizes
      ).toString()}`]: {
        color: color.core.primary
      },
      [`& ~ .${image(equalCardSizes).toString()} > div::after`]: {
        opacity: 1
      }
    })
  )

const playButton = css({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  svg: {
    transform: 'scale(0.5)'
  },
  circle: {
    fill: 'black'
  }
})

export default {
  root,
  image,
  content,
  category,
  title,
  blockLink,
  playButton
}
