import { useState, useEffect } from 'react'

function useCountdown(date, delay) {
  const [_date, setDate] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  useEffect(() => {
    setNewTime()

    const tick = setInterval(() => setNewTime(), delay)
    return () => clearInterval(tick)
  }, [])

  const setNewTime = () => {
    const currentDate = new Date()
    const distanceToDate = new Date(date) - currentDate

    let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24))
    let hours = Math.floor(
      (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    let minutes = Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60))
    let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000)

    const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9]

    if (numbersToAddZeroTo.includes(hours)) {
      hours = `0${hours}`
    } else if (numbersToAddZeroTo.includes(minutes)) {
      minutes = `0${minutes}`
    } else if (numbersToAddZeroTo.includes(seconds)) {
      seconds = `0${seconds}`
    }

    if (days <= 0) days = 0
    if (hours <= 0) hours = 0
    if (minutes <= 0) minutes = 0
    if (seconds <= 0) seconds = 0

    setDate({
      days: parseInt(days, 10),
      hours: parseInt(hours, 10),
      minutes: parseInt(minutes, 10),
      seconds: parseInt(seconds, 10)
    })
  }

  return _date
}

export default useCountdown
