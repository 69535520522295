import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'emotion-theming'
import { cache } from 'emotion'
import { CacheProvider } from '@emotion/core'

import { getTheme } from 'design-system'

import { AdvertProvider } from '_utility/context-advert'
import { AssetsProvider } from '_utility/context-assets'
import { CMPProvider } from '_utility/context-cmp'
import { RegionPreferenceProvider } from '_utility/context-region-preference'
import { SeriesProvider } from '_utility/context-series'

import AppErrorBoundary from '_utility/error-boundary'

import GoogleTagManager from '_utility/google-tag-manager'
import Facebook from '_utility/facebook'
import Permutive from '_utility/permutive'

import DocumentHead from 'partials/_document-head'
import SiteNavigation from 'partials/navigation'
import SiteFooter from 'partials/site-footer'
import ReducedSiteFooter from 'partials/reduced-site-footer'
import SkipLink from 'partials/skip-link'
import CmsPopup from 'partials/cms-popup'

import { isSponsored } from 'utils/croissant'

const Base = ({
  showHeader,
  showFooter,
  showNav = true,
  children,
  showSwtcNav = false,
  shouldRenderCmsPopup = true,
  hideHeader = false,
  ...rest
}) => {
  const mainContentRef = useRef(null)

  return (
    <AppErrorBoundary>
      <SkipLink to="main">{'Skip to content'}</SkipLink>
      <DocumentHead {...rest} />
      {showHeader && (
        <SiteNavigation
          {...rest}
          shouldShowNav={showNav}
          showSwtcNav={showSwtcNav}
          hideHeader={hideHeader}
        />
      )}
      <main
        ref={mainContentRef}
        css={`
          flex: 1 0 auto;
        `}
        id="main"
        role="main"
      >
        {children}
      </main>

      {shouldRenderCmsPopup && (
        <CmsPopup
          postId={rest?.id}
          packageIds={rest?.acf?.package_ids}
          seriesSlug={rest?.taxonomy === 'category' ? false : rest?.slug}
          categorySlug={rest?.taxonomy === 'series' ? false : rest?.slug}
          type={rest?.type}
          isSponsored={rest?.acf?.sponsored}
        />
      )}

      {showFooter &&
        (rest?._tapestry?.options?.reducedFooter ? (
          <ReducedSiteFooter />
        ) : (
          <SiteFooter />
        ))}

      <GoogleTagManager
        apikey={__GOOGLE_TAG_MANAGER_KEY__}
        data={{
          articleType: isSponsored(
            Array.isArray(rest.data) ? rest.data[0].type : rest.type
          )
            ? 'sponsored'
            : 'standard',
          event: 'page_view'
        }}
      />
      <Permutive id={__PERMUTIVE_ID__} apikey={__PERMUTIVE_API_KEY__} />
      <Facebook appId={__FB_APP_ID__} />
    </AppErrorBoundary>
  )
}

Base.propTypes = {
  children: PropTypes.node.isRequired,
  showFooter: PropTypes.bool,
  showHeader: PropTypes.bool,
  showNav: PropTypes.bool
}
Base.defaultProps = {
  showFooter: true,
  showHeader: true,
  showNav: true
}

export default props => (
  <CacheProvider value={cache}>
    <ThemeProvider theme={getTheme(props)}>
      <AssetsProvider>
        <CMPProvider>
          <SeriesProvider {...props}>
            <RegionPreferenceProvider _tapestry={props._tapestry}>
              <AdvertProvider {...props}>
                <Base {...props} />
              </AdvertProvider>
            </RegionPreferenceProvider>
          </SeriesProvider>
        </CMPProvider>
      </AssetsProvider>
    </ThemeProvider>
  </CacheProvider>
)
