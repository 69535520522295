import React from 'react'
import PropTypes from 'prop-types'
import fromnow from 'fromnow'

const TimeAgo = ({ date, ...rest }) => (
  <time dateTime={date} title={date} {...rest}>
    {fromnow(date, { suffix: true, max: 1 })}
  </time>
)

TimeAgo.propTypes = {
  date: PropTypes.string
}

export default TimeAgo
