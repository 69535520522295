import React from 'react'
import PropTypes from 'prop-types'

import styles from './grid-style'

const Grid = props => {
  const { children, tag, datacy = 'grid' } = props
  const Tag = tag
  return (
    <Tag className={styles.root(props)} data-cy={datacy}>
      {children}
    </Tag>
  )
}

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  justify: PropTypes.oneOf(['around', 'between', 'center', 'end', 'start']),
  gutters: PropTypes.bool,
  tag: PropTypes.string,
  fullWidth: PropTypes.bool,
  customStyle: PropTypes.object
}
Grid.defaultProps = {
  justify: 'start',
  gutters: true,
  tag: 'div',
  fullWidth: false,
  customStyle: {}
}

export default Grid
