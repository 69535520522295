import { css } from 'emotion'
import * as blocks from 'style/blocks'
import { anim, color, layout, type } from 'style/tokens'
import rem from 'style/utils/rem'
import media from 'style/utils/media'

const wrapper = customStyle =>
  css(
    {
      marginRight: 'auto'
    },
    customStyle
  )

const buttonOpen = css({
  backgroundColor: color.base.white,
  border: 0,
  cursor: 'pointer',
  display: 'inline-block',
  flex: 'none',
  padding: 0
})
const iconOpen = css(
  {
    height: rem(20),
    margin: 'auto',
    width: rem(20)
  },
  media(layout.mq.smUp, {
    height: rem(28),
    width: rem(28)
  })
)
const root = ({ dialogOpen, swtcNavBar }) =>
  css({
    '::before': {
      backgroundColor: 'black',
      bottom: 0,
      content: '""',
      left: 0,
      opacity: dialogOpen ? 0.7 : 0,
      position: 'absolute',
      right: 0,
      top: swtcNavBar ? '196px' : '168px',
      transition: `all ${anim.duration.initial} ${anim.easing.initial}`,
      visibility: dialogOpen ? 'visible' : 'hidden',
      zIndex: 1,
      height: '100vh'
    }
  })
const container = ({ dialogOpen, swtcNavBar }) =>
  css(
    {
      backgroundColor: 'black',
      left: 0,
      opacity: dialogOpen ? 1 : 0,
      padding: `${layout.sizes.lg} ${layout.sizes.md}`,
      position: 'absolute',
      top: swtcNavBar ? '133px' : '104px',
      transition: `
      opacity ${anim.duration.initial} ${anim.easing.initial},
      visibility ${anim.duration.initial} ${anim.easing.initial}`,
      visibility: dialogOpen ? 'visible' : 'hidden',
      width: '100%'
    },
    media(layout.mq.smUp, {
      padding: `8px ${layout.sizes.lg}`,
      display: 'flex',
      justifyContent: 'space-between'
    })
  )

const form = css({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: '0 auto',
  width: layout.trunk.lg
})
const label = css({
  borderBottom: `2px solid ${color.base.white}`,
  display: 'flex',
  flex: 'none',
  height: layout.sizes.xl,
  width: layout.sizes.md
})
const input = css(
  {
    backgroundColor: 'transparent',
    borderColor: color.base.white,
    borderRadius: 0,
    borderWidth: '0 0 2px',
    color: color.base.white,
    flex: `1 0 calc(100% - ${layout.sizes.lg})`,
    fontFamily: type.stack.tertiary,
    fontSize: type.sizes.sm,
    minHeight: layout.sizes.xl,
    paddingLeft: layout.sizes.sm,
    paddingRight: layout.sizes.sm,
    appearance: 'none',
    ':focus': { outline: 'none' },
    ':placeholder': { color: 'currentColor' }
  },
  media(layout.mq.smUp, {
    flex: 'auto'
  })
)

const submit = css(
  blocks.type.brevier,
  {
    backgroundColor: color.base.white,
    border: 0,
    color: color.base.black,
    cursor: 'pointer',
    fontSize: type.sizes.xxs,
    paddingLeft: layout.sizes.md,
    paddingRight: layout.sizes.md
  },
  media(layout.mq.smOnly, {
    marginTop: layout.sizes.lg,
    height: layout.sizes.lg
  }),
  media(layout.mq.smUp, {
    height: layout.sizes.xl
  })
)
const close = css(
  {
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer'
  },
  media(layout.mq.smOnly, {
    bottom: layout.sizes.lg,
    height: layout.sizes.lg,
    padding: 0,
    position: 'absolute',
    right: layout.sizes.md,
    width: layout.sizes.lg
  }),
  media(layout.mq.smUp, {
    height: layout.sizes.xl,
    width: layout.sizes.xl
  }),
  media(layout.mq.mdUp, {
    width: layout.sizes.xxl
  })
)

const icon = css(
  {
    color: color.base.white,
    margin: 'auto'
  },
  media(layout.mq.mdUp, {
    height: layout.sizes.md,
    width: layout.sizes.md
  })
)

export default {
  buttonOpen,
  iconOpen,
  root,
  container,
  form,
  label,
  input,
  submit,
  close,
  icon,
  wrapper
}
