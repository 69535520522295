/* eslint-disable max-len */
import React from 'react'
import IconBase from 'assets/icon-base'

const IconClose = props => (
  <IconBase {...props}>
    <path d="M0 9.9L9.9 0l190.05 190.05-9.9 9.9" />
    <path d="M190.05 0l9.9 9.9L9.9 199.95l-9.9-9.9" />
  </IconBase>
)

export default IconClose
