import React from 'react'
import { withTheme } from 'emotion-theming'

import Anchor from 'partials/anchor'
import styles from './style'

const MultiPost = ({ posts, theme }) => {
  return (
    <ul className={styles.root}>
      {posts.map(post => (
        <li style={{ display: 'flex' }} key={post.id}>
          <Anchor url={post.link} className={styles.link(theme)}>
            {post.title.rendered}
          </Anchor>
        </li>
      ))}
    </ul>
  )
}

export default withTheme(MultiPost)
