import React from 'react'
import PropTypes from 'prop-types'

import IconFemale from 'assets/icon-female'

import { Box } from 'design-system'

import getUserData from '_utility/get-user-data'

import useMediaQuery from 'hooks/use-media-query'
import { layout } from 'style/tokens'

import { trackEvent } from 'utils/analytics-trackers'

import style from './style'

const UserNavigation = ({ theme, reduced = false }) => {
  const { isAuthenticated } = getUserData()

  const trackClickSignIn = url => {
    trackEvent(`Clicked on Sign in`, 'User Navigation', 'User Navigation')
    window.location = url
  }

  const trackClickRegister = url => {
    trackEvent(`Clicked on Register`, 'User Navigation', 'User Navigation')
    window.location = url
  }

  const isSM = useMediaQuery(layout.mq.mdDown)

  return (
    <div className={style.loggedOutActions(theme)}>
      {isAuthenticated ? (
        <a href="/profile" className={style.accountLinks}>
          <div className={style.userIconSignedIn}>
            <IconFemale />
          </div>
        </a>
      ) : (
        <div
          css={`
            display: flex;
            align-items: center;
            width: max-content;
          `}
        >
          <a href="/login" className={style.userIcon}>
            <IconFemale />
          </a>
          {!reduced &&
            !isSM && (
              <>
                <Box
                  onClick={() => trackClickSignIn('/login')}
                  className={style.accountLinks}
                >
                  {'Sign in'}
                </Box>
                /
                <Box
                  onClick={() => trackClickRegister('/register')}
                  className={style.accountLinks}
                >
                  Register
                </Box>
              </>
            )}
        </div>
      )}
    </div>
  )
}

UserNavigation.propTypes = {
  theme: PropTypes.object.isRequired,
  reduced: PropTypes.bool
}

export default UserNavigation
