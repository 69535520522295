const componentTypes = {
  HEADING: 'heading',
  FONDUE: 'fondue',
  LOOPING_VIDEO: 'looping_video',
  PARAGRAPH: 'paragraph',
  DIVIDER: 'divider',
  BUTTON: 'button',
  EMBED: 'embed',
  HTML: 'html',
  IMAGE: 'image',
  INTERACTIVE_IMAGE: 'interactive_image',
  LISTICLE: 'listicle',
  PULL_QUOTE: 'pull-quote',
  NEWSLETTER_SIGNUP: 'newsletter_signup',
  PRODUCT_CAROUSEL: 'product-carousel',
  RELATED_ARTICLES: 'related_articles'
}

export default componentTypes
