import { useState, useEffect } from 'react'
import { debounce } from 'throttle-debounce'

function useScrollDistance(delay = 500) {
  if (typeof window === 'undefined') return {}

  const [scrollX, setScrollX] = useState(0)
  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const debouncedScrollSetter = debounce(delay, () => setScrollDistance())

    setScrollDistance()

    window.addEventListener('scroll', debouncedScrollSetter)

    return () => {
      window.removeEventListener('scroll', debouncedScrollSetter)
    }
  }, [])

  const setScrollDistance = () => {
    setScrollX(window.scrollX)
    setScrollY(window.scrollY)
  }

  return {
    scrollX,
    scrollY
  }
}

export default useScrollDistance
