const getOzonePlacementId = zone => {
  const placements = {
    channel: '1420430632',
    home: '1420430631',
    longform: '1420430633',
    post: '1420430630',
    quiz_post: '1420430636',
    sponsored_longform: '1420430635',
    sponsored_post: '1420430634',
    sponsored_quiz_post: '1420430637',
    how_to: '1420432362',
    training_plan: '1420432363',
    video: '1420432364'
  }

  return placements[zone]
}

const getAppNexusPlacementId = zone => {
  const placements = {
    channel: 17418045,
    feature_list: 17853262,
    home: 13455034,
    longform: 17418079,
    post: 17418033,
    quiz_post: 17418086,
    sponsored_longform: 17418083,
    sponsored_post: 17418048,
    sponsored_quiz_post: 17418101
  }

  return placements[zone]
}

const getGroupMPlacementId = zone => {
  const placements = {
    channel: 20910810,
    feature_list: 17853262,
    home: 20910809,
    longform: 20910811,
    post: 20905326,
    quiz_post: 20910814,
    sponsored_longform: 20910813,
    sponsored_post: 20910812,
    sponsored_quiz_post: 20910815
  }

  return placements[zone]
}

const getRelevantBidders = ({ bidders, adUnitPath, targeting }) => {
  let arr = []
  const zone = adUnitPath.split('/').slice(-1)[0]

  bidders.forEach(bidder => {
    if (bidder === 'ozone') {
      const { pos, is_article, path, breakpoint } = targeting

      arr.push({
        bidder: 'ozone',
        params: {
          publisherId: 'OZONETSG0001',
          siteId: '4204204213',
          placementId: getOzonePlacementId(zone),
          video: {
            skippable: true,
            playback_method: ['auto_play_sound_off']
          },
          customData: [
            {
              settings: {},
              targeting: {
                pos,
                is_article,
                path,
                breakpoint
              }
            }
          ]
        }
      })
    }

    if (bidder === 'appnexus') {
      arr.push({
        bidder: 'appnexus',
        params: {
          placementId: getAppNexusPlacementId(zone),
          video: {
            skippable: true,
            playback_method: ['auto_play_sound_off']
          }
        }
      })
    }

    if (bidder === 'groupm') {
      arr.push({
        bidder: 'groupm',
        params: {
          placementId: getGroupMPlacementId(zone),
          video: {
            skippable: true,
            playback_method: ['auto_play_sound_off']
          }
        }
      })
    }
  })

  return arr
}

export default getRelevantBidders
