import React from 'react'
import PropTypes from 'prop-types'

import styles from './style'

const Heading = props =>
  props.text ? (
    <h2
      className={styles.heading(props)}
      dangerouslySetInnerHTML={{ __html: props.text }}
    />
  ) : null

Heading.defaultProps = {
  postType: 'standard'
}
Heading.propTypes = {
  postType: PropTypes.string,
  text: PropTypes.string
}

export default Heading
