export const openDialog = () => ({
  dialogOpen: true
})

export const closeDialog = () => ({
  dialogClosing: false,
  dialogOpen: false
})

export const transition = () => ({
  dialogClosing: true
})
