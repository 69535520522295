import parseuri from 'parseUri'
import { stringify } from 'query-str-es5'

const validCrops = ['landscape', 'letterbox', 'portrait', 'square']
const cropRatios = {
  landscape: 2 / 3,
  letterbox: 9 / 16,
  portrait: 4 / 3,
  square: 1 / 1
}

// get file type
export const detectFormat = (src, format) => {
  if (!src) return false

  const type = src.split('.').slice(-1)[0]

  if (typeof type === 'string') {
    return type.toLowerCase() === format
  }
  return false
}

// apply transformations to image with service
export const transformImage = (url, options) => {
  if (!url) return null

  // do not add queries to a GIF, it would be rendered static. IMGIX issue? Investigate?
  if (detectFormat(url, 'gif')) return url

  // skip transformation if already transformed
  if (parseuri(url).host === parseuri(__IMAGE_HOST__).host) return url

  const base = `${__IMAGE_HOST__}${parseuri(url).path}`
  // Imgix specific parameters
  const query = {
    w: 1200,
    h: 1,
    fit: 'max',
    auto: 'format,compress',
    ...options
  }

  return stringify(query, base)
}
// return cropped image url
export const getImageCrop = (image, crop) => {
  return validCrops.indexOf(crop) > -1 ? image.sizes[crop] : image.url
}
// return height/width from crop
export const getImageSize = (image, crop) => {
  if (validCrops.indexOf(crop) > -1 && image.sizes) {
    return {
      height: image.sizes[`${crop}-height`],
      width: image.sizes[`${crop}-width`]
    }
  }
  return {
    height: image.height,
    width: image.width
  }
}
export const getCropRatio = crop => cropRatios[crop]
// get DOM attributes for image
export const getImageSources = ({ crop, image, imageSizes, options }) => {
  // catch errors with source data
  if (!image || !image.sizes || !image.url) return {}
  // collect relevant image data
  const url = getImageCrop(image, crop)
  const { height, width } = getImageSize(image, crop)
  const src = transformImage(url, options)
  const webpSrc = transformImage(url, { ...options, fm: 'webp' })

  const getSrcSet = (imageSizes, url, options) => {
    return imageSizes
      .map(size => `${transformImage(url, { ...options, w: size })} ${size}w`)
      .join()
  }

  // create DOM ready image info
  return {
    src,
    webpSrc,
    preloadSrc: imageSizes
      ? transformImage(url, { ...options, w: imageSizes[0] })
      : src,
    srcset: imageSizes ? getSrcSet(imageSizes, url, options) : null,
    height,
    width,
    ...(webpSrc &&
      imageSizes && { webpSrcSet: getSrcSet(imageSizes, webpSrc, options) })
  }
}
