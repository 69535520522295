import React from 'react'
import PropTypes from 'prop-types'

import styles from './style'

const SectionWrapper = ({
  children,
  customStyle,
  marginBottom,
  marginTop,
  paddingBottom,
  paddingTop,
  tag,
  withMargin,
  withPadding,
  ...rest
}) => {
  const Tag = tag
  return (
    <Tag
      css={`
        ${styles.root(
          marginBottom,
          marginTop,
          paddingBottom,
          paddingTop,
          withMargin,
          withPadding
        )} ${customStyle};
      `}
      {...rest}
    >
      {children}
    </Tag>
  )
}

SectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  customStyle: PropTypes.string,
  marginBottom: PropTypes.bool,
  marginTop: PropTypes.bool,
  paddingBottom: PropTypes.bool,
  paddingTop: PropTypes.bool,
  tag: PropTypes.string,
  withMargin: PropTypes.bool,
  withPadding: PropTypes.bool
}
SectionWrapper.defaultProps = {
  marginBottom: true,
  marginTop: true,
  paddingBottom: true,
  paddingTop: true,
  tag: 'div',
  withMargin: true,
  withPadding: false
}

export default SectionWrapper
