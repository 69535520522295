import { css } from 'emotion'
import { layout } from 'style/tokens'
import media from 'style/utils/media'

/**
 * Fill the containing element
 */
const fill = css({
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0
})

/**
 * Position the element within its container
 */
const position = {
  center: css({
    position: 'absolute',
    right: '50%',
    top: '50%',
    transform: 'translate(50%, -50%)'
  }),
  centerY: css({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
  }),
  centerX: css({
    marginLeft: 'auto',
    marginRight: 'auto'
  })
}

/**
 * Use these 'vpPad*' blocks whenever you are defining the 'minimum'
 * space between an element and the left and right-hand sides of the viewport
 */
const inset = css(
  {
    paddingLeft: layout.sizes.sm,
    paddingRight: layout.sizes.sm
  },
  media(layout.mq.smUp, {
    paddingLeft: layout.sizes.md,
    paddingRight: layout.sizes.md
  })
)
const outset = css(
  media(layout.mq.smOnly, {
    marginLeft: `-${layout.sizes.sm}`,
    marginRight: `-${layout.sizes.sm}`
  })
)

/**
 * Clearfix hack
 */
const clearfix = css({
  '::after': {
    clear: 'both',
    content: '""',
    display: 'table'
  }
})

/**
 * Global section margin (vertical)
 */
const sectionMargin = ({ top = true, bottom = true } = {}) =>
  css(
    {
      marginBottom: bottom && layout.vSpace.sm,
      marginTop: top && layout.vSpace.sm
    },
    media(layout.mq.mdUp, {
      marginBottom: bottom && layout.vSpace.md,
      marginTop: top && layout.vSpace.md
    })
  )

/**
 * Global section padding (vertical)
 */
const sectionPad = ({ top = true, bottom = true } = {}) =>
  css(
    {
      paddingBottom: bottom && layout.vSpace.sm,
      paddingTop: top && layout.vSpace.sm
    },
    media(layout.mq.mdUp, {
      paddingBottom: bottom && layout.vSpace.md,
      paddingTop: top && layout.vSpace.md
    })
  )

export default {
  clearfix,
  fill,
  inset,
  outset,
  position,
  sectionMargin,
  sectionPad
}
