import { css } from 'emotion'
import * as blocks from 'style/blocks'
import { type, layout } from 'style/tokens'

const root = css({
  listStyle: 'none'
})

const link = theme =>
  css(blocks.type.anchor(theme.colors.primaryDark, theme.colors.primary), {
    fontSize: type.sizes.xs,
    fontWeight: type.weight.bold,
    lineHeight: type.leading.md,
    letterSpacing: type.tracking.md,
    marginBottom: layout.sizes.sm,
    padding: `0px ${layout.sizes.xs} 0px`,
    paddingTop: '3px',
    textDecoration: 'none'
  })

export default {
  root,
  link
}
