import parseuri from 'parseUri'
import { isPost, isTaxonomy } from 'utils/croissant'

const getBreakpoint = () => {
  if (typeof window !== 'undefined') {
    if (window.matchMedia('(max-width: 549px)').matches) return 'small'
    if (window.matchMedia('(max-width: 999px)').matches) return 'medium'
    return 'large'
  }
  // unlikely to not match one of the above, but just in case
  return null
}

export default props => {
  /*
    Expected output
    {
      is_article: '<STRING>',
      path: '<STRING>',
      post_id: '<STRING>',
      type: '<STRING>',
      env: '<STRING>',
      article_type: '<STRING>'
    }
  */
  const path = parseuri(props.link).path
  const isArticle = isPost(props.type)
  const isTerm = isTaxonomy(props.taxonomy)
  const isSeriesHomepage = Boolean(props.series_badge)
  const isInSeries = Boolean(props.acf?.series?.slug || isSeriesHomepage)
  const breakpoint = getBreakpoint()

  const data = {
    is_article: isArticle ? 'yes' : 'no',
    in_series: isInSeries ? 'yes' : 'no',
    path,
    type: isArticle ? 'article' : isTerm ? 'section' : 'unknown',
    env: __RELEASE_STAGE__,
    breakpoint
  }

  if (isArticle) {
    data.article_type = props.type
    data.category = props.acf?.category?.slug
    data.keyword = props.acf?.tags?.[0] && props.acf?.tags.map(tag => tag.slug)
    data.post_id = String(props.id)
    data.post_slug = props.slug
  }

  if (isInSeries) {
    data.series_slug = isSeriesHomepage ? props.slug : props.acf.series.slug
  }

  return data
}
