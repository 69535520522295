import { css } from 'emotion'
import media from 'style/utils/media'
import * as blocks from 'style/blocks'
import { layout } from 'style/tokens'
import { getWidth, shouldHaveFullWidth, widths, layouts } from './helpers'
import { isSalesPage } from 'utils/croissant'

const root = customStyle =>
  css(
    {
      position: 'relative',
      '& > div:first-child': {
        marginTop: 0
      },
      '& > div:last-child': {
        marginBottom: 0
      },
      // Any widget that follows a <Heading /> widget will have no top margin
      // defer to the bottom margin used by <Heading />
      '[data-widget="heading"] + [data-widget]': {
        marginTop: 0
      }
    },
    customStyle
  )

// TODO - rewrite all of this rubbish, way too reliant on CSS selectors and nesting!
const widgetStyle = (widget, postType) => {
  const width = getWidth(widget, postType)
  return css(
    {
      maxWidth: width,
      textAlign: widget.alignment || null
    },

    widget.acf_fc_layout !== layouts.FONDUE && {
      // Any widgets inside this div should have reduced margin
      '[data-widget-style="listing"] &': {
        marginTop: layout.sizes.xs,
        marginBottom: layout.sizes.xs
      }
    },

    // Any fondue widgets within the listing page on desktop shouldn't display
    widget.acf_fc_layout === layouts.FONDUE &&
      media(layout.mq.smUp, {
        '[data-widget-style="listing"] &': {
          display: 'none'
        }
      }),

    isSalesPage(postType) &&
      (widget.acf_fc_layout !== layouts.HEADING ||
        widget.acf_fc_layout !== 'paragraph') && {
        marginTop: '0 !important',
        marginBottom: '80px !important'
      } &&
      media(layout.mq.smUp, {
        marginBottom: '32px !important'
      }),

    width !== widths.FULL && css(blocks.layout.position.centerX),

    // Use smaller bottom margin for <Heading /> widgets
    widget.acf_fc_layout === layouts.HEADING &&
      css(
        { marginBottom: layout.vSpace.xs },
        media(layout.mq.mdUp, { marginBottom: layout.vSpace.sm })
      ),

    shouldHaveFullWidth(
      postType,
      widget.acf_fc_layout,
      widget.layout_interaction
    ) && {
      marginLeft: 'calc(-50vw + 50%)',
      maxWidth: 'none',
      width: '100vw'
    }
  )
}

export default {
  root,
  widget: widgetStyle
}
