import { useState, useEffect } from 'react'
import { debounce } from 'throttle-debounce'

function useWindowWidth(time = 1000) {
  if (typeof window === 'undefined') return

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const debouncedHandleResize = debounce(time, () =>
      setWidth(window.innerWidth)
    )

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  return width
}

export default useWindowWidth
