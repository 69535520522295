import React from 'react'
import isPlainObject from 'lodash.isplainobject'

import { hasEntries } from 'utils/array'

import Anchor from 'partials/anchor'
import Image from 'partials/image'
import PostMeta from 'partials/post-meta'
import Category from 'partials/category'
import SponsorKeyline from 'partials/sponsor-keyline'

import { layout } from 'style/tokens'

import styles from './style'

const CardExtended = props => {
  const category = props.acf?.category
  const categoryParent = props.acf?.category?.parent
  const image = props.acf?.hero_images?.[0]
  const shortHeadline = props.acf?.short_headline
  const sell = props.acf?.sell
  const sponsorName = props.acf?.sponsor_name
  const sponsorLabel = props.acf?.sponsor_label
  const seriesBadge = props.acf?.series?.series_badge?.url
  const isPaid = hasEntries(props.acf?.package_ids)

  return (
    <article className={styles.root}>
      <Anchor
        className={styles.blockLink}
        url={props.link}
        aria-label={shortHeadline}
      />
      <div className={styles.image}>
        {isPlainObject(image) && (
          <Image
            badge={seriesBadge}
            crop="landscape"
            image={image}
            imageSizes={[520, 300]}
            sizes={`${layout.mq.smUp} 300px`}
            isPaid={isPaid}
          />
        )}
      </div>
      <div className={styles.content}>
        {category && (
          <Category
            variant="tertiary"
            name={category.name}
            slug={category.slug}
            parent={categoryParent}
            customStyle={styles.category}
          />
        )}
        {shortHeadline && (
          <h3
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: shortHeadline
            }}
          />
        )}
        {sell && (
          <p
            className={styles.body}
            dangerouslySetInnerHTML={{
              __html: sell
            }}
          />
        )}
        {sponsorName ? (
          <SponsorKeyline
            customStyle={styles.sponsor}
            brand={sponsorName}
            label={sponsorLabel}
          />
        ) : (
          <PostMeta
            authors={props._embedded?.author}
            customStyle={styles.meta}
            date={props.date_gmt}
            showTerm={false}
          />
        )}
      </div>
    </article>
  )
}

export default CardExtended
