import { css } from 'emotion'
import * as tokens from 'style/tokens'
import rem from 'style/utils/rem'
import media from 'style/utils/media'
import ratio from 'style/utils/ratio'

const { mq } = tokens.layout
const gutter = rem(tokens.layout.grid.gutter / 2)

const setWidth = width => ({
  flexBasis: ratio(width),
  maxWidth: ratio(width)
})

const setOffset = offset => ({
  marginLeft: ratio(offset)
})

const root = ({
  order,
  offsetXs,
  offsetSm,
  offsetMd,
  offsetLg,
  offsetXl,
  xs,
  sm,
  md,
  lg,
  xl,
  hideSm,
  hideMd,
  hideLg,
  hideXl,
  customStyle = {},
  display = 'block'
}) => {
  const hasSm = sm || offsetSm
  const hasMd = md || offsetMd
  const hasLg = lg || offsetLg
  const hasXl = xl || offsetXl

  return css(
    {
      display,
      flexGrow: 0,
      flexShrink: 0,
      order,
      padding: gutter,
      width: '100%'
    },
    xs && setWidth(xs),
    offsetXs && setOffset(offsetXs),
    hasSm &&
      media(mq.smUp, css(sm && setWidth(sm), offsetSm && setOffset(offsetSm))),
    hasMd &&
      media(mq.mdUp, css(md && setWidth(md), offsetMd && setOffset(offsetMd))),
    hasLg &&
      media(mq.lgUp, css(lg && setWidth(lg), offsetLg && setOffset(offsetLg))),
    hasXl &&
      media(mq.xlUp, css(xl && setWidth(xl), offsetXl && setOffset(offsetXl))),
    hideSm &&
      media(
        mq.smOnly,
        css`
          display: none;
        `
      ),
    hideMd &&
      media(
        mq.mdDown,
        css`
          display: none;
        `
      ),
    hideLg &&
      media(
        mq.mdUp,
        css`
          display: none;
        `
      ),
    hideXl &&
      media(
        mq.xlUp,
        css`
          display: none;
        `
      ),
    customStyle
  )
}

export default {
  root
}
