import { hasEntries } from 'utils/array'
import { isValidPlatform } from 'utils/embed-extractor'

import isHtmlSafeToSkip from '../is-html-safe-to-skip'

const compatiblePosts = [
  'post',
  'longform',
  'sponsored_post',
  'sponsored_longform'
]

const isWidgetCompatible = ({
  acf_fc_layout,
  embed_link,
  html,
  item,
  publish_to_apple_news
}) => {
  // check embed widget compatibility if it's set to be published
  if (acf_fc_layout === 'embed' && publish_to_apple_news) {
    return isValidPlatform(embed_link)
  }
  // widget is html, check if content is crucial to rendering of the post
  if (acf_fc_layout === 'html') {
    return isHtmlSafeToSkip(html)
  }
  // 'listicle' widget
  if (acf_fc_layout === 'listicle') {
    // Listicle with 'embed' elements
    return item.every(({ media_type, embed }) => {
      // check embed compatibility within listicle
      if (media_type === 'embed') return isValidPlatform(embed)
      // otherwise assume compatibility as it'll be an image
      return true
    })
  }
  return true
}

export default post => {
  const widgets = post?.acf?.widgets
  // no AMP if Series (for now)
  if (post?.acf?.series?.slug) return false
  // no widgets is a bad sign
  if (!hasEntries(widgets)) return false
  // gotta have a link
  if (!post.link) return false

  return (
    // check post type compatibility, amp only supports standard text posts
    compatiblePosts.some(type => type === post.type) &&
    // check that we can render all of our widgets
    widgets.every(isWidgetCompatible)
  )
}
