import { css } from 'emotion'
import { anim, color, type, layout } from 'style/tokens'
import rem from 'style/utils/rem'
import media from 'style/utils/media'

/**
 * Use these styles whenever you are setting type that uses
 * the relevant type style
 */

// Headline
const canon = css({
  fontFamily: type.stack.primary,
  fontWeight: type.weight.bold,
  fontSize: type.sizes.md,
  lineHeight: type.leading.sm,
  letterSpacing: type.tracking.md
})

// Sub-heading, small sub-heading
const primer = css({
  fontFamily: type.stack.primary,
  fontSize: type.sizes.sm,
  fontWeight: type.weight.semibold,
  lineHeight: type.leading.sm
})

// UI elements
const pica = css({
  fontFamily: type.stack.secondary,
  fontWeight: type.weight.black,
  fontSize: type.sizes.sm,
  letterSpacing: type.tracking.md,
  lineHeight: type.leading.sm,
  textTransform: 'uppercase'
})

// paragraph
const brevier = css({
  fontFamily: type.stack.tertiary,
  fontSize: type.sizes.xxs,
  fontWeight: type.weight.semibold,
  letterSpacing: type.tracking.lg,
  lineHeight: type.leading.sm,
  textTransform: 'uppercase'
})

// paragraph
const body = css({
  MozOsxFontSmoothing: 'auto',
  WebkitFontSmoothing: 'auto',
  fontFamily: type.stack.tertiary,
  fontSize: type.sizes.xs,
  fontWeight: type.weight.light,
  lineHeight: type.leading.md,
  overflowWrap: 'break-word',
  wordWrap: 'break-word'
})

const anchor = (base, interacted) =>
  css({
    color: base,
    textDecoration: 'none',
    transition: `color ${anim.duration.instant} ${anim.easing.initial}`,
    '&:hover,&:focus': {
      color: interacted
    }
  })

/**
 * Nested HTML content
 * We have to use the descendant selector as we can't access
 * the elements via classnames
 */
const wysiwygSimple = colour =>
  css(body, {
    '& strong, & b': { fontWeight: type.weight.semibold },
    '& a': anchor(colour ? colour : color.core.primary, color.core.primaryDark)
  })
const wysiwyg = (colour, fontFamily = type.stack.tertiary) =>
  css({
    '& > *': css(
      body,
      {
        marginBottom: layout.sizes.sm,
        fontFamily
      },
      media(layout.mq.smUp, {
        fontSize: rem(19),
        letterSpacing: type.tracking.md,
        marginBottom: layout.sizes.md
      })
    ),
    '& strong, & b': { fontWeight: type.weight.semibold },
    '& ul, & ol': { marginLeft: layout.sizes.md },
    '& h3': css(
      canon,
      {
        fontSize: type.sizes.sm,
        marginBottom: layout.sizes.sm,
        marginTop: layout.sizes.xl
      },
      media(layout.mq.smUp, {
        fontSize: type.sizes.md
      })
    ),
    '& a': anchor(colour ? colour : color.core.primary, color.core.primaryDark)
  })

export default {
  canon,
  primer,
  pica,
  body,
  brevier,
  anchor,
  wysiwyg,
  wysiwygSimple
}
