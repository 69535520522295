import { css } from 'emotion'
import { layout } from 'style/tokens'

const countdownNumbers = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  @media ${layout.mq.mdUp} {
    margin: 0 0 80px 0;
  }
`

const countdownNumber = css`
  font-size: 46px;
  line-height: 40px;
  @media ${layout.mq.smUp} {
    font-size: 8.333vw;
    line-height: 160px;
  }
`

const countdownMetric = css`
  font-size: 12px;
  @media ${layout.mq.mdUp} {
    font-size: 30px;
  }
`

const countdownNumberContainer = css`
  z-index: 3;
  margin: 0 15px;
  @media ${layout.mq.mdUp} {
    margin: 0 50px;
  }
`

export default {
  countdownNumbers,
  countdownMetric,
  countdownNumber,
  countdownNumberContainer
}
