const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

// Add a 0 to single digits (1-9)
const addZero = value => (value <= 9 ? `0${value}` : value)

export const convertToUTC = date => (date.slice(-1) === 'Z' ? date : `${date}Z`)

export const isValidDate = date =>
  Object.prototype.toString.call(new Date(date)) === '[object Date]' &&
  !isNaN(new Date(date).getTime())

export const getDateFormat = date => {
  const d = new Date(date)
  const day = d.getDate()
  const month = d.getMonth()
  const year = d.getFullYear()
  const hours = d.getHours()
  const minutes = d.getMinutes()
  return {
    day: addZero(day),
    month: addZero(month + 1),
    monthIndex: month,
    year,
    hours: addZero(hours),
    minutes: addZero(minutes)
  }
}

export const formatDatePretty = date => {
  const { day, monthIndex, year } = getDateFormat(date)
  return `${day} ${monthNames[monthIndex]} ${year}`
}

export const formatDate = date => {
  // return null if invalid date
  if (!isValidDate(date)) {
    return {
      date: null,
      time: null
    }
  }
  const { day, month, year, hours, minutes } = getDateFormat(date)
  // Check for valid day [1-31]
  if (day === undefined || day <= 0 || day > 31) return false
  // Check for valid month [1-12]
  if (month === undefined || month < 1 || month > 12) return false
  // Check there is a year value
  if (year === undefined) return false
  // Returns date on this format: 2018-07-02 and 14:33
  return {
    date: `${year}-${month}-${day}`,
    time: `${hours}:${minutes}`
  }
}

export const isDateInThePast = date => {
  const dateToNewDate = new Date(date)
  const today = new Date()

  return today > dateToNewDate
}

export const isDateInTheFuture = date => {
  const dateToNewDate = new Date(date)
  const today = new Date()

  return today < dateToNewDate
}
