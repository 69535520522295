import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'

import rem from 'style/utils/rem'

const root = css({
  fill: 'currentColor',
  height: rem(18, true),
  maxWidth: '100%',
  verticalAlign: 'middle',
  width: rem(18, true)
})

const IconBase = ({ children, customStyle, viewBox, ...props }) => (
  <svg
    css={`
      ${root} ${customStyle};
    `}
    focusable="false"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
    {...props}
  >
    {children}
  </svg>
)

IconBase.defaultProps = {
  viewBox: '0 0 200 200'
}

IconBase.propTypes = {
  children: PropTypes.node.isRequired,
  customStyle: PropTypes.string,
  viewBox: PropTypes.string
}

export default IconBase
