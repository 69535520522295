import React from 'react'
import Helmet from 'react-helmet'

import { siteData } from 'root/config'
import toAbsolute from 'utils/link-to-absolute'
import { hasEntries } from 'utils/array'

import { Schema, AdditionalSchema } from 'components/_utility/schema'

import resourceHints from './helpers/resource-hints'
import shouldLinkToAmp from './helpers/link-to-amp'

const DocumentHead = props => {
  const isPreview = Boolean(
    props?._tapestry?.requestData?.queryParams?.tapestry_hash
  )
  const shareImage = toAbsolute(require(`public/default-share.jpg`))
  const visibility = props.acf?.visibility?.slug

  return (
    <>
      <Helmet
        htmlAttributes={{ prefix: 'og:http://ogp.me/ns' }}
        encodeSpecialCharacters={false}
        defaultTitle={siteData.site.name}
      >
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Add noindex if preview */}
        {(isPreview ||
          visibility === 'link-only' ||
          visibility === 'hidden') && (
          <meta name="robots" content="noindex, nofollow" />
        )}

        {/* Generic site information */}
        {props.link && <link rel="canonical" href={props.link} />}
        {__FB_APP_ID__ && <meta property="fb:app_id" content={__FB_APP_ID__} />}

        {/* AMP canonical URL */}
        {!isPreview &&
          shouldLinkToAmp(props) && (
            <link rel="amphtml" href={`${props.link}/amp`} />
          )}

        {/* Verification keys to  */}
        {__POCKET_VERIFICATION_KEY__ && (
          <meta
            name="pocket-site-verification"
            content={__POCKET_VERIFICATION_KEY__}
          />
        )}
        {__GOOGLE_VERIFICATION_KEY__ && (
          <meta
            name="google-site-verification"
            content={__GOOGLE_VERIFICATION_KEY__}
          />
        )}

        {/* Manifest */}
        <link rel="manifest" href={`/public/manifest.json`} />

        {/* Resource hints */}
        {resourceHints()}

        {/* @font-face source */}
        {__TYPEKIT_ID__ && (
          <link
            rel="stylesheet"
            href={`https://use.typekit.net/${__TYPEKIT_ID__}.css`}
          />
        )}

        {/* getpocket.com verification key */}
        {__POCKET_VERIFICATION_KEY__ && (
          <meta
            name="pocket-site-verification"
            content={`${__POCKET_VERIFICATION_KEY__}`}
          />
        )}

        {/* Apple touch-icons and Safari pinned tab icon */}
        {['120x120', '152x152', '180x180'].map(size => (
          <link
            href={require(`public/icon-${size}.png`)}
            key={size}
            rel="apple-touch-icon"
            sizes={size}
          />
        ))}
        <link rel="mask-icon" href={require(`public/icon-pinned-tab.svg`)} />

        {/* Standard icons */}
        {['16x16', '32x32', '96x96'].map(size => (
          <link
            href={require(`public/icon-${size}.png`)}
            key={size}
            rel="icon"
            sizes={size}
            type="image/png"
          />
        ))}

        {/*
        Retain this /public/favicon.ico route to comply with tapestrys default redirect from `/favicon.ico` to `/public/favicon.ico`
      */}
        <link rel="shortcut icon" href="/public/favicon.ico" />

        {/* Generic social metadata */}
        <meta property="og:site_name" content={siteData.site.name} />
        {props.link && (
          <meta property="og:url" name="twitter:url" content={props.link} />
        )}
        <meta
          content={`@${siteData.social.twitter.username}`}
          name="twitter:site"
        />

        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:dnt" content="on" />

        <meta name="twitter:image" property="og:image" content={shareImage} />
        <meta property="og:image:width" content="1680" />
        <meta property="og:image:height" content="880" />

        {/* Schema.org metadata for Google */}
        {Schema(props)}
        {AdditionalSchema(props)}

        {/* {BUILD_TARGET === 'server' && Boolean(__CMP_KEY__)
          ? require('_utility/context-cmp/helpers').getCDNScript(__CMP_KEY__)
          : null} */}
        {/* {require('_utility/context-cmp/helpers').getOptanonWrapper()}  */}

        {/* Inlined GA script to prioritise page view log */}
        {BUILD_TARGET === 'server'
          ? require('_utility/google-analytics').default({
              ...props,
              shouldRender: !isPreview,
              apikey: __GOOGLE_ANALYTICS_KEY__,
              optKey: __GOOGLE_OPTIMIZE_KEY__
            })
          : null}

        {BUILD_TARGET === 'server'
          ? require('_utility/hotjar').default()
          : null}
      </Helmet>
      {props.acf?.article_should_publish_to_facebook_news && (
        <Helmet>
          <meta property="article:opinion" content={false} />
          {hasEntries(props.acf?.package_ids) ? (
            <meta property="article:content_tier" content="locked" />
          ) : (
            <meta property="article:content_tier" content="free" />
          )}
          <meta property="article:location" content="region:eng" />
          <meta property="article:location" content="region:sct" />
          <meta property="article:location" content="region:nir" />
          <meta property="article:location" content="region:wls" />
        </Helmet>
      )}
    </>
  )
}

export default DocumentHead
