import React from 'react'
import PropTypes from 'prop-types'

import styles from './style'

const Html = ({ html, width }) =>
  html ? (
    <div
      className={styles.root(width)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  ) : null

Html.propTypes = {
  html: PropTypes.string.isRequired,
  width: PropTypes.oneOf(['small', 'medium', 'large', 'full']).isRequired
}

export default Html
