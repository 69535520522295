/**
 *
 * Post Officer
 * Maps the post type to the correct component for rendering
 *
 *              ________________
 *              \      __      /         __
 *               \_____()_____/         /  )
 *               '============`        /  /
 *                #---\  /---#        /  /
 *               (# @\| |/@  #)      /  /
 *                \   (_)   /       /  /
 *                |\ '---` /|      /  /
 *        _______/ \\_____// \____/ o_|
 *       /       \  /     \  /   / o_|
 *      / |           o|        / o_| \
 *     /  |  _____     |       / /   \ \
 *    /   |  |===|    o|      / /\    \ \
 *   |    |   \@/      |     / /  \    \ \
 *   |    |___________o|__/----)   \    \/
 *   |    '              ||  --)    \     |
 *   |___________________||  --)     \    /
 *        |           o|   ''''   |   \__/
 *        |            |          |
 *
 */

import React from 'react'
import Helmet from 'react-helmet'
import loadable from '@loadable/component'

import { siteData } from 'root/config'

import { convertToUTC, isValidDate } from 'utils/date'

import {
  isLongform,
  getAuthor,
  hasFullViewportComponent,
  heroType
} from 'utils/croissant'
import { transformImage } from 'utils/image'
import { hasEntries } from 'utils/array'

import useIsOnScreen from 'hooks/use-is-on-screen'

import Base from 'partials/_base'

const PostLongform = loadable(() => import('views/post-longform'))
const PostSeries = loadable(() => import('views/post-series'))
const PostStandard = loadable(() => import('views/post-standard'))
const VideoPost = loadable(() => import('views/post-video'))
const TrainingPlan = loadable(() => import('views/post-training-plan'))
const PostIssue = loadable(() => import('views/post-issue'))
const SalesPage = loadable(() => import('views/post-sales-page'))

const getPostView = ({ acf, type }) => {
  if (type === 'training_plan') return TrainingPlan
  if (type === 'video_post') return VideoPost
  if (acf.series) return PostSeries
  if (isLongform(type)) return PostLongform
  if (type === 'issue') return PostIssue
  if (type === 'salespage') return SalesPage
  return PostStandard
}

const shouldShowFooter = props => {
  if (props.type === 'issue') return false

  return true
}

const PostOfficer = props => {
  const { acf = {}, date_gmt, modified_gmt } = props
  const author = props._embedded?.author
  const category = acf?.category?.name
  const image = transformImage(acf?.hero_images?.[0]?.sizes?.letterbox, {
    w: 1680,
    h: 880
  })
  const isHeroParallax = heroType(props.acf).type === 'parallax'
  const fullViewportRequired =
    hasFullViewportComponent(acf?.widgets) || isHeroParallax
  const { addElementsToRef, isVisible } = useIsOnScreen(
    isHeroParallax ? 0 : 0.5
  )

  const View = getPostView(props)
  return (
    <Base
      {...props}
      hideHeader={fullViewportRequired && isVisible}
      showFooter={shouldShowFooter(props)}
    >
      <Helmet>
        <title>{acf.seo_title || acf.short_headline}</title>
        <meta name="description" content={acf.seo_description || acf.sell} />

        {/* Page specific social metadata */}
        <meta
          name="twitter:title"
          property="og:title"
          content={acf.share_title || acf.short_headline}
        />
        <meta
          name="twitter:description"
          property="og:description"
          content={acf.share_description || acf.sell}
        />
        {image && (
          <meta name="twitter:image" property="og:image" content={image} />
        )}
        {image && <meta property="og:image:width" content="1680" />}
        {image && <meta property="og:image:height" content="880" />}

        <meta property="og:type" content="article" />
        <meta
          property="article:publisher"
          content={siteData.social.facebook.page}
        />
        {hasEntries(author) && (
          <meta property="article:author" content={getAuthor(author)} />
        )}
        {isValidDate(date_gmt) && (
          <meta
            property="article:published_time"
            content={convertToUTC(date_gmt)}
          />
        )}
        {isValidDate(modified_gmt) && (
          <meta
            property="article:modified_time"
            content={convertToUTC(modified_gmt)}
          />
        )}
        {category && <meta property="article:section" content={category} />}
        {acf?.no_index && <meta name="robots" content="noindex" />}
      </Helmet>
      <View
        setReference={fullViewportRequired && addElementsToRef}
        {...props}
      />
    </Base>
  )
}

export default PostOfficer
