import React from 'react'

export default () =>
  [
    '//connect.facebook.net',
    '//embeds.stylist.co.uk',
    '//platform.twitter.com',
    '//quantcast.mgr.consensu.org',
    '//www.googletagmanager.com',
    '//www.googletagservices.com'
  ].map(link => <link rel="dns-prefetch" href={link} key={link} />)
