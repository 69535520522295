export default props => {
  // if we've already modified the items, e.g. ordered and numbered them
  // don't modify again, return the original props
  // although we want to rename item to items because WHY IS IT ITEM WHEN THERE ARE MULTIPLE
  if (props.modified) {
    return {
      ...props,
      items: props.item,
      modified: true
    }
  }

  let items = props.item

  if (props.numbered) {
    items = props.item.map((item, i) => ({
      ...item,
      index: i + 1
    }))
  }

  const processedItems = props.descending ? items.reverse() : items

  return {
    ...props,
    items: processedItems,
    modified: true
  }
}
