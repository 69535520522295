import { css } from 'emotion'
import media from 'style/utils/media'
import * as blocks from 'style/blocks'
import { anim, color, layout, type } from 'style/tokens'
import rem from 'style/utils/rem'

const root = css(
  {
    alignItems: 'center',
    backgroundColor: color.base.black,
    color: color.base.white,
    display: 'flex',
    flexDirection: 'column',
    padding: `${layout.sizes.lg} ${layout.sizes.md}`,
    textAlign: 'center'
  },
  media(layout.mq.mdUp, {
    padding: `${layout.sizes.xl} ${layout.sizes.md}`
  })
)

const main = css(
  {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: layout.sizes.md,
    maxWidth: rem(1400),
    width: '100%'
  },
  media(layout.mq.mdUp, {
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-around'
  })
)

const sectionCenter = css(
  media(layout.mq.mdDown, {
    marginBottom: layout.sizes.lg
  }),
  media(layout.mq.mdUp, {
    width: rem(250)
  })
)

const logoStandard = css({
  color: color.base.white,
  height: rem(32),
  marginBottom: layout.sizes.sm,
  width: '100%'
})

const linkStandard = css({
  color: color.base.white,
  display: 'inline-block'
})

const subtext = css(blocks.type.brevier, {
  fontSize: type.sizes.xxs,
  fontWeight: type.weight.light
})

const menuRoot = css({
  listStyle: 'none',
  marginBottom: layout.sizes.md,
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: layout.trunk.md,
  width: '100%'
})

const listItem = css({
  display: 'inline-block'
})

const link = css(blocks.type.brevier, {
  padding: `${layout.sizes.xxs} ${layout.sizes.xs}`,
  color: color.base.white,
  display: 'inline-block',
  textDecoration: 'none',
  transition: `color ${anim.duration.instant} ${anim.easing.initial}`,
  '&:hover,&:focus': {
    color: color.core.primary
  }
})

const listLink = css(blocks.type.body, {
  color: color.base.white,
  textTransform: 'uppercase',
  fontSize: type.sizes.xxs,
  fontWeight: type.weight.semibold,
  letterSpacing: type.tracking.md,
  marginBottom: layout.sizes.sm,
  textDecoration: 'none',
  background: 'none',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  '&:hover,&:focus': {
    color: color.core.primary
  }
})

export default {
  root,
  main,
  sectionCenter,
  logoStandard,
  linkStandard,
  subtext,
  listLink,
  link,
  listItem,
  menuRoot
}
