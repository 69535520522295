import { useState, useEffect } from 'react'
import { debounce } from 'throttle-debounce'

const resolveOrientation = () =>
  window.matchMedia('(orientation: landscape)').matches
    ? 'landscape'
    : 'portrait'

function useOrientation(delay = 500) {
  // defaults to portrait since most users are mobile
  if (typeof window === 'undefined') return 'portrait'
  const [orientation, setOrientation] = useState('portrait')

  useEffect(() => {
    setOrientation(resolveOrientation())

    const debouncedHandleResize = debounce(delay, () =>
      setOrientation(resolveOrientation())
    )

    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  return orientation
}

export default useOrientation
