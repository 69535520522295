import React, { useEffect, useState } from 'react'

import getUserData from '_utility/get-user-data'
import { bugsnag } from '_utility/error-boundary'

import { isPost } from 'utils/croissant'
import { hasEntries } from 'utils/array'

import usePostRequest from 'hooks/use-post-request'

import Modal from 'partials/modal'
import PopupBanner from 'partials/popup-banner'

import RestrictBySize from 'higher-order/restrict-by-size'

const getSegments = () => {
  try {
    return JSON.parse(window.localStorage.getItem('_psegs') || '[]')
  } catch (e) {
    return []
  }
}

const CmsPopup = ({
  postId = false,
  packageIds = false,
  seriesSlug = false,
  categorySlug = false,
  type = false,
  isSponsored = false
}) => {
  const { isAuthenticated } = getUserData()
  const { submitRequest } = usePostRequest(false, true)
  const [popupMeta, setPopupMeta] = useState(false)

  useEffect(() => {
    const fetchPopupMeta = async () => {
      const segments = getSegments()

      let query = {
        ...(isAuthenticated && { logged_user: true }),
        ...(hasEntries(segments) && { permutive_segment_ids: segments }),
        page_path: window.location.pathname
      }

      // If we have a post and ID, only send post ID
      if (type && isPost(type) && postId) {
        query = {
          ...query,
          post_id: postId,
          ...(hasEntries(segments) && { segments })
        }
      } else {
        // Otherwise send premium, series & category
        query = {
          ...query,
          ...(packageIds && { premium: packageIds }),
          ...(seriesSlug && { series: seriesSlug }),
          ...(categorySlug && { category: categorySlug })
        }
      }

      const results = await submitRequest(
        query,
        `${__CMS_ENDPOINT__}/wp-json/croissant/v2/popup`
      )

      if (results?.popup) return setPopupMeta(results.popup)

      return bugsnag.notify(
        JSON.stringify({ type: 'Error loading CMS popup', query })
      )
    }

    fetchPopupMeta()
  }, [])

  if (!popupMeta?.acf || popupMeta?.errors || isSponsored) return null

  const { acf, id, title } = popupMeta

  const props = {
    title: title?.rendered,
    backgroundColour: acf?.popup_background_colour,
    buttonColour: acf?.popup_button_colour,
    buttonLink: acf?.popup_button_link,
    buttonText: acf?.popup_button_text,
    buttonTextColour: acf?.popup_button_text_colour,
    externalLink: acf?.external_url,
    image: acf?.popup_image,
    text: acf?.popup_text,
    textColour: acf?.popup_text_colour,
    logo: acf?.popup_logo,
    emailCapture: acf?.email_capture,
    telemeteryId: acf?.telemetry_vertical_id,
    emailSuccessMessage: acf?.success_message,
    emailRepeatMessage: acf?.duplicate_email_message,
    id
  }

  return (
    <>
      <RestrictBySize minHeight={640} minWidth={800} method="and">
        <Modal {...props} />
      </RestrictBySize>
      <RestrictBySize maxHeight={639} maxWidth={799} method="or">
        <PopupBanner {...props} />
      </RestrictBySize>
    </>
  )
}

export default CmsPopup
