import React, { useContext } from 'react'

import RegionPreferenceContext from '_utility/context-region-preference'
import Anchor from 'partials/anchor'

const HomePageAnchor = ({ children, ...rest }) => {
  const { selectedRegion, getRegionPath } = useContext(RegionPreferenceContext)
  return (
    <Anchor
      {...rest}
      url={getRegionPath ? getRegionPath(selectedRegion) : '/'}
      style={{ textDecoration: 'none' }}
    >
      {children}
    </Anchor>
  )
}

export default HomePageAnchor
