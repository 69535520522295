import { css } from 'emotion'
import * as blocks from 'style/blocks'
import { anim, color, layout } from 'style/tokens'

const root = (fullWidth, small, theme, customStyle) => {
  const combinedStyleObj = {
    backgroundColor: theme.colors.primary,
    border: 0,
    color: theme.colors.textAccentColour,
    cursor: 'pointer',
    display: 'inline-block',
    marginTop: layout.sizes.sm,
    opacity: 1,
    padding: small
      ? `${layout.sizes.xs} ${layout.sizes.sm}`
      : `${layout.sizes.sm} ${layout.sizes.md}`,
    textDecoration: 'none',
    transition: `background-color ${anim.duration.quick} ${
      anim.easing.initial
    }`,
    width: fullWidth ? '100%' : 'auto',
    textAlign: fullWidth && 'center',
    ':focus': {
      outline: 'none'
    },
    '[disabled]': {
      backgroundColor: color.tonal[0],
      cursor: 'default'
    },
    ':not([disabled]):hover,:not([disabled]):focus': {
      backgroundColor: theme.colors.primaryDark
    }
  }

  return css(blocks.type.brevier, combinedStyleObj, customStyle)
}

export default {
  root
}
