import React from 'react'
import { withTheme } from 'emotion-theming'
import propTypes from 'prop-types'

import Button from 'partials/button'
import Anchor from 'partials/anchor'

import style from './style'

const Primary = withTheme(
  ({
    text,
    theme,
    fullWidth = false,
    isAnchored = false,
    customStyle = '',
    inverted = false,
    disabled = false,
    icon = false,
    datacy = 'primaryButton',
    ...rest
  }) => {
    return (
      <Button
        customStyle={style.primary({
          fullWidth,
          theme,
          isAnchored,
          customStyle,
          inverted,
          disabled
        })}
        type="submit"
        disabled={disabled}
        datacy={datacy}
        {...rest}
      >
        {icon && icon}
        {text}
      </Button>
    )
  }
)
Primary.propTypes = {
  text: propTypes.string,
  theme: propTypes.object,
  fullWidth: propTypes.bool,
  isAnchored: propTypes.bool,
  customStyle: propTypes.string
}

const AnchorButton = withTheme(
  ({
    text,
    url,
    fullWidth = false,
    basic = false,
    customStyle = '',
    disabled,
    theme,
    datacy = 'anchorButton'
  }) => {
    return (
      <Anchor
        url={url}
        className={style.anchorButton(
          fullWidth,
          basic,
          customStyle,
          theme,
          disabled
        )}
        data-cy={datacy}
      >
        {text}
      </Anchor>
    )
  }
)
AnchorButton.propTypes = {
  text: propTypes.string,
  url: propTypes.string,
  fullWidth: propTypes.bool,
  customStyle: propTypes.string,
  theme: propTypes.object
}

const Secondary = withTheme(
  ({ text, theme, datacy = 'secondaryButton', ...rest }) => (
    <Button
      customStyle={style.secondary(theme)}
      type="submit"
      {...rest}
      datacy={datacy}
    >
      {text}
    </Button>
  )
)
Secondary.propTypes = {
  text: propTypes.string,
  theme: propTypes.object
}

const BasicButton = withTheme(
  ({ children, theme, datacy = 'basicButton', ...rest }) => (
    <Button
      customStyle={style.basic(theme)}
      type="submit"
      {...rest}
      datacy={datacy}
    >
      {children}
    </Button>
  )
)
BasicButton.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node
  ]),
  theme: propTypes.object
}

const IconButton = withTheme(
  ({
    text,
    theme,
    icon,
    customStyle = '',
    datacy = 'iconButton',
    reduced = false,
    ...rest
  }) => (
    <Button
      customStyle={style.iconButton(theme, customStyle, reduced)}
      type="submit"
      datacy={datacy}
      {...rest}
    >
      <span>{icon}</span>
      <p>{text}</p>
    </Button>
  )
)
IconButton.propTypes = {
  text: propTypes.string,
  theme: propTypes.object,
  icon: propTypes.element
}

export { Primary, Secondary, BasicButton, IconButton, AnchorButton }
