import NoeDisplayBlack from '/public/NoeDisplay-Black.woff2'
import Hurme2Regular2 from '/public/Hurme-Geometric-Sans-2-Regular.woff2'
import Hurme2Regular from '/public/Hurme-Geometric-Sans-2-Regular.woff'
import Hurme2Bold2 from '/public/Hurme-Geometric-Sans-2-Bold.woff2'
import Hurme2Bold from '/public/Hurme-Geometric-Sans-2-Bold.woff'
import Hurme2Black2 from '/public/Hurme-Geometric-Sans-2-Black.woff2'
import Hurme2Black from '/public/Hurme-Geometric-Sans-2-Black.woff'
import Hurme3Regular2 from '/public/Hurme-Geometric-Sans-3-Regular.woff2'
import Hurme3Regular from '/public/Hurme-Geometric-Sans-3-Regular.woff'
import Hurme3Semibold2 from '/public/Hurme-Geometric-Sans-3-Semi-Bold.woff2'
import Hurme3Semibold from '/public/Hurme-Geometric-Sans-3-Semi-Bold.woff'
import Hurme3Bold2 from '/public/Hurme-Geometric-Sans-3-Bold.woff2'
import Hurme3Bold from '/public/Hurme-Geometric-Sans-3-Bold.woff'

import * as fontFaces from './fontFaces'

export {
  NoeDisplayBlack,
  Hurme2Regular2,
  Hurme2Bold2,
  Hurme2Black2,
  Hurme3Regular2,
  Hurme3Semibold2,
  Hurme3Bold2
}

export const fontFiles = [
  Hurme2Regular2,
  Hurme2Bold2,
  Hurme2Black2,
  Hurme3Regular2,
  Hurme3Semibold2,
  Hurme3Bold2
]

export const fontFace = `${fontFaces.sourceSerifPro}${fontFaces.futuraPTBold}${
  fontFaces.futuraPT
}${fontFaces.sourceSerifProRegular}${
  fontFaces.neoDisplay
}@font-face {font-family: 'Hurme Geometric Sans 2';src: url(${Hurme2Regular2}) format("woff2"), url(${Hurme2Regular}) format("woff");font-style: normal;font-weight: 400;font-display: swap;}@font-face {font-family: 'Hurme Geometric Sans 2';src: url(${Hurme2Bold2}) format("woff2"), url(${Hurme2Bold}) format("woff");font-style: normal;font-weight: 600;font-display: swap;}@font-face {font-family: 'Hurme Geometric Sans 2';src: url(${Hurme2Black2}) format("woff2"), url(${Hurme2Black}) format("woff");font-style: normal;font-weight: 900;font-display: swap;}@font-face {font-family: 'Hurme Geometric Sans 3';src: url(${Hurme3Regular2}) format("woff2"), url(${Hurme3Regular}) format("woff");font-style: normal;font-weight: 400;font-display: swap;}@font-face {font-family: 'Hurme Geometric Sans 3';src: url(${Hurme3Semibold2}) format("woff2"), url(${Hurme3Semibold}) format("woff");font-style: normal;font-weight: 600;font-display: swap;}@font-face {font-family: 'Hurme Geometric Sans 3';src: url(${Hurme3Bold2}) format("woff2"), url(${Hurme3Bold}) format("woff");font-style: normal;font-weight: 700;font-display: swap;} @font-face {font-family: 'futura-pt';src: url('https://use.typekit.net/af/c4c302/000000000000000000012192/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3') format('woff2');font-style: normal;font-weight: 600;}; @font-face { font-family: 'titling-gothic-fb-extended'; src: url('https://use.typekit.net/af/26c587/00000000000000003b9ae8f5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff2'); font-style: normal; font-weight: 700;} @font-face {
font-family:"futura-pt"; src:url("https://use.typekit.net/af/0c71d1/000000000000000000010097/27/l?subset_id=2&fvd=n8&v=3") format("woff2");font-display:auto;font-style:normal;font-weight:800;}`

/*
@font-face {
  font-family: 'Noe Display';
  src: url(${NoeDisplayBlack2}) format("woff2"),
       url(${NoeDisplayBlack}) format("woff");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: 'Hurme Geometric Sans 2';
  src: url(${Hurme2Regular2}) format("woff2"),
       url(${Hurme2Regular}) format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Hurme Geometric Sans 2';
  src: url(${Hurme2Bold2}) format("woff2"),
       url(${Hurme2Bold}) format("woff");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'Hurme Geometric Sans 2';
  src: url(${Hurme2Black2}) format("woff2"),
       url(${Hurme2Black}) format("woff");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: 'Hurme Geometric Sans 3';
  src: url(${Hurme3Regular2}) format("woff2"),
       url(${Hurme3Regular}) format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Hurme Geometric Sans 3';
  src: url(${Hurme3Semibold2}) format("woff2"),
       url(${Hurme3Semibold}) format("woff");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'Hurme Geometric Sans 3';
  src: url(${Hurme3Bold2}) format("woff2"),
       url(${Hurme3Bold}) format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'futura-pt';
  src: url('https://use.typekit.net/af/c4c302/000000000000000000012192/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3') format('woff2');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family:"futura-pt";
  src:url("https://use.typekit.net/af/0c71d1/000000000000000000010097/27/l?subset_id=2&fvd=n8&v=3") format("woff2");
  font-display:auto;
  font-style:normal;
  font-weight:800;
}
@font-face {
  font-family: 'titling-gothic-fb-extended';
  src: url('https://use.typekit.net/af/26c587/00000000000000003b9ae8f5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff2');
  font-style: normal;
  font-weight: 700;
}
*/
