import React from 'react'
import PropTypes from 'prop-types'
import styles from './wrapper-style'

const Wrapper = ({ children, customStyle, ...props }) => (
  <div className={styles.root(props, customStyle)}>{children}</div>
)

Wrapper.defaultProps = {
  width: 'lg'
}
Wrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default Wrapper
