import { css } from 'emotion'
import { layout } from 'style/tokens'

const loggedOutActions = () => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 32px;
  width: max-content;
  @media ${layout.mq.mdDown} {
    margin-left: 16px;
  }
`

const accountLinks = css`
  width: max-content;
  font-family: inherit;
  font-size: 13px;
  line-height: 14.3px;
  text-decoration: none;
  cursor: pointer;
  color: #000;
  background: none;
  border: none;
  display: flex;
  align-items: center;

  position: relative;
  padding: 0;
  margin: 0px 8px;
  @media ${layout.mq.mdDown} {
    font-size: 13px;
  }
  &:hover {
    text-decoration-line: underline;
  }
`

const userIcon = css`
  color: #000;
`

const userIconSignedIn = css`
  display: flex;
  align-items: center;
  text-transform: uppercase;

  @media ${layout.mq.mdUp} {
    &::after {
      content: 'My Account';
      margin-left: 8px;
      text-transform: uppercase;
    }
    &:hover {
      text-decoration-line: underline;
    }
  }
`

export default {
  accountLinks,
  loggedOutActions,
  userIcon,
  userIconSignedIn
}
