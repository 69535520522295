import { css, injectGlobal } from 'emotion'
import { layout } from 'style/tokens'
import { widths } from '../../partials/widget-officer/helpers'

const root = width =>
  css(
    {
      marginBottom: layout.sizes.lg,
      marginTop: layout.sizes.lg,
      '& #frame__grooming-awards': { textAlign: 'center' }
    },
    width === widths.FULL && {
      marginLeft: 'calc(-50vw + 50%)',
      width: '100vw'
    }
  )

injectGlobal`div + div > .CookieDeclaration { display: none; }`

export default {
  root
}
