import { css } from 'emotion'
import media from 'style/utils/media'
import * as blocks from 'style/blocks'
import { anim, color, layout, type } from 'style/tokens'
import rem from 'style/utils/rem'

const root = ({ theme }) =>
  css(
    {
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      color: theme.colors.textAccentColour,
      display: 'flex',
      flexDirection: 'column',
      padding: `${layout.sizes.lg} ${layout.sizes.md}`,
      textAlign: 'center'
    },
    media(layout.mq.mdUp, {
      padding: `${layout.sizes.xl} ${layout.sizes.md}`
    })
  )

const main = css(
  {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: layout.sizes.md,
    maxWidth: rem(1400),
    width: '100%'
  },
  media(layout.mq.mdUp, {
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-around'
  })
)

const sectionLeft = css(
  media(layout.mq.mdDown, {
    order: 3
  }),
  media(layout.mq.mdUp, {
    display: 'flex',
    width: `calc(50% - ${rem(125)})`
  })
)
const sectionCenter = css(
  media(layout.mq.mdDown, {
    marginBottom: layout.sizes.lg
  }),
  media(layout.mq.mdUp, {
    width: rem(250)
  })
)
const sectionRight = css(
  {
    alignItems: 'flex-end',
    display: 'flex'
  },
  media(layout.mq.mdDown, {
    justifyContent: 'center',
    marginBottom: layout.sizes.lg
  }),
  media(layout.mq.mdUp, {
    width: `calc(50% - ${rem(125)})`
  })
)

const logoStandard = css({
  color: 'currentColor',
  height: rem(32),
  marginBottom: layout.sizes.sm,
  width: '100%'
})
const logoCorporate = css({
  height: rem(42),
  width: rem(120)
})
const logoRegulator = css({
  height: rem(29),
  width: rem(82)
})

const linkStandard = css({
  color: 'currentColor',
  display: 'inline-block'
})
const linkCorporate = css({
  color: 'currentColor',
  marginLeft: layout.sizes.sm,
  transition: `color ${anim.duration.instant} ${anim.easing.initial}`,
  '&:hover,&:focus': {
    color: color.core.secondary
  }
})
const linkRegulator = css(
  {
    color: 'currentColor',
    marginRight: layout.sizes.xs,
    transition: `color ${anim.duration.instant} ${anim.easing.initial}`,
    '&:hover,&:focus': {
      color: color.core.secondary
    }
  },
  media(layout.mq.mdUp, {
    marginLeft: 'auto'
  })
)

const subtext = css(blocks.type.brevier, {
  fontSize: type.sizes.xxs,
  fontWeight: type.weight.light
})

const menuExternal = css(
  {
    marginBottom: 0
  },
  media(layout.mq.mdUp, {
    marginLeft: 0,
    width: 'auto'
  })
)

export default {
  root,
  main,
  sectionLeft,
  sectionCenter,
  sectionRight,
  logoStandard,
  logoCorporate,
  logoRegulator,
  linkStandard,
  linkCorporate,
  linkRegulator,
  subtext,
  menuExternal
}
