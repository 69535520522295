const apiEndpoint = __ALEXANDRIA_ENDPOINT__

export const api = {
  preferences: `${apiEndpoint}/emails`,
  signup: `${apiEndpoint}/emails/signup-to-many`,
  signupSingle: `${apiEndpoint}/emails/quick-signup`,
  subscribe: `${apiEndpoint}/emails/subscribe`,
  unsubscribe: `${apiEndpoint}/emails/unsubscribe`,
  unsubscribeFeedback: `${apiEndpoint}/emails/feedback`,
  unsubscribeTeam: `${apiEndpoint}/emails/unsubscribe-from-team`
}

const handleJSON = res => {
  if (!res.ok || typeof res.json !== 'function') {
    throw new Error(res.status)
  }
  return res.json()
}

export const POST = (url, body) =>
  fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  }).then(handleJSON)

export const GET = url =>
  fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }).then(handleJSON)

export const DELETE = (url, body) =>
  fetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  }).then(handleJSON)
