import React from 'react'
import PropTypes from 'prop-types'
import Video from './video'
import FillViewport from '../../higher-order/fill-viewport'
import { widths, orientations } from '../../partials/widget-officer/helpers'
import { isLongform } from 'utils/croissant'

const LoopingVideo = ({
  video,
  placeholder,
  mobile_video,
  mobile_placeholder,
  width,
  postType
}) => {
  if (!video) return null

  return (
    <FillViewport disable={width !== widths.FULL || !isLongform(postType)}>
      {/* landscape/main video */}
      {
        <Video
          url={video}
          placeholder={placeholder}
          orientation={mobile_video ? orientations.LANDSCAPE : ''}
        />
      }

      {/* mobile video if available */}
      {mobile_video && (
        <Video
          url={mobile_video}
          placeholder={mobile_placeholder}
          orientation={orientations.PORTRAIT}
        />
      )}
    </FillViewport>
  )
}

LoopingVideo.propTypes = {
  video: PropTypes.string.isRequired,
  mobile_video: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.shape({
    url: PropTypes.string.isRequired
  }),
  mobile_placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      url: PropTypes.string
    }),
    PropTypes.bool
  ]),
  postType: PropTypes.string,
  width: PropTypes.string
}

export default LoopingVideo
