const loadedScripts = []

export default ({ src, id, defer }) =>
  new Promise((resolve, reject) => {
    // cancel if already on the page OR script has already loaded
    if (
      id &&
      (window.document.getElementById(id) || loadedScripts.indexOf(id) > -1)
    ) {
      return
    }
    // first script on the page is the reference to append the new script to
    const ref = window.document.getElementsByTagName('script')[0]
    // lets create our script element and set the src
    const script = window.document.createElement('script')
    script.src = src
    script.async = true
    if (defer) script.defer = true
    if (id) script.id = id

    // append to page
    ref.parentNode.insertBefore(script, ref)

    // save reference
    if (id) loadedScripts.push(id)

    script.onload = () => {
      resolve({
        script: src,
        loaded: true,
        status: 'Loaded'
      })
    }

    script.onerror = () => {
      reject({
        script: src,
        loaded: false,
        status: 'Failed loading'
      })
    }
  })
