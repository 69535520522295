import siteData from './data.json'
import emails from './emails.json'
import form from './form'
import accountMenu from './account-menu.json'
import endpoints from './endpoints'
import features from './features'
import checkoutCopyDetails from './checkout-copy-details.json'
import responseMessages from './response-messages'

export {
  siteData,
  emails,
  form,
  accountMenu,
  endpoints,
  features,
  checkoutCopyDetails,
  responseMessages
}
