export const removeDuplicateEntries = (base = [], compare = [], key) => {
  if (!key) return base
  return base.reduce((arr, baseItem) => {
    // only add item to array if `compare` doesn't contain a matching key
    if (compare.some(compareItem => baseItem[key] === compareItem[key])) {
      return arr
    }
    return [...arr, baseItem]
  }, [])
}

export const dedupeArray = (arr = [], key) =>
  arr.filter((item, i, src) => src.map(({ id }) => id).indexOf(item[key]) === i)

export const hasEntries = (arr, length = 0) =>
  Array.isArray(arr) && arr.length > length

export const insertItem = (arr, insertAt, item) => {
  const _arr = [...arr]

  _arr.splice(insertAt, 0, item)

  return _arr
}

export const removeItem = (arr, i) => [
  ...arr.slice(0, i),
  ...arr.slice(i + 1, arr.length)
]
