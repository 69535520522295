import { css } from 'emotion'
import * as blocks from 'style/blocks'
import { color, layout, icons } from 'style/tokens'
import rem from 'style/utils/rem'

const caption = css(blocks.type.wysiwyg, { marginTop: layout.sizes.lg })

const fallback = css({
  backgroundImage: icons.logomark(color.tonal[0]),
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: `${rem(64)} ${rem(48)}`,
  border: `1px solid ${color.tonal[0]}`,
  borderRadius: rem(4),
  paddingTop: '50%'
})

export default {
  caption,
  fallback
}
