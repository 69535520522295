import 'core-js/modules/es6.object.assign'
import loadable from '@loadable/component'

import 'style/global'

import CustomError from 'views/custom-error'
import Page from 'views/page'
import PostOfficer from 'views/post-officer'
import Search from 'views/search'

const Emails = loadable(() => import('views/emails'))
const FrontPage = loadable(() => import('views/front-page'))
const Listing = loadable(() => import('views/listing'))
const Preview = loadable(() => import('views/_croissant-preview'))
const Series = loadable(() => import('views/series'))
const StylistStrong = loadable(() => import('views/stylist-strong'))
const SignupLandingPage = loadable(() => import('views/signup-landing-page'))
const Login = loadable(() => import('views/login'))
const Register = loadable(() => import('views/register'))
const ForgottenPassword = loadable(() => import('views/forgotten-password'))
const ResetPassword = loadable(() => import('views/reset-password'))
const Checkout = loadable(() => import('views/checkout'))
const NewAccountSetup = loadable(() => import('views/new-account-setup'))
const Profile = loadable(() => import('views/profile'))
const ThreeStepCheckout = loadable(() => import('views/three-step-checkout'))
const DigitalEditionCheckout = loadable(() =>
  import('views/digital-edition-checkout')
)
const PopupPreview = loadable(() => import('views/popup-preview'))

const SwtcTrainingPlans = loadable(() => import('views/swtc/training-plans'))
const SWTCHub = loadable(() => import('views/swtc/hub'))
const SwtcSubscribe = loadable(() => import('views/swtc/subscribe'))
const SwtcHowTo = loadable(() => import('views/swtc/how-to'))
const SwtcVideos = loadable(() => import('views/swtc/videos'))
const SwtcSales = loadable(() => import('views/swtc/sales'))

const CuriosityAcademy = loadable(() => import('views/the-curiosity-academy'))
const DigitalEdition = loadable(() => import('views/digital-edition'))
const StylistExtraHub = loadable(() => import('views/stylist-extra'))

const REQUIRED_SWTC_IDS = [__SWTC_MONTHLY_ID__, __SWTC_YEARLY_ID__]
const REQUIRED_STYLIST_EXTRA_IDS = [
  __STYLIST_EXTRA_MONTHLY_ID__,
  __STYLIST_EXTRA_ANNUAL_ID__,
  __STYLIST_EXTRA_VIP_ID__
]

export default {
  siteUrl: __CMS_ENDPOINT__,
  secondarySiteUrl: __GOUDA_ENDPOINT__,
  apiBasePath: '/wp-json/croissant/v1',
  components: { CustomError },
  proxyPaths:
    __RELEASE_STAGE__ === 'production'
      ? [
          '/ads.txt',
          { '/robots.txt': `${__S3_DOMAIN__}/robots.txt` },
          { '/sitemap/{file}': `${__S3_DOMAIN__}/sitemap/{file}` },
          { '/pages/{file}': `${__S3_DOMAIN__}/pages/{file}` },
          { '/google-news/{file}': `${__S3_DOMAIN__}/news/{file}` }
        ]
      : [{ '/robots.txt': `${__S3_DOMAIN__}/robots-staging.txt` }],
  redirectsEndpoint: __CMS_REDIRECTS__,
  redirectPaths: {
    // win channel is no longer controlled via CMS
    // promotions category should point to `/win` instead
    '/promotions': '/win',
    '/styleguide': '/public/style-guide',
    // mimic `/us` without providing route
    '/uk': '/',
    // old daily route is unnecessary now
    '/account': '/profile',
    '/sign-up': '/emails',
    '/account/my-content': '/account/my-content/following',
    '/account/settings': '/account/settings/my-details',
    '/account/my-content/following': '/account',
    '/account/my-content/saved-articles': '/account',
    '/account/my-content/my-events': '/account',
    '/account/my-content/my-subscriptions': '/account',
    '/account/my-content/offers': '/account',
    '/account/settings/my-details': '/account',
    '/account/settings/email-preferences': '/account',
    '/account/settings/help': '/account'
  },
  cacheKeyHandler: (request, cacheKey) => {
    // change cache key for Redis to ensure US users get the correct region option
    const isAmp = url =>
      typeof url === 'string' && url.length > 3 && url.slice(-4) === '/amp'

    let key = cacheKey
    if (!isAmp(key) && request.headers?.['cloudfront-viewer-country'] === 'US')
      key += ':region-us'
    return key
  },
  cachePurgeHandler: key => {
    return [key, key + ':region-us']
  },
  headers: ['cloudfront-viewer-country'],
  myStylistConfigEndpoint: 'my-stylist',
  routes: [
    // not sure what mraid is
    // resource/binary is a legacy image link
    { path: '/:a/:b/:c?/mraid.js', error: true },
    { path: '/resource/binary/:a/:b', error: true },
    // facebook domain verification Shortlist
    {
      path: '/dhpzfuucfh9fgmksu3urd9g391292r.html',
      component: () => 'dhpzfuucfh9fgmksu3urd9g391292r',
      options: {
        customDocument: ({ html }) => html,
        disableDoctype: true
      }
    },
    // facebook domain verification Stylist
    {
      path: '/y15u5qcff097fjf21p3m1y333gcgnr.html',
      component: () => 'y15u5qcff097fjf21p3m1y333gcgnr',
      options: {
        customDocument: ({ html }) => html,
        disableDoctype: true
      }
    },
    {
      path: '/',
      component: FrontPage,
      endpoint: {
        latest:
          'posts?sticky=0&per_page=30&categories_exclude=win,promotions&visibility_exclude=hidden,link-only,sitemap-only&post_types=post,longform,quiz_post,feature_list',
        sticky:
          'posts?sticky=1&per_page=4&categories_exclude=win,promotions&regions=en-gb&visibility_exclude=hidden,link-only,sitemap-only&post_types=post,longform,quiz_post,feature_list'
      }
    },
    {
      path: '/profile',
      component: Profile,
      options: {
        reducedFooter: true
      }
    },
    {
      path: '/digital-magazine',
      component: DigitalEdition,
      endpoint: {
        posts: 'posts?post_types=issue&per_page=1000'
      },
      options: {
        reducedFooter: true
      }
    },
    {
      path: '/login',
      component: Login,
      options: {
        reducedFooter: true
      }
    },
    {
      path: '/register',
      component: Register,
      options: {
        reducedFooter: true
      }
    },
    {
      path: '/forgotten-password',
      component: ForgottenPassword,
      options: {
        reducedFooter: true
      }
    },
    {
      path: '/reset-password',
      component: ResetPassword,
      options: {
        reducedFooter: true
      }
    },
    {
      path: '/checkout',
      component: Checkout,
      options: {
        reducedFooter: true
      }
    },
    {
      path: '/new-account-setup',
      component: NewAccountSetup,
      options: {
        reducedFooter: true
      }
    },
    {
      path: '/signup',
      component: SignupLandingPage,
      endpoint: { verticals: 'verticals' },
      options: {
        reducedFooter: true
      }
    },
    {
      path: '/emails',
      component: Emails,
      endpoint: {
        verticals: 'verticals',
        unsubQuestions: 'unsubscribe-questions'
      }
    },
    {
      path: '/search',
      component: Search
    },
    {
      path: '/strong',
      component: StylistStrong
    },
    {
      path: '/training-club',
      component: SWTCHub,
      endpoint: () =>
        `hub-page?post_types=video_post,longform,training_plan,how_to&packages=strong-women-training-club,swtc-monthly,swtc-yearly`,
      options: {
        apiBasePath: '/wp-json/croissant/v2',
        requiredIds: REQUIRED_SWTC_IDS,
        redirectPath: '/training-club/sales'
      }
    },
    {
      path: '/us',
      component: FrontPage,
      endpoint: {
        latest:
          'posts?sticky=0&per_page=30&regions=en-us&categories_exclude=win,promotions&visibility_exclude=hidden,link-only,sitemap-only&post_types=post,longform,quiz_post,feature_list',
        sticky:
          'posts?sticky=1&per_page=4&regions=en-us&categories_exclude=win,promotions&visibility_exclude=hidden,link-only,sitemap-only&post_types=post,longform,quiz_post,feature_list'
      }
    },
    {
      path: '/win',
      component: Listing,
      endpoint: {
        listing: `categories?slug=win`,
        posts: `posts?categories=win,promotions&sticky=1&visibility_exclude=hidden,link-only,sitemap-only&per_page=-1`
      }
    },
    {
      path: '/training-club/training-plans',
      component: SwtcTrainingPlans,
      endpoint: () => `posts?post_types=training_plan`,
      options: {
        requiredIds: REQUIRED_SWTC_IDS
      }
    },
    {
      path: '/training-club/subscribe',
      component: SwtcSubscribe,
      options: {
        reducedFooter: true
      }
    },
    {
      path: '/training-club/how-to',
      component: SwtcHowTo,
      endpoint: () =>
        `how-to?show_taxonomies=exercise_type,muscle_group&sort_tax=exercise_type&sort_term=strength`,
      options: {
        reducedFooter: true,
        apiBasePath: '/wp-json/croissant/v2',
        requiredIds: REQUIRED_SWTC_IDS
      }
    },
    {
      path: '/training-club/videos',
      component: SwtcVideos,
      endpoint: () =>
        `posts?post_types=video_post&packages=strong-women-training-club,swtc-monthly,swtc-yearly&show_authors`,
      options: {
        reducedFooter: true,
        apiBasePath: '/wp-json/croissant/v2',
        requiredIds: REQUIRED_SWTC_IDS
      }
    },
    {
      path: '/training-club/sales',
      component: SwtcSales
    },
    {
      path: '/stylist-extra',
      component: StylistExtraHub,
      endpoint: () => ({
        posts: `posts?per_page=6&post_types=post,longform,video_post&packages=stylist-extra,stylist-extra-annual,stylist-extra-monthly`,
        books: `posts?per_page=4&tags=extra-short-stories,extra-book-of-the-week,extra-write`,
        longReads: `posts?per_page=4&tags=extra-first-person,extra-self,extra-reports`,
        watch: `posts?per_page=4&tags=extra-how-to,extra-inspiration&post_types=video_post`,
        events: `posts?per_page=4&tags=extra-editor-events&post_types=video_post`,
        columnistOne: `posts?per_page=1&tags=extra-ask-billie,extra-billie-bhatia`,
        columnistTwo: `posts?per_page=1&tags=extra-honest-boss`,
        magazine: `posts?post_types=issue&per_page=5`
      }),
      options: {
        requiredIds: REQUIRED_STYLIST_EXTRA_IDS,
        redirectPath: '/subscription/stylist-extra/528238'
      }
    },
    BUILD_TARGET === 'server'
      ? {
          path: '/:category/:subcategory?/:slug/:id/amp-story',
          component: require('./amp-components/views/stories').default,
          endpoint: ({ id }) => `posts/${id}`,
          options: {
            customDocument: require('./amp-components/_stories').default,
            requiredIds: REQUIRED_SWTC_IDS // may need to re-think in future. For now AMP stories are SWTC only,
          }
        }
      : null,
    BUILD_TARGET === 'server'
      ? {
          path: '/:category/:subcategory?/:slug/:id/amp-story/player',
          component: require('./amp-components/views/story-player').default,
          endpoint: ({ id }) => `posts/${id}`,
          options: {
            customDocument: require('./amp-components/_stories').default,
            requiredIds: REQUIRED_SWTC_IDS // may need to re-think in future. For now AMP stories are SWTC only
          }
        }
      : null,
    {
      path: '/three-step-checkout',
      component: ThreeStepCheckout,
      options: {
        reducedFooter: true
      }
    },
    {
      path: '/the-curiosity-academy',
      component: CuriosityAcademy,
      endpoint: () => `posts?post_types=course`,
      options: {
        reducedFooter: true
      }
    },
    {
      path: '/digital-magazine/buy',
      component: DigitalEditionCheckout,
      options: {
        reducedFooter: true
      }
    },
    {
      path: '/about/:slug',
      component: Page,
      endpoint: ({ slug }) => `pages?slug=${slug}&_embed`
    },
    {
      path: '/author/:slug',
      component: Listing,
      endpoint: ({ slug }) => ({
        listing: `users?slug=${slug}`,
        posts: `posts?authors=${slug}&per_page=24&visibility_exclude=hidden,link-only,sitemap-only&post_types=post,sponsored_post,longform,sponsored_longform,quiz_post,sponsored_quiz_post,video_post`
      })
    },
    BUILD_TARGET === 'server'
      ? {
          path: '/email/:id',
          component: require('./email').default,
          endpoint: ({ id }) => `emails/${id}`,
          options: {
            apiBasePath: '/wp-json/croissant/v1',
            customDocument: require('./email/_document').default,
            customDoctype:
              '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">'
          }
        }
      : null,
    {
      path: '/email/:id/preview',
      component: Preview
    },
    {
      path: '/series/:slug',
      component: Series,
      endpoint: ({ slug }) => ({
        series: `series?slug=${slug}`,
        posts: `posts?series=${slug}&per_page=17&visibility_exclude=hidden,link-only,sitemap-only&post_types=post,sponsored_post,longform,sponsored_longform,quiz_post,sponsored_quiz_post`
      })
    },
    {
      path: '/tag/:slug',
      component: Listing,
      endpoint: ({ slug }) => ({
        listing: `tags?slug=${slug}`,
        posts: `posts?tags=${slug}&per_page=24&visibility_exclude=hidden,link-only,sitemap-only&post_types=post,sponsored_post,longform,sponsored_longform,quiz_post,video_post,sponsored_quiz_post`
      })
    },
    {
      path: '/:category/:subcategory?',
      component: Listing,
      endpoint: ({ category, subcategory }) => ({
        listing: `categories?slug=${subcategory || category}`,
        posts: `posts?categories=${subcategory ||
          category}&per_page=24&visibility_exclude=hidden,link-only,sitemap-only&post_types=post,sponsored_post,longform,sponsored_longform,quiz_post,sponsored_quiz_post`
      })
    },
    {
      path: '/:category/:subcategory?/:slug/:id/preview',
      component: Preview
    },
    {
      path: '/:category/:subcategory?/:slug/:id/popup-preview',
      component: PopupPreview
    },
    BUILD_TARGET === 'server'
      ? {
          path: '/:category/:subcategory?/:slug/:id/amp',
          component: require('./amp-components/views/post').default,
          endpoint: ({ id }) => `posts/${id}`,
          options: {
            customDocument: require('./amp-components/_document').default
          }
        }
      : null,
    {
      path: '/:category/:subcategory?/:slug/:id',
      component: PostOfficer,
      endpoint: ({ id }) => `posts/${id}`
    }
  ]
}
