import parseuri from 'parseUri'

export default {
  type: 'twitter',
  testType: url => {
    const { host } = parseuri(url)
    return host === 'mobile.twitter.com' || host === 'twitter.com'
  },
  extractData: ({ embed_link }) => {
    const match = RegExp(/status\/(\d*)/).exec(embed_link)
    return { id: Array.isArray(match) ? match[1] : null }
  }
}
