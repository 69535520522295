import React, { useState } from 'react'
import { withTheme } from 'emotion-theming'

import IconDown from 'assets/icon-down'
import IconUp from 'assets/icon-up'

import style from './style'

const Faq = ({ faqs, theme }) => {
  const [active, setActive] = useState(false)

  return (
    <section className={style.faq(theme)}>
      <h2>Frequently asked questions</h2>
      <div className={style.base} data-testid="faqs">
        {faqs.map((item, index) => {
          const { question, answer } = item
          const isActive = index === active

          return (
            <div key={index}>
              <div
                role="button"
                tabIndex={0}
                className={style.titleTab(theme)}
                onClick={() => setActive(index)}
                onKeyPress={() => setActive(index)}
              >
                <h3>{question}</h3>
                {isActive ? <IconUp /> : <IconDown />}
              </div>
              {isActive && (
                <div className={style.content}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: answer
                    }}
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default withTheme(Faq)
