import { css } from 'emotion'

const base = (theme, shouldShowOpacity) => css`
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;

    ${shouldShowOpacity &&
      `background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 80%);`};
  }
`

export default {
  base
}
