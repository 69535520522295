import { injectGlobal } from 'emotion'
import { color } from 'style/tokens'
import { fontFace } from 'style/fonts'

const colorDefault = color.tonal[5]

/*
  Global styles consisting of:
  * Normalize v8
  * A generic reset based on Eric Meyers
  * Some useful global styles
  * All font assets
*/
injectGlobal`body,sub,sup{position:relative}img,legend,svg{max-width:100%}button,hr,input{overflow:visible}progress,sub,sup{vertical-align:baseline}[type=checkbox],[type=radio],legend{box-sizing:border-box;padding:0}html{line-height:1.15;-webkit-text-size-adjust:100%;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;height:100%;overflow-x:hidden;overflow-y:scroll;color:${colorDefault}}details,main{display:block}h1{font-size:2em}hr{box-sizing:content-box;height:0}code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em}a{background-color:transparent}abbr[title]{border-bottom:none;text-decoration:underline dotted}b,strong{font-weight:bolder}small{font-size:80%}sub,sup{font-size:75%;line-height:0}sub{bottom:-.25em}sup{top:-.5em}img{border-style:none}button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}button,select{text-transform:none}[type=button],[type=reset],[type=submit],button{-webkit-appearance:button}legend{color:inherit;display:table;white-space:normal}textarea{overflow:auto}summary{display:list-item}[hidden],template{display:none}*,::after,::before{box-sizing:border-box}body{font-size:100%;min-height:100%}img,svg{font-style:italic;vertical-align:middle}img[height],img[width]{max-width:none}svg{height:auto}svg path{fill:currentColor}blockquote,body,caption,dd,dl,fieldset,figure,form,hr,iframe,legend,ol,p,pre,table,td,th,ul{margin:0;padding:0}h1,h2,h3,h4,h5,h6{font-weight:400;margin:0;padding:0}iframe{border:0;width:100%}${fontFace}
#root{display:flex;flex-direction:column;min-height:100vh}.emoji{width:1em;vertical-align:text-bottom}`

// Unminified reference to global CSS above

/*
body, sub, sup {
  position: relative
}

img, legend, svg {
  max-width: 100%
}

button, hr, input {
  overflow: visible
}

progress, sub, sup {
  vertical-align: baseline
}

[type=checkbox], [type=radio], legend {
  box-sizing: border-box;
  padding: 0
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: ${colorDefault};
  height: 100%;
  line-height: 1.15;
  overflow-x: hidden;
  overflow-y: scroll;
}

details, main {
  display: block
}

h1 {
  font-size: 2em
}

hr {
  box-sizing: content-box;
  height: 0
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em
}

a {
  background-color: transparent
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted
}

b, strong {
  font-weight: bolder
}

small {
  font-size: 80%
}

sub, sup {
  font-size: 75%;
  line-height: 0
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

img {
  border-style: none
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0
}

button, select {
  text-transform: none
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button
}

legend {
  color: inherit;
  display: table;
  white-space: normal
}

textarea {
  overflow: auto
}

summary {
  display: list-item
}

[hidden], template {
  display: none
}

*, ::after, ::before {
  box-sizing: border-box
}

body {
  font-size: 100%;
  min-height: 100%
}

img, svg {
  font-style: italic;
  vertical-align: middle
}

img[height], img[width] {
  max-width: none
}

svg {
  height: auto
}

svg path {
  fill: currentColor
}

blockquote, body, caption, dd, dl, fieldset, figure, form, hr, iframe, legend, ol, p, pre, table, td, th, ul {
  margin: 0;
  padding: 0
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  margin: 0;
  padding: 0
}

iframe {
  border: 0;
  width: 100%
}

${fontFace}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.emoji {
  width: 1em;
  vertical-align: text-bottom;
}
*/
