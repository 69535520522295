import React from 'react'
import PropTypes from 'prop-types'

import Anchor from 'partials/anchor'

import styles from './style'

const MenuInline = ({ customStyle, variant, menu, hasCookiePreferences }) => (
  <ul
    css={`
      ${styles.root} ${customStyle};
    `}
  >
    {menu.map(item => (
      <li key={item.url} className={styles.listItem}>
        <Anchor url={item.url} className={styles.link(variant)}>
          {item.text}
        </Anchor>
      </li>
    ))}
    {hasCookiePreferences && (
      <li className={styles.listItem}>
        <button
          className={styles.listLink}
          onClick={() => window.Optanon.ToggleInfoDisplay()}
        >
          Cookie Preferences
        </button>
      </li>
    )}
  </ul>
)

MenuInline.defaultProps = {
  variant: 'standard'
}
MenuInline.propTypes = {
  customStyle: PropTypes.string,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  variant: PropTypes.string
}

export default MenuInline
