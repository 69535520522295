import parseuri from 'parseUri'

export default {
  type: 'instagram',
  testType: url => parseuri(url).host === 'www.instagram.com',
  extractData: ({ embed_link, embed }) => {
    const idMatch = RegExp(/p\/(.*?)\//).exec(embed_link)
    const paddingMatch = RegExp(/padding-bottom:.*?(\d*.\d*)%/).exec(embed)
    const width = 480
    const height = Array.isArray(paddingMatch)
      ? Math.round((Number(paddingMatch[1]) / 100) * width)
      : 480
    return {
      id: Array.isArray(idMatch) ? idMatch[1] : null,
      width,
      height
    }
  }
}
