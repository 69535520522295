import React, { useState } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import { Box } from 'design-system'
import InView from 'partials/in-view'
import Badge from 'series/badge'

import { getImageSources } from 'utils/image'

import IconPadlock from 'assets/icon-padlock'

const Ratio = ({ ratio, ...rest }) => (
  <Box height={0} width="100%" pt={`${ratio * 100}%`} {...rest} />
)
const Preload = ({ src }) => (
  <Helmet>
    <link rel="preload" as="image" href={src} />
  </Helmet>
)
const Schema = ({ src, height, width }) => (
  <>
    <meta itemProp="url" content={src} />
    <meta itemProp="height" content={height} />
    <meta itemProp="width" content={width} />
  </>
)

const greyscaleOptions = {
  duotone: '000000,FFFFFF',
  'duotone-alpha': '100'
}

export const BackgroundImage = ({ crop, image, options, ...rest }) => {
  const { src } = getImageSources({ crop, image, options })
  return (
    <InView observer={{ rootMargin: '670px 0px' }} progressive>
      {({ target, inview }) => (
        <Box
          backgroundPosition="center center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          bg="tonal.0"
          innerRef={target}
          position="relative"
          style={{ backgroundImage: inview ? `url(${src})` : null }}
          {...rest}
        />
      )}
    </InView>
  )
}

const ImageStatic = ({
  alt,
  crop,
  image,
  imageSizes,
  options = {},
  preload,
  schema,
  isGreyScale = false,
  ...rest
}) => {
  if (isGreyScale) {
    options = {
      ...options,
      ...greyscaleOptions
    }
  }

  const { src, webpSrc, srcset, preloadSrc, height, width } = getImageSources({
    crop,
    image,
    imageSizes,
    options
  })

  if (!src && !srcset) return null

  return (
    <>
      {preload && <Preload src={preloadSrc} />}
      {schema && <Schema src={src} height={height} width={width} />}
      <picture>
        {webpSrc && <source src={webpSrc} type="image/webp" />}
        <source src={src} type="image/jpeg" />
        <img alt={alt || image.alt} src={src} {...rest} />
      </picture>
    </>
  )
}

const ImageLazy = ({
  alt,
  badge,
  crop,
  image,
  imageSizes,
  options = {},
  preload = false,
  schema,
  sizes = null,
  isPaid = false,
  isGreyScale = false
}) => {
  const [loaded, setLoaded] = useState(false)

  if (isGreyScale) {
    options = {
      ...options,
      ...greyscaleOptions
    }
  }

  const {
    src,
    srcset,
    preloadSrc,
    height,
    width,
    webpSrcSet = false,
    webpSrc
  } = getImageSources({
    crop,
    image,
    imageSizes,
    options
  })

  if (!src && !srcset) return null

  return (
    <InView observer={{ rootMargin: '670px 0px' }} progressive>
      {({ target, inview }) => (
        <Ratio
          bg="tonal.0"
          innerRef={target}
          overflow="hidden"
          position="relative"
          ratio={height / width}
          width="100%"
        >
          {isPaid && (
            <Box
              css={`
                position: absolute;
                top: 10px;
                left: 10px;
                z-index: 1;
                color: #000;
              `}
            >
              <IconPadlock />
            </Box>
          )}
          {schema && <Schema src={src} height={height} width={width} />}
          {preload && <Preload src={preloadSrc} />}
          {badge && <Badge url={badge} containerRatio={height / width} />}
          <div
            dangerouslySetInnerHTML={{
              __html: `<noscript><img src="${src}" ${
                alt ? `alt="${alt}"` : ''
              }/></noscript>`
            }}
          />
          <picture
            css={`
              opacity: ${loaded ? 1 : 0};
              transition: opacity 250ms ease-out;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              position: absolute;
            `}
          >
            {webpSrc && (
              <source
                srcSet={inview ? (webpSrcSet ? webpSrcSet : webpSrc) : null}
                type="image/webp"
                sizes={sizes}
              />
            )}
            <source
              srcSet={inview ? (srcset ? srcset : src) : null}
              type="image/jpeg"
              sizes={sizes}
            />
            <img
              alt={alt || image.alt}
              src={inview ? src : null}
              srcSet={inview ? srcset : null}
              sizes={inview ? sizes : null}
              onLoad={() => setLoaded(true)}
              css={`
                width: 100%;
              `}
            />
          </picture>
        </Ratio>
      )}
    </InView>
  )
}

const Image = ({ type, ...rest }) => {
  if (type === 'static') {
    return <ImageStatic {...rest} />
  }
  return <ImageLazy {...rest} />
}

Image.defaultProps = {
  type: 'lazy'
}
Image.propTypes = {
  alt: PropTypes.string,
  badge: PropTypes.string,
  crop: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string,
    sizes: PropTypes.object,
    url: PropTypes.string
  }),
  imageSizes: PropTypes.array,
  preload: PropTypes.bool,
  schema: PropTypes.bool,
  type: PropTypes.oneOf(['static', 'lazy'])
}

export default Image
