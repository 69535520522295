import { css } from 'emotion'
import * as blocks from 'style/blocks'
import { anim, color, layout, type } from 'style/tokens'

const root = ({ customStyle }) =>
  css(
    blocks.type.body,
    {
      fontSize: type.sizes.xxs,
      listStyle: 'none'
    },
    customStyle
  )

const term = show =>
  css(
    {
      display: 'inline-block',
      marginRight: layout.sizes.xxs
    },
    !show && blocks.display.hidden
  )
const definition = css({
  display: 'inline-block',
  '::after': {
    marginLeft: layout.sizes.xs,
    marginRight: layout.sizes.xs,
    display: 'inline-block',
    content: '"◆"',
    color: color.tonal[2]
  },
  ':last-child::after': {
    display: 'none'
  }
})
const link = customLinkStyle =>
  css(
    {
      color: color.base.black,
      textDecoration: 'none',
      fontWeight: type.weight.bold,
      transition: `color ${anim.duration.instant} ${anim.easing.initial}`
    },
    blocks.state.interact({
      color: color.coreDark.primary
    }),
    customLinkStyle
  )

export default {
  root,
  term,
  definition,
  link
}
