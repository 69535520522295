import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'

import { layout, color } from 'style/tokens'
import * as blocks from 'style/blocks'

const style = css(blocks.type.brevier, {
  backgroundColor: color.base.black,
  color: color.base.white,
  left: '-20em',
  padding: `${layout.sizes.sm}`,
  position: 'fixed',
  textDecoration: 'none',
  top: '-20em',
  zIndex: 20,
  ':focus': {
    left: '2em',
    top: '1em'
  }
})

const SkipLink = ({ children, to }) => (
  <a href={`#${to}`} css={style}>
    {children}
  </a>
)

SkipLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
}

export default SkipLink
