import { useState, useEffect } from 'react'
import { debounce } from 'throttle-debounce'

function useWindowHeight(delay = 500) {
  if (typeof window === 'undefined') return

  const [height, setHeight] = useState(window.innerHeight)

  const debouncedHandleResize = debounce(delay, () =>
    setHeight(window.innerHeight)
  )

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  return height
}

export default useWindowHeight
