import SHA1 from 'crypto-js/sha1'
import encodeUtf8 from 'crypto-js/enc-utf8'
import encodeBase64 from 'crypto-js/enc-base64'

export default email => {
  // Create hash from email and identifier
  const hash = SHA1(`${email}TelemetryPayload`)
  // Convert string to word array
  const wordArray = encodeUtf8.parse(`${hash}|${new Date().getTime()}`)
  // Generate Base64 token from word array
  return encodeBase64.stringify(wordArray)
}
