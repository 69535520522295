import React from 'react'
import PropTypes from 'prop-types'
import styles from './style'
import FillViewport from '../../higher-order/fill-viewport'
import EmbedContainer from './embed-container'
import { orientations, widths } from '../widget-officer/helpers'
import { isLongform } from 'utils/croissant'

const Embed = ({ embed, mobile_embed, target, caption, width, postType }) => {
  return (
    <figure>
      {/* expand image (but not "caption") to full width if required and post is longform */}
      <FillViewport disable={width !== widths.FULL || !isLongform(postType)}>
        {/* landscape/main embed */}
        {
          <EmbedContainer
            embed={embed}
            target={target}
            orientation={orientations.LANDSCAPE}
            mobileViewExists={mobile_embed}
          />
        }

        {/* portrait embed if available */}
        {mobile_embed && (
          <EmbedContainer
            embed={mobile_embed}
            target={target}
            orientation={orientations.PORTRAIT}
          />
        )}
      </FillViewport>
      {!embed && <div className={styles.fallback} />}
      {caption && (
        <figcaption
          className={styles.caption}
          dangerouslySetInnerHTML={{ __html: caption }}
        />
      )}
    </figure>
  )
}

Embed.propTypes = {
  embed: PropTypes.string,
  mobile_embed: PropTypes.string,
  caption: PropTypes.string,
  width: PropTypes.string,
  postType: PropTypes.string
}

export default Embed
