import { hasEntries } from 'utils/array'

const postTypes = [
  'longform',
  'post',
  'quiz_post',
  'feature_list',
  'sponsored_quiz_post',
  'sponsored_longform',
  'sponsored_post',
  'video_post',
  'salespage'
]

const sponsoredPostTypes = [
  'sponsored_quiz_post',
  'sponsored_longform',
  'sponsored_post'
]

const quizTypes = ['quiz_post', 'sponsored_quiz_post']
const longformTypes = ['longform', 'sponsored_longform']
const taxonomyTypes = ['post_tag', 'category', 'series']

export const isPost = type => postTypes.indexOf(type) > -1
export const isLongform = type => longformTypes.indexOf(type) > -1
export const isQuiz = type => quizTypes.indexOf(type) > -1
export const isReview = props =>
  props.type === 'longform' && props.acf && props.acf.review_rating
export const isFeatureList = type => type === 'feature_list'
export const isSalesPage = type => type === 'salespage'
export const isTaxonomy = type => taxonomyTypes.indexOf(type) > -1
export const isAuthor = props =>
  props.link && props.link.indexOf('/author/') > -1
export const isTag = props => props.taxonomy === 'post_tag'
export const isCategory = props => props.taxonomy === 'category'

export const isFrontPage = props => {
  const url = props?._tapestry?.requestData?.url
  return url === '/' || url === '/us'
}

export const isSponsored = type => sponsoredPostTypes.indexOf(type) > -1

export const getSeriesSlug = props => {
  // returns a series slug if available, empty string otherwise
  const isSeriesHomepage = Boolean(props.series_badge)
  const isSeriesArticle = Boolean(props.acf?.series?.slug)
  const isSeries = Boolean(isSeriesArticle || isSeriesHomepage)
  if (!isSeries) return ''
  const slug = isSeriesHomepage ? props.slug : props.acf.series.slug
  return slug
}

// defaults to Movie. logic must change if stylist reviews other things one day
export const getReviewedItemType = props => {
  switch (props.acf?.review_type) {
    case 'movie':
      return 'Movie'
    case 'series':
      return 'TVSeries'
    default:
      return 'Movie'
  }
}

// Takes an array of authors - if multiple inserts an ampersand between each author
export const getAuthor = (authorArray = []) => {
  if (authorArray.length === 0) return authorArray[0]?.name || null

  return authorArray.reduce((acc, i, _index) => {
    if (_index === 0) {
      acc = i.name
    } else {
      acc = `${acc} & ${i.name}`
    }

    return acc
  }, '')
}

// To be used when hiding the Nav bar if a component requires a height of 100vh (ie . parallax image component)
export const hasFullViewportComponent = (arr = []) => {
  if (!hasEntries(arr)) return false
  return (
    arr.filter(widget => widget.layout_interaction === 'parallax').length > 0
  )
}
// Identify which media to show in the hero of a post
export const heroType = post => {
  const {
    header_type,
    header_video_landscape_id,
    header_vimeo_landscape_id,
    header_vimeo_portrait_id,
    landscape_video_meta,
    portrait_video_meta,
    hero_images
  } = post

  const media = {
    hero: {
      type: 'image',
      images: hero_images
    },
    'hero-with-video': {
      type: 'video',
      id: header_video_landscape_id,
      metadata: landscape_video_meta || {
        ...post?.landscape_video_meta,
        description:
          post?.landscape_video_meta?.description || post?.seo_description
      }
    },
    'interactive-hero': {
      type: 'parallax',
      setReference: post?.setReference,
      ...post?.parallax_hero
    },
    'hero-with-vimeo': {
      type: 'vimeo',
      id: header_vimeo_landscape_id,
      layoutMode: [
        header_vimeo_landscape_id !== '' && 'landscape',
        header_vimeo_portrait_id !== '' && 'portriat'
      ],
      metadata: {
        ...(portrait_video_meta && { portrait: portrait_video_meta }),
        ...(landscape_video_meta && { landscape: landscape_video_meta })
      },
      background: post?.hero_autoplay
    }
  }

  if (!hasEntries(hero_images) && !header_vimeo_landscape_id)
    return {
      type: 'default'
    }

  return {
    ...media[header_type]
  }
}
