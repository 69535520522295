import { css } from 'emotion'
import { type } from 'style/tokens'

const root = css`
  font-family: ${type.stack.quinary};
  h3 {
    font-size: 32px;
    margin-bottom: 32px;
    font-weight: ${type.weight.bold};
  }
  p {
    font-size: 28px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`
const buttonCSS = `
  text-decoration: none;
  font-weight: bold;
  font-size: 12px;
  margin: 0 auto 32px auto;
  padding: 10px;
  transition: all 0.2s;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
`
const button = (buttonTextColour, buttonColour) => css`
  background-color: ${buttonColour};
  color: ${buttonTextColour};
  width: 230px;
  max-width: 90%;
  ${buttonCSS};
`

const submitButton = (buttonTextColour, buttonColour) => css`
  background-color: ${buttonColour};
  color: ${buttonTextColour};
  width: 100%;
  margin-top: 16px !important;
  ${buttonCSS};
`

const message = css`
  margin: 0 auto 32px auto;
  font-size: 20px !important;
  a {
    color: currentColor;
  }
`

export default {
  button,
  root,
  submitButton,
  message
}
