import { css } from 'emotion'
import rem from 'style/utils/rem'
import media from 'style/utils/media'

/**
 * Scale font-size fluidly between breakpoints
 * https://madebymike.com.au/writing/precise-control-responsive-typography/
 */
export default (
  minFont = 16,
  maxFont = 32,
  minScreen = 400,
  maxScreen = 1000
) =>
  css(
    {
      fontSize: rem(minFont)
    },
    media(`(min-width: ${rem(minScreen)})`, {
      fontSize: `calc(${rem(minFont)} + ${maxFont - minFont} * ((100vw - ${rem(
        minScreen
      )}) / ${maxScreen - minScreen}))`
    }),
    media(`(min-width: ${rem(maxScreen)})`, {
      fontSize: rem(maxFont)
    })
  )
