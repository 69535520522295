import React from 'react'
import { withTheme } from 'emotion-theming'
import { style } from 'styled-system'
import system from '@rebass/components/emotion'

const textTransform = style({
  prop: 'textTransform',
  cssProperty: 'textTransform'
})

const textDecoration = style({
  prop: 'textDecoration',
  cssProperty: 'textDecoration'
})

export const Text = system(
  { is: 'p', fontSize: 'xs', blacklist: ['textTransform', 'textDecoration'] },

  'color',
  'display',
  'space',

  'border',
  'borderRadius',

  'flex',
  'alignItems',
  'justifyContent',

  'position',
  'top',
  'right',
  'bottom',
  'left',
  'zIndex',

  'height',
  'width',
  'maxWidth',

  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',

  'letterSpacing',
  'lineHeight',
  'textAlign',

  textDecoration,
  textTransform
)

export const Caracas = system({
  extend: Text,
  fontFamily: 'NoeDisplay',
  fontWeight: 'black',
  letterSpacing: 'sm',
  lineHeight: 'md'
})

export const Toronto = system({
  extend: Text,
  fontFamily: 'HurmeGeometricSans3',
  fontWeight: 'bold',
  letterSpacing: 'sm',
  lineHeight: 'md'
})

export const Vladivostok = system({
  extend: Text,
  fontFamily: 'FuturaPT',
  letterSpacing: 'md',
  lineHeight: 'md'
})

export const Ljubljana = system({
  extend: Text,
  fontFamily: 'HurmeGeometricSans2',
  fontWeight: 'semibold',
  letterSpacing: 'xxxl',
  lineHeight: 'md',
  textTransform: 'uppercase'
})

export const Warsaw = system({
  extend: Text,
  fontFamily: 'HurmeGeometricSans3',
  fontWeight: 'normal',
  letterSpacing: 'md',
  lineHeight: 'sm',
  textTransform: 'uppercase'
})

export const Canberra = system({
  extend: Text,
  fontFamily: 'HurmeGeometricSans2',
  fontWeight: 'semibold',
  letterSpacing: 'lg',
  lineHeight: 'sm',
  textTransform: 'uppercase'
})

export const Cardiff = system({
  extend: Text,
  fontFamily: 'HurmeGeometricSans3',
  fontWeight: 'normal',
  letterSpacing: 'md',
  lineHeight: 'lg'
})

export const Brasilia = system({
  extend: Text,
  fontFamily: 'Ovo',
  fontWeight: 'normal',
  letterSpacing: 'sm',
  lineHeight: 'lg'
})

export const Wysiwyg = withTheme(({ style, text, theme, ...rest }) => (
  <Brasilia
    is="div"
    fontSize={['xs', null, 'sm']}
    css={`
      h3 {
        font-family: ${theme.fonts.HurmeGeometricSans2};
        font-size: ${theme.fontSizes.xs};
        font-weight: 600;
        letter-spacing: ${theme.letterSpacings.lg};
        line-height: ${theme.lineHeights.sm};
        margin-bottom: ${theme.space[3]};
        text-transform: uppercase;
      }
      ul {
        text-align: left;
      }
      p:not(:last-child),
      ul:not(:last-child) {
        margin-bottom: ${theme.space[3]};
        @media (min-width: ${theme.breakpoints[1]}) {
          margin-bottom: ${theme.space[4]};
        }
      }
      a {
        background-image: linear-gradient(
          270deg,
          currentColor 50%,
          currentColor 0
        );
        background-position: 0 1em;
        background-repeat: repeat-x;
        background-size: 1px 1px;
        color: ${theme.colors.accent};
        text-decoration: none;
        text-shadow: 0.03em 0.03em #fff, -0.03em -0.03em #fff,
          -0.03em 0.03em #fff, 0.03em -0.03em #fff, 0.03em 0 #fff,
          -0.03em 0 #fff;
        transition: color 125ms ease-out;
        :hover,
        :focus {
          color: ${theme.colors.accentDark};
        }
      }
      strong,
      b {
        font-weight: bold;
      }
      em,
      i {
        font-style: italic;
      }
      ${typeof style === 'string' ? style : ''};
    `}
    dangerouslySetInnerHTML={{ __html: text.trim() }}
    {...rest}
  />
))
