import { css } from 'emotion'

const primary = ({
  fullWidth,
  isAnchored,
  theme,
  customStyle = '',
  inverted,
  disabled = false
}) =>
  css(
    `
      width: ${
        fullWidth || isAnchored ? '100%' : theme.buttonSizes.membersArea
      };
      max-width: 100%;
      margin: 5px 0;
      padding: 15px 0;
      color: #000;
      background-color: ${inverted ? '#fff' : theme.colors.primary};
      transition: ${theme.animations.buttonHover};
      font-family: ${theme.fonts.HurmeGeometricSans3};
      text-transform: uppercase;
      font-size: 12px;
      border: ${theme.colors.primary} 2px solid;
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      font-weight: bold;

      &:hover {
        background-color: ${theme.colors.primaryDark};
        border-color: ${theme.colors.primaryDark};
      }

      ${isAnchored &&
        `
        position: fixed;
        bottom: 0;
        left: 0;
        margin: 0;
      `}

      &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
      }

      svg { 
        margin: 0 10px;
      }
    
      ${disabled ? 'opacity: 0.7;' : ''}
    `,
    customStyle
  )

const secondary = theme => css`
  width: ${theme.buttonSizes.membersArea} !important;
  max-width: 100%;
  margin: 5px 0 !important;
  padding: 15px 0 !important;
  background-color: ${theme.colors.grey} !important;
`

const basic = () => css`
  background: none !important;
  color: #000 !important;
  text-decoration: underline !important;
  padding: 0 !important;
  margin: 0 !important;
  text-transform: initial !important;
`

const iconButton = (theme, customStyle, reduced) =>
  css(
    `
  background-color: ${theme.colors.primary};
  color: ${theme.colors.black};
  display: flex;
  align-items: center;
  margin: 0;
  width: 400px;
  max-width: 100%;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  font-family: ${theme.fonts.HurmeGeometricSans3};
  ${!reduced && 'padding: 15px 0;'}
  cursor: pointer;
  transform: ${theme.animations.buttonHover};
  letter-spacing: initial;
  ${reduced && `height: 32px;`}

  span {
    color: transparent;
    padding: 0 15px 0 10px;
  }

  svg { 
    width: 16px;
    margin: 0 5px 0 0;
  }

  &:hover {
    border-color: ${theme.colors.primaryDark};
  }
`,
    customStyle
  )

const anchorButton = (fullWidth, basic, customStyle, theme, disabled) =>
  css(
    `
    ${customStyle}
    color: #000;
    font-family: ${theme.fonts.HurmeGeometricSans3};
    display: block;
    text-align: center;
    margin: 5px auto;
    padding: 15px;
    font-size: 12px;
    font-weight: bold;
    border: ${theme.colors.primary} 2px solid;
    transition: ${theme.animations.buttonHover};

    ${!basic &&
      `
      width: ${fullWidth ? '100%' : theme.buttonSizes.membersArea};
      text-decoration: none;
      text-transform: uppercase;
      background-color: ${theme.colors.primary};
      transform: ${theme.animations.buttonHover};

      &:hover {
        background-color: ${theme.colors.primaryDark} !important;
        border-color: ${theme.colors.primaryDark};
      }
    `}

    ${disabled &&
      `
      pointer-events: none;
      opacity: 0.7;
      cursor: not-allowed;
    `}
  `
  )

export default {
  primary,
  secondary,
  basic,
  iconButton,
  anchorButton
}
