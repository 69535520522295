/**
 * Typographic sizing scale
 * Loosely based on 1.25 (Major Third), from a base 16px
 */

const sizes = {
  xxxs: '0.625rem', // 10px
  xxs: '0.75rem', // ~ 12px
  xs: '1rem', // ~ 16px
  sm: '1.25rem', // ~ 20px
  md: '1.563rem', // ~ 25px
  lg: '2rem', // ~ 32px
  xl: '2.75rem', // ~ 44px
  xxl: '3.5rem' // ~ 56px
}

/**
 * Font weights
 */
const weight = {
  light: 300,
  normal: 400,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900
}

/**
 * Type-setting
 */

// letter-spacing
const tracking = {
  sm: '-0.02em',
  md: '0.015em',
  lg: '0.08em',
  xl: '0.12em'
}

// line-height
const leading = {
  sm: '1.1',
  md: '1.4',
  lg: '1.7'
}

const sans = '-apple-system, BlinkMacSystemFont, Helvetica Neue, sans-serif'
const serif = 'Georgia, Times New Roman, serif'

const stack = {
  primary: `Noe Display, ${serif}`,
  secondary: `"Hurme Geometric Sans 2", ${sans}`,
  tertiary: `"Hurme Geometric Sans 3", ${sans}`,
  quaternary: 'Ovo, serif',
  quinary: `"futura-pt",  ${sans}`,
  senary: `'titling-gothic-fb-extended', gill sans, sans-serif`
}

export default {
  leading,
  sizes,
  stack,
  tracking,
  weight
}
