import { css } from 'emotion'
import { anim, color, layout, type } from 'style/tokens'
import * as blocks from 'style/blocks'

const root = css({
  listStyle: 'none',
  marginBottom: layout.sizes.md,
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: layout.trunk.md,
  width: '100%'
})

const listItem = css({
  display: 'inline-block'
})

const link = variant =>
  css(
    variant === 'standard' &&
      css(blocks.type.brevier, {
        padding: `${layout.sizes.xxs} ${layout.sizes.xs}`
      }),
    variant === 'reduced' &&
      css(blocks.type.body, {
        fontSize: type.sizes.xxs,
        padding: `0 ${layout.sizes.xs}`
      }),
    {
      color: 'currentColor',
      display: 'inline-block',
      textDecoration: 'none',
      transition: `color ${anim.duration.instant} ${anim.easing.initial}`,
      '&:hover,&:focus': {
        color: color.core.secondary
      }
    }
  )

const listLink = css(blocks.type.body, {
  color: 'currentColor',
  textTransform: 'uppercase',
  fontSize: type.sizes.xxs,
  fontWeight: type.weight.semibold,
  letterSpacing: type.tracking.md,
  marginBottom: layout.sizes.sm,
  textDecoration: 'none',
  background: 'none',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  '&:hover,&:focus': {
    color: color.core.secondary
  }
})

export default {
  root,
  listItem,
  link,
  listLink
}
