import { css } from 'emotion'
import { layout, type } from 'style/tokens'

const quote = background => css`
  position: relative;
  background: ${background};
  width: 100%;
  min-height: 219px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  * {
    max-width: 722px;
  }
  q {
    color: currentColor;
    font-size: 32px;
    line-height: 35.2px;
    letter-spacing: 3.8px;
    font-weight: ${type.weight.bold};
    text-align: center;
    text-transform: uppercase;
    @media${layout.mq.smOnly} {
      font-size: 24px;
    }
  }
  cite {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: normal;
    font-style: normal;
    font-weight: ${type.weight.bold};
  }
  @media${layout.mq.smOnly} {
    width: 100vw;
  }
`

export default {
  quote
}
