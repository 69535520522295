import { css } from 'emotion'
import { layout } from 'style/blocks'

const root = (
  marginBottom,
  marginTop,
  paddingBottom,
  paddingTop,
  withMargin,
  withPadding
) =>
  css(
    withMargin &&
      layout.sectionMargin({
        bottom: marginBottom,
        top: marginTop
      }),
    withPadding &&
      layout.sectionPad({
        bottom: paddingBottom,
        top: paddingTop
      })
  )

export default {
  root
}
