import React from 'react'
import { transformImage } from 'utils/image'

const Video = ({ url, placeholder, orientation }) => (
  <video
    autoPlay
    loop
    muted
    playsInline
    poster={transformImage(placeholder.url, { w: 1200 })}
    preload="metadata"
    css={`
      ${orientation
        ? `
          display: none;
          width: 100%;
          @media (orientation: ${orientation}) {
            display: block;
          }`
        : `
          display: block;
          width: 100%;
          `};
    `}
  >
    <source src={url} type="video/mp4" />
  </video>
)

export default Video
