import React from 'react'
import { withTheme } from 'emotion-theming'
import { AnchorButton } from 'partials/buttons'
import parse from 'html-react-parser'

import style from './style.js'

const Ticket = ({
  button_link,
  button_text,
  ticket_description,
  ticket_details,
  ticket_price,
  background_colour,
  theme
}) => {
  return (
    <section className={style.ticket(background_colour)}>
      <h2>{ticket_description}</h2>
      <h3>{`£${ticket_price}`}</h3>
      <div>{parse(ticket_details)}</div>
      <AnchorButton
        url={button_link}
        text={button_text}
        theme={theme}
        customStyle={`
          border-color: white;
          color: ${background_colour};
        `}
      />
    </section>
  )
}

export default withTheme(Ticket)
