import toAbsolute from 'utils/link-to-absolute'

import SourceSerifProRegular from '/public/source-serif-pro-regular.woff2'
import NoeDisplayRegularItalic from '/public/NoeDisplay-RegularItalic.woff2'
import NoeDisplayRegular from '/public/NoeDisplay-Regular.woff2'
import NoeDisplayMediumItalic from '/public/NoeDisplay-MediumItalic.woff2'
import NoeDisplayMedium from '/public/NoeDisplay-Medium.woff2'
import NoeDisplayBoldItalic from '/public/NoeDisplay-BoldItalic.woff2'
import NoeDisplayBold from '/public/NoeDisplay-Bold.woff2'
import NoeDisplayBlackItalic from '/public/NoeDisplay-BlackItalic.woff2'
import NoeDisplayBlack from '/public/NoeDisplay-Black.woff2'

export const neoDisplay = `
    @font-face {
        font-family: 'Noe Display';
        src: url('${toAbsolute(NoeDisplayBlack)}') format('woff2');
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: 'Noe Display';
        src: url('${toAbsolute(NoeDisplayBoldItalic)}') format('woff2');
        font-weight: bold;
        font-style: italic;
    }

    @font-face {
        font-family: 'Noe Display';
        src: url('${toAbsolute(NoeDisplayMedium)}') format('woff2');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Noe Display';
        src: url('${toAbsolute(NoeDisplayMediumItalic)}') format('woff2');
        font-weight: 500;
        font-style: italic;
    }

    @font-face {
        font-family: 'Noe Display';
        src: url('${toAbsolute(NoeDisplayBold)}') format('woff2');
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: 'Noe Display';
        src: url('${toAbsolute(NoeDisplayRegular)}') format('woff2');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Noe Display';
        src: url('${toAbsolute(NoeDisplayRegularItalic)}') format('woff2');
        font-weight: normal;
        font-style: italic;
    }

    @font-face {
        font-family: 'Noe Display';
        src: url('${toAbsolute(NoeDisplayBlackItalic)}') format('woff2');
        font-weight: 900;
        font-style: italic;
    }
`
export const sourceSerifProRegular = `
  @font-face {
    font-family: 'Source Serif Pro';
    src: url('${toAbsolute(SourceSerifProRegular)}') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
`
export const futuraPT = `
@font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/1eb35a/000000000000000000010090/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/1eb35a/000000000000000000010090/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/1eb35a/000000000000000000010090/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:500;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/eb729a/000000000000000000010092/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/eb729a/000000000000000000010092/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/eb729a/000000000000000000010092/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
    }
`
export const futuraPTBold = `
    @font-face {
    font-family:"futura-pt-bold";
    src:url("https://use.typekit.net/af/053fc9/00000000000000003b9af1e4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/053fc9/00000000000000003b9af1e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/053fc9/00000000000000003b9af1e4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
    }
    
    @font-face {
    font-family:"futura-pt-bold";
    src:url("https://use.typekit.net/af/72575c/00000000000000003b9af1e5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/72575c/00000000000000003b9af1e5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/72575c/00000000000000003b9af1e5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
    }
`

export const sourceSerifPro = `
    @font-face {
    font-family:"source-serif-pro";
    src:url("https://use.typekit.net/af/ac5663/00000000000000007735a458/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/ac5663/00000000000000007735a458/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/ac5663/00000000000000007735a458/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
    }
    
    @font-face {
    font-family:"source-serif-pro";
    src:url("https://use.typekit.net/af/923169/00000000000000007735a459/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/923169/00000000000000007735a459/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/923169/00000000000000007735a459/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
    }
    
    @font-face {
    font-family:"source-serif-pro";
    src:url("https://use.typekit.net/af/3135a7/00000000000000007735a460/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/3135a7/00000000000000007735a460/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/3135a7/00000000000000007735a460/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
    }
    
    @font-face {
    font-family:"source-serif-pro";
    src:url("https://use.typekit.net/af/1fc4ef/00000000000000007735a45f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/1fc4ef/00000000000000007735a45f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/1fc4ef/00000000000000007735a45f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
    }
    
    @font-face {
    font-family:"source-serif-pro";
    src:url("https://use.typekit.net/af/052e55/00000000000000007735a467/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"),url("https://use.typekit.net/af/052e55/00000000000000007735a467/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"),url("https://use.typekit.net/af/052e55/00000000000000007735a467/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:600;font-stretch:normal;
    }
    
    @font-face {
    font-family:"source-serif-pro";
    src:url("https://use.typekit.net/af/7b989c/00000000000000007735a46e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/7b989c/00000000000000007735a46e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/7b989c/00000000000000007735a46e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
    }
`
