import React from 'react'
import parse from 'html-react-parser'

import style from './style.js'

const FreeText = ({ paragraph }) => (
  <div className={style.wrapper}>{parse(paragraph)}</div>
)

export default FreeText
