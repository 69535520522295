export default {
  DELETE: {
    UNFAVOURITE_ARTICLE: '/unfavourite-article',
    REMOVE_TREND: '/remove-trend'
  },
  GET: {
    VERTICALS: 'https://cms.stylist.co.uk/wp-json/croissant/v1/verticals', // needs changing (as does everything)
    PAYMENT_TOKEN: '/api/generate-braintree-key',
    PACKAGES: `/api/packages`,
    POST_META: 'https://run.mocky.io/v3/ed071727-4d03-4634-8c89-36fbbceddda3',
    RECOMMENDED_ARTICLES: '',
    FAVOURITED_ARTICLES: '/favourited-articles',
    GET_TRENDS: '/get-user-trends',
    SUBSCRIPTIONS: '/user-subscriptions',
    COMPLETED_ASSETS: '/completed'
  },
  POST: {
    LOGIN: '/api/login',
    REGISTER: '/api/register',
    LOGOUT: '/api/logout',
    SUBMIT_PAYMENT: '/create-transaction',
    FORGOT_PASSWORD: '/api/forgot-password',
    UPDATE_PASSWORD: '/update-password',
    UPDATE_USER: '/update-user-names',
    FAVOURITE_ARTICLE: '/favourite-article',
    SET_TRENDS: '/set-user-trends',
    VALIDATE_VOUCHER: '/validate-voucher',
    APPLY_VOUCHER: '/apply-voucher',
    SUMBIT_PROMO_CODE: '/validate-promo-code',
    SUBMIT_SUBSCRIPTION: '/create-subscription',
    GENERATE_SUBSCRIPTION_NONCE: '/generate-subscription-nonce',
    CANCEL_SUBSCRIPTION: '/cancel-subscription',
    CANCELLATION_FEEDBACK: '/cancel-feedback',
    COMPLETE_ASSET: '/complete',
    CHECK_ASSETS: '/check'
  }
}
