import React from 'react'
import { css } from 'emotion'
import { style } from 'styled-system'
import system from '@rebass/components/emotion'

import ratioUtil from 'style/utils/ratio'

const textDecoration = style({
  prop: 'textDecoration',
  cssProperty: 'textDecoration'
})

export const Box = system(
  { blacklist: ['textDecoration'] },

  'color',
  'display',
  'space',

  'backgroundImage',
  'backgroundPosition',
  'backgroundRepeat',
  'backgroundSize',
  'background',

  'border',
  'borderRadius',

  'top',
  'right',
  'bottom',
  'left',

  'height',
  'width',
  'maxWidth',
  'minWidth',
  'minHeight',

  'flex',
  'flexDirection',
  'flexWrap',
  'alignItems',
  'justifyContent',

  'overflow',
  'position',
  'zIndex',

  'opacity',

  'textAlign',
  textDecoration
)

const getImage = path => `url(${path})`

const interactionOverlay = (selector, color) => {
  if (!selector || !color) return null
  return css`
    &::after {
      background-color: ${color};
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 0.3s ease-out;
      width: 100%;
    }
    .${selector}:hover &::after,
    .${selector}:focus &::after {
      opacity: 0.3;
    }
    @media (hover: none), (hover: on-demand) {
      transition: opacity 0.1s ease-out;
      .${selector}:hover &::after,
      .${selector}:focus &::after {
        opacity: 0;
      }
      .${selector}:active &::after {
        opacity: 0.3;
      }
    }
  `
}

export const BackgroundImage = ({
  bg = 'tonal.0',
  children,
  parentInteractSelector,
  parentInteractOverlay,
  ratio,
  url,
  ...props
}) => {
  const pt = Array.isArray(ratio) ? ratio.map(ratioUtil) : ratioUtil(ratio)
  const image = Array.isArray(url) ? url.map(getImage) : getImage(url)

  return (
    <Box
      css={interactionOverlay(parentInteractSelector, parentInteractOverlay)}
      position="relative"
      width="100%"
      {...props}
    >
      <Box
        backgroundImage={Array.isArray(url) ? image : null}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        bg={bg}
        pt={pt}
        width="100%"
        style={!Array.isArray(url) ? { backgroundImage: image } : {}}
      />
      {children}
    </Box>
  )
}

export const Wrapper = ({ children, ...props }) => (
  <Box display="flex" flexWrap="wrap" maxWidth="large" mx="auto" {...props}>
    {children}
  </Box>
)

export const Fill = ({ children, ...props }) => (
  <Box left={0} top={0} bottom={0} right={0} position="absolute" {...props}>
    {children}
  </Box>
)

export const contentHover = ({
  from,
  to,
  parentInteractSelector: selector
}) => {
  if (!from || !to || !selector) return null
  return css`
    color: ${from};
    transition: color 0.3s ease-out;
    .${selector}:hover &,
    .${selector}:focus & {
      color: ${to};
    }
    @media (hover: none), (hover: on-demand) {
      transition: color 0.1s ease-out;
      .${selector}:hover &,
      .${selector}:focus & {
        color: ${from};
      }
      .${selector}:active & {
        color: ${to};
      }
    }
  `
}
