import { css } from 'emotion'
import * as blocks from 'style/blocks'
import { anim, color, layout } from 'style/tokens'
import rem from 'style/utils/rem'
import media from 'style/utils/media'

const root = css(
  {
    display: 'flex',
    color: color.tonal[5],
    position: 'relative',
    marginBottom: layout.sizes.xs
  },
  media(layout.mq.smOnly, {
    flexDirection: 'column',
    marginLeft: 'calc(-50vw + 50%)',
    width: '100vw'
  }),
  media(layout.mq.smUp, {
    borderBottom: `1px solid ${color.tonal[0]}`
  })
)

const hoverStyles = css({
  opacity: 0,
  transition: `opacity ${anim.duration.quick} ${anim.easing.initial}`
})

const image = css(
  media(layout.mq.smUp, {
    width: '40%'
  }),
  {
    '& div': blocks.display.transparentOverlay('after', hoverStyles, 'primary')
  }
)

const content = css(
  {
    paddingLeft: layout.sizes.sm,
    paddingRight: layout.sizes.sm,
    paddingBottom: layout.sizes.sm,
    textAlign: 'center'
  },
  media(layout.mq.smUp, {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    padding: `${layout.sizes.xs} ${layout.sizes.md}`,
    width: '60%'
  })
)

const title = css(blocks.type.canon, {
  fontSize: rem(22),
  marginBottom: rem(12),
  transition: `color ${anim.duration.quick} ${anim.easing.initial}`
})

const blockLink = css(
  blocks.layout.fill,
  blocks.display.absoluteAnchor,
  {
    zIndex: 1
  },
  blocks.state.interact({
    [`& ~ .${content.toString()} .${title.toString()}`]: {
      color: color.core.primary
    },
    [`& ~ .${image.toString()} > div::after`]: {
      opacity: 1
    }
  })
)

const category = css(
  {
    marginBottom: layout.sizes.xs,
    position: 'relative',
    zIndex: 1
  },
  media(layout.mq.smOnly, {
    bottom: layout.sizes.sm,
    backgroundColor: color.base.white,
    paddingTop: layout.sizes.xs,
    paddingRight: layout.sizes.md,
    paddingLeft: layout.sizes.md
  })
)

const meta = css(
  {
    marginTop: 'auto',
    display: 'none',
    position: 'relative',
    zIndex: 1
  },
  media(layout.mq.smUp, {
    display: 'block'
  })
)
const sponsor = css({
  marginTop: 'auto'
})

const body = css(blocks.type.body, {
  fontSize: rem(15),
  marginBottom: layout.sizes.sm
})

export default {
  blockLink,
  root,
  title,
  category,
  meta,
  sponsor,
  body,
  image,
  content
}
