import { css } from 'emotion'
import * as blocks from 'style/blocks'
import { color, layout, type } from 'style/tokens'

const root = css({
  border: `1px dashed ${color.tonal[2]}`,
  marginBottom: layout.sizes.lg,
  marginTop: layout.sizes.lg,
  padding: layout.sizes.lg,
  textAlign: 'center'
})
const title = css(blocks.type.brevier, {
  color: color.tonal[2],
  fontSize: type.sizes.xs
})

export default {
  root,
  title
}
