import twitter from './twitter'
import facebook from './facebook'
import instagram from './instagram'
import youtube from './youtube'
import gfycat from './gfycat'
import playbuzz from './playbuzz'

const platforms = [twitter, facebook, instagram, youtube, gfycat, playbuzz]

export const isValidPlatform = str => Boolean(getPlatform(str))

export const getPlatform = str => {
  const platform = platforms.filter(({ testType }) => testType(str))
  return platform.length ? platform[0] : false
}
