const hexToRgb = color => {
  const colorNoHash = color.charAt(0) === '#' ? color.slice(1) : color
  const f = parseInt(colorNoHash, 16)
  return [f >> 16, (f >> 8) & 0x00ff, f & 0x0000ff]
}

export const shade = (color, percent) => {
  if (!color || !percent) return color
  // https://stackoverflow.com/a/13542669
  const [R, G, B] = hexToRgb(color)
  const t = percent < 0 ? 0 : 255
  const p = percent < 0 ? percent * -1 : percent

  return `#${(
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  )
    .toString(16)
    .slice(1)}`
}

export const transparentise = (color, value = 0.3) =>
  `rgba(${hexToRgb(color)}, ${value})`

export const darken = color => shade(color, -0.2)

export const lighten = color => shade(color, 0.2)

export const pickAccessibleColor = (bg, option1, option2) => {
  // Implements the brightness calculation from this post:
  // http://alienryderflex.com/hsp.html
  const [R, G, B] = hexToRgb(bg)
  const hsp = Math.sqrt(0.299 * (R * R) + 0.587 * (G * G) + 0.114 * (B * B))
  // Using the HSP value, determine whether the color is light or dark
  return hsp > 164 ? option2 : option1
}
