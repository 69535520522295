import React from 'react'

const UpArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.025"
      height="9.427"
      viewBox="0 0 16.025 9.427"
      color="#FF8088"
    >
      <path
        id="Path_620"
        data-name="Path 620"
        d="M-14319,7426l7.307,7.306,7.3-7.306"
        transform="translate(-14303.68 7434.72) rotate(180)"
        fill="none"
        stroke="#000"
        strokeWidth="2"
      />
    </svg>
  )
}

export default UpArrow
