import React, { useContext } from 'react'
import { css } from 'emotion'

import SeriesContext from '_utility/context-series'
import AdvertSlot from 'partials/advert-slot'

import { layout } from 'style/tokens'

export const SiteAdvertHeader = () => {
  const { series } = useContext(SeriesContext)
  return (
    <AdvertSlot
      type="static"
      id="site-header"
      sizes={[
        {
          slot: [0, 0]
        },
        series.name
          ? {
              placeholder: [970, 250],
              slot: [[970, 250], [4, 1]],
              viewport: 980
            }
          : {
              placeholder: [970, 250],
              slot: [970, 250],
              viewport: 980
            }
      ]}
      style={{
        // default to a standard fixed size ad, add padding
        // when rendering, if not standard, remove padding
        container: ({ renderType }) => css`
          ${(!renderType || renderType === 'standard') && 'padding: 5px'};
          @media ${layout.mq.lgDown} {
            display: none;
          }
        `
      }}
      targeting={{ pos: 'top' }}
    />
  )
}
