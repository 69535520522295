import { css } from 'emotion'
import * as blocks from 'style/blocks'
import { anim, color, layout, type } from 'style/tokens'
import rem from 'style/utils/rem'
import media from 'style/utils/media'

const root = css(
  {
    color: color.base.black,
    display: 'flex',
    flexDirection: 'row',
    position: 'relative'
  },
  media(`${layout.mq.smUp} and ${layout.mq.lgDown}`, {
    flexDirection: 'column'
  })
)

const hoverStyles = css({
  opacity: 0,
  transition: `opacity ${anim.duration.quick} ${anim.easing.initial}`
})

const image = onExtraHubPage =>
  css(
    onExtraHubPage && {
      position: 'relative',
      width: '100%'
    },
    {
      width: '48%'
    },
    media(`${layout.mq.smUp} and ${layout.mq.lgDown}`, {
      width: '100%'
    }),
    {
      '& div': blocks.display.transparentOverlay(
        'after',
        hoverStyles,
        'primary'
      )
    }
  )

const category = css({
  marginBottom: layout.sizes.xs,
  position: 'relative',
  whiteSpace: 'normal',
  zIndex: 1
})

const content = css(
  {
    padding: `${layout.sizes.xxs} ${layout.sizes.sm}`,
    textAlign: 'left',
    width: '52%'
  },
  media(`${layout.mq.smUp} and ${layout.mq.lgDown}`, {
    padding: `${layout.sizes.xs}`,
    textAlign: 'center',
    width: '100%'
  }),
  media(`${layout.mq.mdUp} and ${layout.mq.lgDown}`, {
    padding: layout.sizes.xs
  }),
  media(layout.mq.lgUp, {
    padding: `${layout.sizes.xxs} 0 ${layout.sizes.xxs} ${layout.sizes.sm}`
  })
)

const title = css(blocks.type.brevier, {
  letterSpacing: rem(0.5, true),
  lineHeight: 1.21,
  fontWeight: type.weight.bold,
  fontSize: rem(14),
  textTransform: 'none',
  transition: `color ${anim.duration.quick} ${anim.easing.initial}`
})

const blockLink = onExtraHubPage =>
  css(
    blocks.layout.fill,
    blocks.display.absoluteAnchor,
    {
      zIndex: 1
    },
    blocks.state.interact({
      [`& ~ .${content.toString()} .${title.toString()}`]: {
        color: color.core.primary
      },
      [`& ~ .${image(onExtraHubPage).toString()} > div::after`]: {
        opacity: 1
      }
    })
  )

const playButton = css({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  svg: {
    transform: 'scale(0.5)'
  },
  circle: {
    fill: 'black'
  }
})

export default {
  root,
  title,
  image,
  category,
  content,
  blockLink,
  playButton
}
