import React from 'react'

import SinglePost from './single-post'
import MultiPost from './multi-post'

import styles from './style'

const RelatedArticles = ({ title, posts }) => {
  if (!posts || posts.length === 0) return null
  const isSinglePost = posts.length === 1
  return (
    <div className={styles.root}>
      <h2 className={styles.title(isSinglePost)}>{title}</h2>
      {isSinglePost ? (
        <SinglePost post={posts[0]} />
      ) : (
        <MultiPost posts={posts} />
      )}
    </div>
  )
}

RelatedArticles.defaultProps = {
  title: 'You may also like'
}

export default RelatedArticles
