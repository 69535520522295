import { css } from 'emotion'
import { layout, type } from 'style/tokens'

const H2 = css`
  h2 {
    text-transform: uppercase;
    font-weight: ${type.weight.extrabold};
    text-align: center;
    font-size: 32px;
    margin-bottom: 80px;
    @media${layout.mq.smOnly} {
      font-size: 32px;
      margin-bottom: 25px;
    }
  }
`
const H3 = css`
  h3 {
    font-weight: ${type.weight.bold};
    color: currentColor;
    font-size: 20px !important;
    text-align: left;
    padding-top: 12px;
    @media${layout.mq.smOnly} {
      font-size: 20px;
    }
  }
`
const P = css`
  p {
    font-weight: normal;
    color: currentColor;
    font-size: 19px;
    @media${layout.mq.smOnly} {
      font-size: 20px;
    }
  }
`

const faq = theme => css`
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: ${theme.fonts.HurmeGeometricSans3};
  ${H2}
  ${H3}
  ${P}
  * {
    text-align: left;
  }
  div:first-of-type {
    max-width: 900px;
    width: 100%;
  }
  ul {
    font-weight: normal;
    list-style-position: inside;
    li:not(:last-child) {
      margin-bottom: 5px;
      &::marker {
        width: min-content;
      }
    }
  }
`

const titleTab = theme => css`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  height: 60px;
  border-top: 1px solid #e2e2e2;
  position: relative;
  svg {
    margin-left: 1rem;
    path {
      stroke: ${theme.colors.primary};
    }
  }
  &:hover {
    &::before {
      content: '';
      position: absolute;
      top: 15%;
      right: -12px;
      width: 40px;
      height: 40px;
      border: 1px solid ${theme.colors.primary};
      border-radius: 50%;
    }
  }
`

const content = css`
  margin: 0 0 16px 0;
  ul,
  ol {
    padding: 0 16px;
  }
`

export default {
  faq,
  titleTab,
  content
}
