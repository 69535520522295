import getUserData from '_utility/get-user-data'

import { hasEntries } from 'utils/array'

const isSubscribed = ids => {
  const { subscriptions } = getUserData()

  if (!ids || !hasEntries(subscriptions)) return false

  return ids.some(id => subscriptions.includes(parseInt(id, 10)))
}

export default isSubscribed
