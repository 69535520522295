import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'emotion-theming'

import { type } from 'style/tokens'

import styles from './style'

const Paragraph = ({ index, paragraph, theme }) => {
  let formattedParagraph = paragraph

  return paragraph ? (
    <div
      className={styles.root({ index, font: type.stack.tertiary, theme })}
      dangerouslySetInnerHTML={{ __html: formattedParagraph }}
    />
  ) : null
}

Paragraph.propTypes = {
  index: PropTypes.number,
  paragraph: PropTypes.string.isRequired
}

export default withTheme(Paragraph)
