import { css } from 'emotion'
import media from 'style/utils/media'
import { layout, color, type } from 'style/tokens'

const root = (isVisible, duration) =>
  css(
    {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      backgroundColor: '#3dd2be',
      textAlign: 'center',
      width: '100%',
      position: 'fixed',
      left: 0,
      bottom: 0,
      zIndex: 8,
      transform: `${isVisible ? null : 'translateY(100%)'}`,
      transition: `all ${duration}ms ease-out`,
      visibility: `${isVisible ? 'visible' : 'hidden'}`,
      fontFamily: type.stack.quinary,
      h3: {
        fontSize: '14px',
        fontWeight: type.weight.bold,
        marginBottom: '16px'
      },
      p: {
        fontSize: '14px',
        '&:not(:last-child)': {
          marginBottom: '16px'
        }
      },
      button: {
        fontSize: '12px',
        textTransformation: 'none'
      }
    },
    css(media(layout.mq.smUp, { paddingRight: '24px' }))
  )

const closeBtn = css({
  color: color.base.black,
  position: 'absolute',
  right: '1%',
  top: '4%',
  height: '24px',
  width: '24px',
  cursor: 'pointer'
})

export default { root, closeBtn }
