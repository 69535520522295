import styled from '@emotion/styled'
import { layout, color } from 'style/tokens'

export const Divider = styled.hr`
  grid-row: 2 / 3;
  grid-column: 1 / -1;
  /* end of parent related css */

  border: 0;
  margin: 0;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100vw;
    margin: 0 -60px;
    border-bottom: 1px solid rgb(208,208,208, .32);
  }
  @media ${layout.mq.mdUp} and ${layout.mq.xlDown} {
    grid-row: 3 / 4;  
    &::after {
      margin: 0 -32px;
    }
  }
  @media ${layout.mq.mdDown} {
    margin: 0;
    padding-top: 8px;
    &::after {
      margin: 0 -16px;
    }
  }
`
export const CloseButton = styled.button`
  justify-self: center;
  height: min-content;
  text-transform: uppercase;
  background: none;
  border: none;
  color: currentColor;
  font-size: 13px;
  line-height: 14.3px;
  letter-spacing: 1.5px;
  margin-top: auto;
  padding: 32px 0;

  @media ${layout.mq.mdUp} {
    position: absolute;
    right: 0;
    margin: 0;
    padding: 0 16px;
  }
`

export const VipButton = styled.a`
  background: ${color.verticals.teal};
  padding: 8px 15px;
  text-decoration: none;
  text-transform: uppercase;
  color: currentColor;
  border-radius: 16px;
  font-size: 13px;
  height: min-content;

  @media ${layout.mq.smUp} {
    margin-left: 16px;
  }
`

export const DropDownButton = styled.button`
  background: none;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  color: currentColor;
  svg {
    color: currentColor;
    margin-left: 12px;
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out;
    * {
      stroke-width: 1;
      stroke: currentColor;
      fill: none;
    }
  }
  ${props =>
    props.state &&
    `
      border-bottom: 1px solid black;
      padding-bottom: 5px;
      svg {
        transform: rotate(180deg);
      }
    @media ${layout.mq.mdDown} {
      border-bottom: none;
      &::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 25%;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 7px solid black;
      }
    }
  `};
`

export const Ad = styled.a`
  grid-column: 1 / -1;
  /* end of parent related css */

  background-image: url('/public/swtc-ad.png');
  background-size: contain;
  position: relative;
  width: 300px;
  height: 250px;
  background-size: 100% 100%;
  @media ${layout.mq.mdUp} {
    grid-column: 1 / 2;
  }
`

export const AllIssuesButton = styled.a`
  font-size: 19px;
  font-weight: bold;
  line-height: 26.6px;
  letter-spacing: 0.3px;
  text-transform: none;
  text-decoration: none;
  color: currentColor;
  display: flex;
  align-items: center;
  svg {
    transform: rotate(90deg) !important;
    margin-left: 8px;
    * {
      stroke: black;
    }
  }
  @media ${layout.mq.mdDown} {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    /* end of parent related css */

    font-size: 12px;
    line-height: 13.2px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    svg {
      display: inline !important;
      width: 14px !important;
      height: 14px !important;
      * {
        stroke: white !important;
      }
    }
  }
  @media (max-width: 450px) {
    align-self: end;
  }
`

export const H3 = styled.h3`
  font-size: 19px;
  font-weight: bold;
  line-height: 26.6px;
  letter-spacing: 0.3px;
  @media ${layout.mq.mdDown} {
    font-size: 12px;
    line-height: 13.2px;
    letter-spacing: 1.5px;
  }
`

export const articles = `
  grid-column: 1 / -1;
  /* end of parent related css */

  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  grid-template-rows: min-content 1fr;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 16px;

  h2 {
    grid-column: 1 / 6;
    grid-row: 1 / 2;
    justify-self: center;
  }

  svg {
    transform: none !important;
  }

  > * {
    padding-top: 0px !important;
  }
  @media ${layout.mq.xlDown} {
    grid-column: 1 / -1;
  }
  @media ${layout.mq.mdDown} {
    grid-column: 1 / -1;

    display: grid;
    grid-auto-flow: row;
    grid-gap: 0px;
    grid-template-columns: 1fr;

    h2 {
        justify-self: start;
    }

    article {
        grid-column: 1 / 2;
        &:not(:first-of-type) {
          padding-top: 16px !important;
        }
    }
  }

`

export const issue = `
  grid-column: 1 / 2;
  grid-row: 1 / end;
  grid-template-columns: 1fr;

  /* end of parent related css */

  @media ${layout.mq.mdDown} {
    order: -1;
  }
`

export const workouts = `
  * {
    grid-column: 1 / 2;
  }
  /* end of parent related css */

  a {
    color: ${color.verticals.teal};;
  }

  svg {
    transform: none !important;
  }

  @media ${layout.mq.mdDown} {
    h3 {
      color: white;
    }
  }
`
export const H1 = styled.p`
  font-weight: bold;
  letter-spacing: 2.8px;
  font-size: 28px;
  text-transform: uppercase;
  @media ${layout.mq.smOnly} {
    font-size: 24px;
  }
`
