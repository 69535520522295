import React from 'react'
import { withTheme } from 'emotion-theming'
import { hasEntries } from 'utils/array'
import { Grid, Col } from 'partials/grid'
import Image from 'partials/image'

import style from './style.js'

const ImageCollection = withTheme(({ images, images_links }) => {
  if (!hasEntries(images) || !images.every(image => Boolean(image.url)))
    return null

  return (
    <Grid justify="center" customStyle={style.base}>
      {images.map((image, i) => {
        const link = hasEntries(images_links) && images_links[i]
        return (
          <Col
            xs={1 / 1}
            sm={1 / 2}
            md={1 / 3}
            key={i}
            customStyle={style.wrapper}
          >
            <a href={link}>
              <Image image={image} crop="landscape" schema />
            </a>
          </Col>
        )
      })}
    </Grid>
  )
})

export default ImageCollection
