import React from 'react'
import PropTypes from 'prop-types'

import styles from './style'

const SubHeading = props => {
  const Tag = props.tag
  // nothing to render
  if (!props.children && !props.text) {
    return null
  }
  // if special tags required e.g. <Anchor>
  if (props.children) {
    return (
      <Tag data-testid="subHeading__header" className={styles.root(props)}>
        {props.children}
      </Tag>
    )
  }
  // otherwise, text rendering
  return (
    <Tag
      data-testid="subHeading__header"
      className={styles.root(props)}
      dangerouslySetInnerHTML={{
        __html: props.text
      }}
    />
  )
}

SubHeading.defaultProps = {
  alignment: 'center',
  tag: 'h2'
}
SubHeading.propTypes = {
  alignment: PropTypes.oneOf(['center', 'left', 'right']),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  highlight: PropTypes.bool,
  large: PropTypes.bool,
  small: PropTypes.bool,
  tag: PropTypes.string,
  text: PropTypes.string,
  xlarge: PropTypes.bool
}

export default SubHeading
