import React from 'react'
import PropTypes from 'prop-types'

import Anchor from 'partials/anchor'
import styles from './style'

const Category = ({ name, slug, parent, suffix, variant, customStyle }) => {
  if (!slug || !name) return null

  const url = parent && parent.slug ? `/${parent.slug}/${slug}` : `/${slug}`
  const text = suffix ? `${name} ${suffix}` : name
  return (
    <Anchor
      className={`${styles.root({ variant })} ${styles.colour({
        slug,
        variant
      })} ${customStyle}`}
      url={url}
    >
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </Anchor>
  )
}

Category.propTypes = {
  customStyle: PropTypes.string,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quarternary']),
  parent: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      slug: PropTypes.string.isRequired
    })
  ])
}
Category.defaultProps = {
  variant: 'primary'
}

export default Category
