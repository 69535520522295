import { css } from 'emotion'
import media from 'style/utils/media'
import * as blocks from 'style/blocks'
import { color, layout, type } from 'style/tokens'
import rem from 'style/utils/rem'
import { widths } from '../../partials/widget-officer/helpers'
import { isLongform } from 'utils/croissant'

const root = (postType, width) =>
  css(
    {
      position: 'relative'
    },
    isLongform(postType) &&
      width === widths.FULL && {
        marginLeft: 'calc(-50vw + 50%)',
        width: '100vw'
      }
  )
const caption = (postType, width) =>
  css(
    blocks.type.wysiwygSimple,
    {
      backgroundColor: color.base.white,
      paddingTop: layout.sizes.xs,
      width: '100%',
      fontSize: type.sizes.xxs,
      fontWeight: type.weight.bold
    },
    isLongform(postType) &&
      width === widths.FULL && {
        paddingLeft: layout.sizes.sm,
        paddingRight: layout.sizes.sm
      },
    media(layout.mq.smUp, {
      bottom: layout.sizes.sm,
      left: layout.sizes.sm,
      maxWidth: '60%',
      padding: `${rem(6)} ${rem(12)}`,
      position: 'absolute',
      width: 'auto'
    })
  )

const imageDesktop = css(
  {
    display: 'none'
  },
  media(layout.mq.smUp, {
    display: 'block'
  })
)

const imageMobile = css(
  {
    display: 'block'
  },
  media(layout.mq.smUp, {
    display: 'none'
  })
)

export default {
  root,
  caption,
  imageDesktop,
  imageMobile
}
