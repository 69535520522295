import React, { Component } from 'react'
import bugsnagJs from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

import FallbackComponent from './fallback-component'

let ErrorBoundary = null
let bugsnagClient = { notify: () => {} }

// To test bugsnag run add this line to the App:
// bugsnagClient.notify(new Error('Test error'))
if (__BUGSNAG_KEY__) {
  bugsnagClient = bugsnagJs({
    apiKey: __BUGSNAG_KEY__,
    releaseStage: __RELEASE_STAGE__,
    appVersion: __VERSION__
  })
  bugsnagClient.use(bugsnagReact, React)
  ErrorBoundary = bugsnagClient.getPlugin('react')
}

const AppErrorBoundary = ({ children }) => {
  if (!ErrorBoundary) return children
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </ErrorBoundary>
  )
}

export const bugsnag = bugsnagClient

export class ComponentErrorBoundary extends Component {
  state = {
    hasError: false
  }
  componentDidCatch(err, info) {
    bugsnag.notify(err, { react: info })
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) return null
    return this.props.children
  }
}

export default AppErrorBoundary
