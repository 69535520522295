import React from 'react'
import { generate as uid } from 'shortid'

import Image from 'widgets/image'
import Embed from 'widgets/embed'
import itemOrder from './helpers/item-order'
import LoopingVideo from 'widgets/looping-video'

import styles from './style'

const HorizontalListicle = props => {
  const Tag = props.numbered ? 'ol' : 'ul'
  const { items } = itemOrder(props)
  return (
    <Tag className={styles.list}>
      {items.map(item => {
        const index = item.index ? `${item.index}.` : null

        return (
          <li className={styles.listItem} key={uid()}>
            {item.media_type === 'image' && (
              <figure
                style={{ position: 'relative' }}
                className={styles.mediaType}
              >
                <Image
                  className={styles.listicleImage}
                  image={item.image}
                  mobile_image={item.mobile_image}
                  postType={props.postType}
                  crop={item.image_crop}
                  width={item.width}
                />
              </figure>
            )}
            {item.media_type === 'embed' && (
              <Embed
                embed={item.embed}
                mobile_embed={item.mobile_embed}
                caption={item.paragraph}
                postType={props.postType}
                width={item.width}
                className={styles.mediaType}
              />
            )}
            {item.media_type === 'loop' && (
              <LoopingVideo
                video={item.video}
                placeholder={item.placeholder}
                mobile_video={item.mobile_video || false}
                mobile_placeholder={item.mobile_placeholder || false}
                width={item.width}
                postType={props.postType}
                className={styles.mediaType}
              />
            )}
            <div className="text">
              {index && <span className={styles.index}>{index}</span>}
              {item.title && (
                <h3
                  className={styles.title}
                  dangerouslySetInnerHTML={{
                    __html: item.title
                  }}
                />
              )}
              {item.paragraph && (
                <div
                  className={styles.paragraph}
                  dangerouslySetInnerHTML={{
                    __html: item.paragraph
                  }}
                />
              )}
            </div>
          </li>
        )
      })}
    </Tag>
  )
}

export default HorizontalListicle
