import { css } from 'emotion'
import { layout } from 'style/tokens'
import media from 'style/utils/media'

const bannerNavStyle = css({
  display: 'inline-block',
  textAlign: 'center',
  padding: '3px',
  textDecoration: 'none',
  color: 'black'
})

const base = css(
  {
    background: '#FF8088',
    height: layout.sizes.lg,
    margin: '0 auto',
    paddingTop: '2px'
  },
  media(layout.mq.smUp, {
    height: '2rem'
  })
)

const link = theme => css`
  font-family: ${theme.fonts.HurmeGeometricSans2};
`

export default {
  bannerNavStyle,
  base,
  link
}
