import { css } from 'emotion'
import media from 'style/utils/media'
import * as blocks from 'style/blocks'
import { color, layout, type } from 'style/tokens'
import { postTypes } from '../../partials/widget-officer/helpers'

const rootStandard = css(
  {
    margin: `${layout.sizes.md} 0`
  },
  media(layout.mq.mdUp, {
    maxWidth: '80%'
  }),
  media(layout.mq.lgUp, {
    margin: `${layout.sizes.lg} 0 ${layout.sizes.lg} -${layout.sizes.xl}`
  })
)

const blockquoteStandard = css(
  {
    color: color.core.primary,
    fontSize: type.sizes.md
  },
  media(layout.mq.mdUp, {
    fontSize: type.sizes.lg
  })
)

const rootLongform = css({
  padding: `${layout.sizes.md}`,
  position: 'relative',
  '> svg:first-of-type': {
    marginBottom: layout.sizes.md
  },
  '> svg:last-of-type': {
    marginTop: layout.sizes.md
  }
})

const blockquoteLongform = css(
  {
    color: color.tonal[4],
    fontSize: type.sizes.md,
    lineHeight: type.leading.md,
    textAlign: 'center'
  },
  media(layout.mq.lgUp, {
    fontSize: type.sizes.lg
  })
)

const root = ({ postType }) =>
  css(
    postType === postTypes.STANDARD && rootStandard,
    postType === postTypes.LONGFORM && rootLongform
  )

const blockquote = ({ postType }) =>
  css(
    blocks.type.canon,
    postType === postTypes.STANDARD && blockquoteStandard,
    postType === postTypes.LONGFORM && blockquoteLongform,
    {
      lineHeight: type.leading.md
    }
  )

const divider = {
  backgroundColor: color.core.primary,
  width: '33%'
}

export default {
  root,
  blockquote,
  divider
}
