import React from 'react'
import { rem } from 'polished'
import { withTheme } from 'emotion-theming'
import Anchor from 'partials/anchor'
import { Text, Box } from 'design-system'
import siteData from 'config/data.json'
import { trackEvent } from 'utils/analytics-trackers'
import { camalize } from 'utils/camalize'

import styles from './style'

const SWTCBannerMenu = ({ theme }) => {
  const trackCategoryClick = (e, item) => {
    e.preventDefault()
    trackEvent(
      `Clicked on SWTC Site Navigation Banner category: ${item.text}`,
      'SWTC Site Navigation Banner',
      item.text
    )
    window.location.href = item.url
  }

  return (
    <Box
      alignItems="center"
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="space-evenly"
      className={styles.base}
      data-cy="swtcNav"
    >
      {siteData.stongWomenTrainingClubBannerMenu.channels.map(item => {
        return (
          <Anchor
            url={item.url}
            key={item.text}
            className={styles.bannerNavStyle}
            role="button"
            tabIndex={0}
            onClick={e => trackCategoryClick(e, item)}
            data-cy={camalize(`swtc nav option ${item.text}`)}
          >
            <Text
              is="span"
              fontSize={rem(14)}
              fontWeight="600"
              letterSpacing="sm"
              lineHeight="md"
              textAlign="center"
              textTransform="uppercase"
              width="100%"
              p={'8px'}
              className={styles.link(theme)}
            >
              {item.text}
            </Text>
          </Anchor>
        )
      })}
    </Box>
  )
}

export default withTheme(SWTCBannerMenu)
