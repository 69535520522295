import React from 'react'
import Divider from 'widgets/divider'
import PropTypes from 'prop-types'
import styles from './style'

const postTypes = ['longform', 'sponsored_longform']

const PullQuote = props =>
  props.text ? (
    <aside className={styles.root(props)}>
      {postTypes.includes(props.postType) && (
        <Divider customStyle={styles.divider} />
      )}
      <blockquote
        className={styles.blockquote(props)}
        dangerouslySetInnerHTML={{ __html: props.text }}
      />
      {postTypes.includes(props.postType) && (
        <Divider customStyle={styles.divider} />
      )}
    </aside>
  ) : null

PullQuote.defaultProps = {
  postType: 'standard'
}
PullQuote.propTypes = {
  position: PropTypes.string,
  postType: PropTypes.string,
  text: PropTypes.string,
  width: PropTypes.string
}

export default PullQuote
