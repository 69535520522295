import { css } from 'emotion'
import media from 'style/utils/media'
import * as blocks from 'style/blocks'
import { layout, type } from 'style/tokens'

const header = css(
  {
    marginBottom: layout.vSpace.sm,
    marginTop: layout.vSpace.sm
  },
  media(layout.mq.mdUp, {
    marginBottom: layout.vSpace.md,
    marginTop: layout.vSpace.lg
  })
)
const title = css(
  blocks.type.canon,
  {
    fontSize: type.sizes.lg
  },
  media(layout.mq.smUp, {
    fontSize: type.sizes.xl
  }),
  media(layout.mq.mdUp, {
    fontSize: type.sizes.xxl
  })
)

export default {
  header,
  title
}
