import React from 'react'
import { withTheme } from 'emotion-theming'
import Countdown from 'partials/countdown'
import { AnchorButton } from 'partials/buttons'

import style from './style'

const CountdownTimer = ({
  background_colour,
  countdown_header,
  countdown_to_date,
  button_link,
  button_text,
  theme
}) => {
  return (
    <section className={style.countdownWrapper(background_colour)}>
      <h2>{countdown_header}</h2>
      <Countdown
        className={style.countdown}
        formattedDateTime={countdown_to_date}
      />
      <AnchorButton
        url={button_link}
        text={button_text}
        theme={theme}
        customStyle={`
          border-color: white;
          color: ${background_colour};
        `}
      />
    </section>
  )
}

export default withTheme(CountdownTimer)
