import { useState, useEffect, useRef } from 'react'
import { isSupported } from 'utils/feature-detection'
import { throttle } from 'throttle-debounce'

const useIsOnScreen = (threshold = 0.5) => {
  const [isVisible, setVisible] = useState(false)
  const arrRefs = useRef([])

  const addElementsToRef = ele => {
    if (ele && !arrRefs.current.includes(ele)) arrRefs.current.push(ele)
  }

  arrRefs.current = []

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.map(entry => {
          entry.isIntersecting ? setVisible(true) : setVisible(false)
        })
      },
      {
        threshold
      }
    )

    if (arrRefs.current) {
      arrRefs.current.forEach(element => {
        if (isSupported('intersection')) return observer.observe(element)
        return window.addEventListener(
          'scroll',
          throttle(100, () => {
            const { top, bottom } = element.getBoundingClientRect()
            top < window.innerHeight / 2 && setVisible(true)
            bottom < window.innerHeight / 2 && setVisible(false)
          }),
          false
        )
      })
    }
  }, [])
  return { addElementsToRef, isVisible }
}

export default useIsOnScreen
