/* eslint-disable max-len */
import React from 'react'
import IconBase from 'assets/icon-base'

const IconTwitter = props => (
  <IconBase {...props}>
    <path d="M66.8 173.7c68 0 105.3-56.5 105.3-105.4v-4.8c7.3-5.2 13.5-11.7 18.5-19.2-6.8 3-14 5-21.3 6 7.8-4.8 13.5-12 16.3-20.6-7.3 4.3-15.2 7.3-23.5 9-11.5-12.2-29.7-15.2-44.5-7.3-14.8 8-22.4 24.7-18.6 41C69 71 41.3 57 22.5 33.8c-10 17-4.8 38.5 11.4 49.4-6-.2-11.6-1.8-16.8-4.6v.4c0 17.7 12.5 32.8 29.7 36.3-5.5 1.5-11.2 1.7-16.8.7 5 15 18.8 25.4 34.6 25.7-13 10.3-29.3 16-46 16-3 0-6-.3-8.8-.7 17 11 36.6 16.7 56.8 16.6" />
  </IconBase>
)

export default IconTwitter
