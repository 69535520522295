import { css } from 'emotion'
import { layout } from 'style/tokens'

const root = css({
  textAlign: 'center',
  marginBottom: layout.sizes.md
})

export default {
  root
}
