import { css } from 'emotion'
import { type, layout } from 'style/tokens'

const root = css({
  color: 'currentColor',
  display: 'inline-block',
  fontFamily: type.stack.tertiary,
  fontSize: type.sizes.xxxs,
  fontStyle: 'italic',
  fontWeight: type.weight.semibold,
  lineHeight: type.leading.sm,
  marginTop: layout.sizes.sm,
  textTransform: 'none',
  whiteSpace: 'pre'
})
const definition = css({
  float: 'left'
})
const term = css(definition, {
  clear: 'both',
  '::after': { content: '"\u0020"' }
})

export default {
  root,
  term,
  definition
}
