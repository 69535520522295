export const EXCLUDED_URLS = [
  '/emails',
  '/people/an-apology-stylist-august-2019/401337',
  '/people/an-apology-stylist-august-2019/401337/preview',
  '/login',
  '/register',
  '/forgotten-password',
  '/reset-password',
  '/new-account-setup'
]
