import React from 'react'
import PropTypes from 'prop-types'
import IframelyLoader from '../iframely-loader'
import { layout } from 'style/tokens'
import { orientations } from '../../widget-officer/helpers'

export const EmbedContainer = ({
  embed,
  target,
  orientation,
  mobileViewExists
}) => {
  return (
    <div
      css={`
        word-wrap: break-word;
        display: block;
        @media${layout.mq.smUp} {
          ${orientation === orientations.PORTRAIT &&
            `
            display: none;
          `} 
        }
        @media${layout.mq.smOnly} {
          ${orientation === orientations.LANDSCAPE &&
            mobileViewExists &&
            `
            display: none;
          `} 
        }
      `}
      ref={target}
      dangerouslySetInnerHTML={{
        __html: embed
      }}
    />
  )
}

EmbedContainer.propTypes = {
  embed: PropTypes.string,
  orientation: PropTypes.string
}

export default props => {
  return (
    <IframelyLoader embed={props.embed}>
      {({ target, embed }) => {
        return <EmbedContainer {...props} target={target} embed={embed} />
      }}
    </IframelyLoader>
  )
}
