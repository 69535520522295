import { css } from 'emotion'
import media from 'style/utils/media'
import * as blocks from 'style/blocks'
import { color, type, layout } from 'style/tokens'
import rem from 'style/utils/rem'
import { widths } from 'partials/widget-officer/helpers'
import { isLongform } from 'utils/croissant'

const title = css(
  {
    display: 'inline-block',
    fontFamily: type.stack.tertiary,
    fontSize: '25px',
    fontWeight: type.weight.bold,
    marginBottom: '24px',
    textTransform: 'uppercase'
  },
  media(layout.mq.smOnly, {
    fontSize: '20px'
  })
)

const index = css(title, {
  marginRight: layout.sizes.xxs
})

const list = css({
  listStyleType: 'none',
  padding: '0 16px'
})

const listItem = css(
  {
    marginBottom: layout.sizes.xl,
    display: 'grid'
  },
  media(layout.mq.smUp, {
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '24px'
  }),
  media(layout.mq.mdUp, {
    gridColumnGap: '30px'
  }),
  media(layout.mq.smOnly, {
    gridRowGap: '30px',
    marginBottom: '0px'
  })
)

const paragraph = css(
  blocks.type.wysiwyg,
  {},
  {
    h3: {
      fontSize: '19px',
      margin: '0 0 24px'
    }
  },
  media(layout.mq.smOnly, {
    h3: {
      fontSize: '16px'
    }
  })
)

const listicleImage = css(
  media(layout.mq.smOnly, {
    marginRight: `-${layout.sizes.sm}`,
    marginLeft: `-${layout.sizes.sm}`
  })
)
const caption = (postType, width) =>
  css(
    blocks.type.wysiwygSimple,
    {
      backgroundColor: color.base.white,
      paddingTop: layout.sizes.xs,
      width: '100%',
      fontSize: type.sizes.xxs,
      fontWeight: type.weight.bold
    },
    isLongform(postType) &&
      width === widths.FULL && {
        paddingLeft: layout.sizes.sm,
        paddingRight: layout.sizes.sm
      },
    media(layout.mq.smUp, {
      bottom: layout.sizes.sm,
      left: layout.sizes.sm,
      maxWidth: '60%',
      padding: `${rem(6)} ${rem(12)}`,
      position: 'absolute',
      width: 'auto'
    })
  )

const button = css({
  width: '100%',
  textAlign: 'center'
})

export default {
  title,
  index,
  list,
  listItem,
  paragraph,
  listicleImage,
  caption,
  button
}
