import React, { Component } from 'react'
import loadJS from 'utils/load-js'

const AssetsContext = React.createContext()

export class AssetsProvider extends Component {
  state = {}

  loadAsset = (src, id) => {
    if (!id || this.state[id]) return
    loadJS({ src, id })
      .then(() => this.setState({ [id]: true }))
      .catch(() => this.setState({ [id]: false }))
  }

  render() {
    return (
      <AssetsContext.Provider
        value={{ assets: this.state, loadAsset: this.loadAsset }}
      >
        {this.props.children}
      </AssetsContext.Provider>
    )
  }
}

export default AssetsContext
