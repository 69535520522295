import React from 'react'

import Anchor from 'partials/anchor'
import Image from 'partials/image'
import Category from 'partials/category'

import IconPlay from 'assets/icon-play'

import { layout } from 'style/tokens'

import { hasEntries } from 'utils/array'

import styles from './style'

const CardMini = props => {
  const category = props.acf?.category
  const heroImage = props.acf?.hero_images?.[0]
  const shortHeadline = props.acf?.short_headline
  const categoryParent = props.acf?.category?.parent
  const seriesBadge = props.acf?.series?.series_badge?.url
  const onExtraHubPage = props?.onExtraHubPage
  const isPaid =
    onExtraHubPage !== undefined ? false : hasEntries(props.acf?.package_ids)
  const isVideoPost = !!props.acf?.video_url

  return (
    <article className={styles.root}>
      <Anchor
        className={styles.blockLink(onExtraHubPage)}
        url={props.link}
        aria-label={shortHeadline}
      />
      <div className={styles.image(onExtraHubPage)}>
        {heroImage && (
          <Image
            image={heroImage}
            badge={seriesBadge}
            crop="landscape"
            sizes={`50vw, ${layout.mq.smUp} 33vw, ${layout.mq.lgUp} 176px`}
            imageSizes={[260, 300, 176]}
            isPaid={isPaid}
            preload
          />
        )}
        {onExtraHubPage &&
          isVideoPost && (
            <div className={styles.playButton}>
              <IconPlay />
            </div>
          )}
      </div>
      <div className={styles.content}>
        {category && (
          <Category
            variant="tertiary"
            name={category.name}
            slug={category.slug}
            parent={categoryParent}
            customStyle={styles.category}
          />
        )}
        {shortHeadline && (
          <h3
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: shortHeadline
            }}
          />
        )}
      </div>
    </article>
  )
}

export default CardMini
