import { css } from 'emotion'
import { rem } from 'polished'

const toggleContainer = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  background: '#FF8088',
  margin: '0 auto',
  position: 'relative'
})

const toggle = css({
  backgroundColor: 'transparent',
  border: 0,
  flex: '0 0 auto',
  height: rem(40),
  width: rem(40)
})

const link = theme =>
  css({
    fontSize: '16px',
    fontFamily: theme.fonts.HurmeGeometricSans3,
    fontWeight: '600',
    textDecoration: 'none',
    cursor: 'pointer',
    color: '#000',
    textAlign: 'center',
    textTransform: 'uppercase',
    lineHeight: '40px'
  })

export default {
  toggleContainer,
  toggle,
  link
}
