const baseFontSize = 16

/**
 * Convert px to rem/em, and round to 3 decimal places (at most)
 */
export default (px = baseFontSize, em = false) => {
  // Remove type checking in production mode
  if (NODE_ENV !== 'production') {
    // Check that the value can be coerced to a number
    if (isNaN(px) || px === null) {
      throw new TypeError(`'${px}' is not a number`)
    }
  }
  // Return '0' if argument is less than 1 but greater than 0
  if (px < 1 && px > 0) {
    return '0'
  }
  // Raw converted value
  const rawResult = parseInt(px, 10) / baseFontSize
  // Round to 3 decimal places at most
  const result = Math.round(rawResult * 1000) / 1000
  // Return result as string with unit
  return `${result}${em ? 'em' : 'rem'}`
}
