import { css } from 'emotion'
import { layout, type } from 'style/tokens'

const H2 = css`
  h2 {
    font-size: 32px;
    line-height: 35.2px;
    letter-spacing: 3.8px;
    font-weight: ${type.weight.bold};
    text-align: center;
    text-transform: uppercase;
    margin: 0 !important;
  }
`
const Button = css`
  a {
    width: max-content;
    padding: 16px 24px;
    font-weight: normal;
    font-size: 13px;
    border-color: white;
    letter-spacing: 1.5px;
    line-height: 14.3px;
    background: white;
    &:hover {
      color: white;
    }
  }
`

const countdownWrapper = background => css`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  min-height: 348px;
  background: ${background};
  color: white;
  ${H2} ${Button}
  * {
    max-width: 604px;
    width: 100%;
    margin: 0 !important;
  }
  @media ${layout.mq.smOnly} {
    width: 100vw;
  }
`
const countdown = css`
  text-align: center;
  font-weight: bold;
  div {
    margin: 0;
    width: 100%;
    p {
      font-size: 3.3vw;
      line-height: 64px;
      letter-spacing: 1.2px;
      @media ${layout.mq.smOnly} {
        font-size: 32px;
      }
    }
    p:last-child {
      text-transform: uppercase;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: normal;
      @media ${layout.mq.smOnly} {
        font-size: 16px;
      }
    }
  }
`

export default {
  countdownWrapper,
  countdown
}
