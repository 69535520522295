/* eslint-disable max-len */
import React from 'react'

const IconCaret = props => (
  <svg
    color="transparent"
    xmlns="http://www.w3.org/2000/svg"
    width="12.772"
    height="7.093"
    viewBox="0 0 12.772 7.093"
    {...props}
  >
    <path
      className="a"
      d="M-11318.1-6825.249l5.9-5,5.553,5"
      transform="translate(11318.75 6831.579)"
      fill="none"
      stroke="#fff"
      strokeWidth="2px"
    />
  </svg>
)

export default IconCaret
