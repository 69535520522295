import { css } from 'emotion'
import { type, layout, color } from 'style/tokens'

const root = css({
  fontFamily: type.stack.tertiary
})

const title = isSinglePost =>
  css(
    {
      fontSize: type.sizes.xs,
      fontWeight: type.weight.bold,
      letterSpacing: type.tracking.md,
      marginBottom: layout.sizes.md,
      textTransform: 'uppercase'
    },
    isSinglePost && {
      color: color.core.primary
    }
  )

export default {
  root,
  title
}
