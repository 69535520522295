const intersection = () =>
  'IntersectionObserver' in window &&
  'isIntersecting' in window.IntersectionObserverEntry.prototype

export const isSupported = type => {
  // can only check on the client
  if (typeof window === 'undefined') return false
  // link up the test type with the test
  if (type === 'intersection') return intersection()
  // gotta catch it if their aint a type
  return false
}
