import { css } from 'emotion'
import { color } from 'style/tokens'
import { layout } from 'style/blocks'

/**
 * Hide an element 'visually', but remain visible to assistive technologies
 * such as screen-readers
 */
const hidden = css({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  width: '1px'
})

/**
 * Create a transparent pseudo element overlay. Assumes 'relative' positioning
 * on the parent element. This uses the 'fill' block to fill the parent.
 * Different types of 'transparency' tokens can be specified. You can also
 * supply custom styles to add to the base defaults
 */
const transparentOverlay = (
  element = 'before',
  styles = null,
  type = 'black'
) =>
  css({
    [`::${element}`]: css(
      layout.fill,
      {
        backgroundColor: color.transparency[type],
        content: '""'
      },
      styles
    )
  })

const absoluteAnchor = css({
  // To get around IE10 & IE11 absolutely positioned links
  // https://stackoverflow.com/questions/6914822/absolute-positioned-anchor-tag-with-no-text-not-clickable-in-ie#answer-10557354
  backgroundColor: 'rgba(0, 0, 0, 0)'
})

export default {
  hidden,
  transparentOverlay,
  absoluteAnchor
}
