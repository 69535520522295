const getMyStylistConfig = () => {
  if (typeof window === 'undefined') return {}

  if (!window.__MY_STYLIST_CONFIG__) return {}

  return {
    ...window.__MY_STYLIST_CONFIG__.data
  }
}

export default getMyStylistConfig
