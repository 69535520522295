import iconBase from 'style/utils/icon-base'

/**
 * Simple version of the brand logo
 * Mainly used for placeholder media
 */
const logomark = color =>
  iconBase(`
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143 197">
    <path fill="${color}" fill-rule="evenodd" d="M30.5 175.1c8.82 8.6 22.17 13.68 40.1 13.67 14.66 0 26.48-5.06 34.6-13.17 8.1-8.1 12.5-19.23 12.5-31.2.05-22.1-9.8-32.8-29.77-51.9C72 77.2 55.33 63 55.36 39.24 55.4 16.65 72.46.05 96.9 0c15.68.04 23.24 5.56 30.88 12.27l1.3 1.15 7.48-11.55h3.37L143 47.6l-2.93.5C132.34 32.8 125.8 22.67 119 16.23c-6.93-6.6-14.26-9.2-22.8-9.16-7.98 0-14.1 2.6-18.13 6.9-4.04 4.33-5.93 10.24-5.93 16.62-.02 17.76 9.4 26.97 26.54 42.57 17.76 16.2 39.34 35.12 39.3 62.82-.08 29.2-22.7 60.96-72.02 61.03C20.72 196.92.06 170.9 0 146.04c.05-25.67 18.53-42 41.77-42.06 14.65.1 24.54 7.72 24.56 19.35-.02 7.1-5.84 12.3-12 12.3-5.7 0-10.82-4.77-10.83-10.66.08-4.8 2.65-10 10.8-11.16l1.3-.17v-2.88l-1.05-.34c-4.5-1.43-6.94-1.7-11.62-1.7-7.3 0-13.77 3.22-18.3 8.9-4.52 5.7-7.15 13.77-7.15 23.52 0 13.42 4.18 25.4 13.02 34z"/>
  </svg>
`)

export default {
  logomark
}
