const duration = {
  instant: '75ms',
  quick: '125ms',
  initial: '200ms',
  long: '350ms',
  longest: '800ms'
}

const easing = {
  initial: 'ease-in-out'
}

export default {
  duration,
  easing
}
