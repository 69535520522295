import React from 'react'
import { keyframes } from 'emotion'
import { rem } from 'polished'
import PropTypes from 'prop-types'

import { Box } from 'design-system'

const animation = keyframes`
  0% { opacity: 1 }
  35% { opacity: 0 }
  70% { opacity: 1 }
`

const Circle = ({ color, delay, offset, size }) => (
  <Box
    bg={color}
    borderRadius="50%"
    height={rem(size)}
    left={rem(offset)}
    position="absolute"
    top={0}
    width={rem(size)}
    css={`
      animation: ${animation} 900ms infinite cubic-bezier(0.63, 0.05, 0.43, 1.7)
        ${delay}ms;
    `}
  />
)

const IconLoading = ({ count, delay, size, color, ...rest }) => (
  <Box
    height={size}
    m="auto"
    position="relative"
    width={size * 2 * (count - 1) + size}
    {...rest}
  >
    {Array(count)
      .fill('')
      .map((_, i) => (
        <Circle
          color={color}
          delay={i * delay}
          key={i}
          offset={i * (size * 2)}
          size={size}
        />
      ))}
  </Box>
)

IconLoading.defaultProps = {
  color: 'black',
  count: 3,
  delay: 150,
  size: 6
}

IconLoading.propTypes = {
  color: PropTypes.string,
  count: PropTypes.number,
  delay: PropTypes.number,
  size: PropTypes.number
}

export default IconLoading
