import React from 'react'
import { withTheme } from 'emotion-theming'

import style from './style'

const VerticalGradient = ({ children, showGradient = false, theme }) => {
  return <div className={style.base(theme, showGradient)}>{children}</div>
}

export default withTheme(VerticalGradient)
