import React, { useRef, useState } from 'react'
import { stringify } from 'query-str-es5'
import { withTheme } from 'emotion-theming'

import { trackEvent } from 'utils/analytics-trackers'
import triggerHotjarEvent from 'utils/hotjar-tracker'

import IconSearch from 'assets/icon-search'

import { display } from 'style/blocks'

import style from './style'

const InlineSiteSearch = ({ theme }) => {
  const [value, setValue] = useState('')
  const searchInput = useRef(null)

  const handleSubmit = e => {
    // always prevent form submission, we'll handle this ourselves
    e.preventDefault()
    // prevent submission if input is empty
    if (!value.length) return searchInput.focus()
    trackEvent(
      'Pressed Enter On Search',
      'Site Search Navigation',
      'Site search'
    )
    // otherwise go to search page with query
    window.location = stringify({ q: value.trim() }, '/search')
  }

  const handleChange = e => setValue(e.currentTarget.value)

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      className={style.form(theme)}
    >
      <label htmlFor="search-input" className={style.icon}>
        <span className={display.hidden}>{'Search'}</span>
        <IconSearch />
      </label>
      <input
        id="search-input"
        onClick={() => triggerHotjarEvent('use_site_search')}
        onChange={handleChange}
        ref={searchInput}
        type="search"
        value={value}
        placeholder="Search stylist.co.uk"
        className={style.inputField}
      />
      <button className={style.submitButton(theme)} type="submit">
        {'Go'}
      </button>
    </form>
  )
}

export default withTheme(InlineSiteSearch)
