/**
 * Utility emotion wrapper for combined :hover & :focus styles
 */
const interact = styles => ({
  ':hover,:focus': styles
})

export default {
  interact
}
