import { css } from 'emotion'
import * as blocks from 'style/blocks'
import { anim, color, type } from 'style/tokens'
import rem from 'style/utils/rem'
import channelColor from 'style/utils/channel-color'

const variants = {
  primary: css({
    color: color.base.black,
    fontSize: type.sizes.xxs,
    padding: `0 ${rem(20)}`,
    position: 'relative',
    '& span': {
      position: 'relative',
      zIndex: 1
    },
    '::after': {
      content: '""',
      height: rem(6),
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: rem(1, true)
    }
  }),
  secondary: css({
    backgroundColor: color.base.white,
    padding: `${rem(6)} ${rem(14)}`,
    transitionProperty: 'color'
  }),
  tertiary: css({
    transitionProperty: 'color'
  }),
  quarternary: css(
    {
      color: color.core.primary,
      transitionProperty: 'color',
      fontSize: rem(14)
    },
    {
      '::after': {
        content: '"/"',
        marginLeft: rem(8, true)
      }
    }
  )
}

const colour = ({ variant, slug }) => {
  if (variant === 'primary') {
    return css({
      '::after': {
        backgroundColor: channelColor(slug)
      },
      ':hover::after, :focus::after': {
        backgroundColor: channelColor(slug, 'dark')
      }
    })
  }
  if (variant === 'secondary' || variant === 'tertiary') {
    return css({
      color: channelColor(slug),
      ':hover, :focus': {
        color: channelColor(slug, 'dark')
      }
    })
  }
  if (variant === 'quarternary') {
    return css({
      color: channelColor(slug),
      ':hover,:focus': {
        color: channelColor(slug, 'dark')
      },
      '::after': {
        color: channelColor(slug)
      }
    })
  }
}

const root = ({ variant }) =>
  css(
    blocks.type.brevier,
    {
      fontWeight: type.weight.black,
      display: 'inline-block',
      textDecoration: 'none',
      transitionDuration: anim.duration.instant,
      transitionTimingFunction: anim.easing.initial,
      whiteSpace: 'nowrap'
    },
    variants[variant]
  )

const suffix = css({
  display: 'inline-block',
  marginLeft: rem(5),
  textTransform: 'none'
})

export default {
  root,
  suffix,
  colour
}
