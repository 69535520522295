import React from 'react'
import isPlainObject from 'lodash.isplainobject'

import { BackgroundImage } from 'partials/image'
import Anchor from 'partials/anchor'
import styles from './style'

const SinglePost = ({ post }) => (
  <div className={styles.root}>
    <Anchor url={post.link} className={styles.blockLink} />
    {isPlainObject(post.acf.hero_images[0]) && (
      <BackgroundImage
        className={`${styles.bgrImage} ${styles.bgImageOverlay}`}
        crop="landscape"
        image={post.acf.hero_images[0]}
        options={{ w: 240 }}
      />
    )}
    <div className={styles.content}>
      <h3 className={styles.singleTitle}>{post.title.rendered}</h3>
    </div>
  </div>
)

export default SinglePost
