import React from 'react'
import Helmet from 'react-helmet'

import { Wrapper, Grid, Col } from 'partials/grid'
import Base from 'partials/_base'
import WidgetOfficer from 'partials/widget-officer'

import styles from './style'

const Page = props => (
  <Base {...props}>
    <Helmet>
      <title>{props.acf.short_headline}</title>
      <meta name="description" content={props.acf.sell} />
      <meta
        content={props.acf.short_headline}
        name="twitter:title"
        property="og:title"
      />
      <meta
        content={props.acf.sell}
        name="twitter:description"
        property="og:description"
      />
    </Helmet>
    <Wrapper>
      <Grid>
        <Col md={2 / 3} offsetMd={1 / 6}>
          <header className={styles.header}>
            <h1
              className={styles.title}
              dangerouslySetInnerHTML={{
                __html: props.title.rendered
              }}
            />
          </header>
          <WidgetOfficer widgets={props.acf.widgets} />
        </Col>
      </Grid>
    </Wrapper>
  </Base>
)

export default Page
