import React from 'react'

const IconUp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.025"
    height="9.427"
    viewBox="0 0 16.025 9.427"
    color="transparent"
  >
    <path
      id="Path_66"
      data-name="Path 66"
      d="M-14319,7426l7.307,7.306,7.3-7.306"
      transform="translate(-14303.68 7434.72) rotate(180)"
      fill="none"
      stroke="#3dd2be"
      strokeWidth="2"
    />
  </svg>
)

export default IconUp
