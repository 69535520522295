import { css } from 'emotion'
import rem from 'style/utils/rem'
import { layout } from 'style/tokens'

const wrapper = css`
  width: 100%;
  padding: 0px 16px;
  h3 {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  p {
    &:nth-of-type(2n) {
      margin-top: 8px;
    }
    &:nth-of-type(3n) {
      margin: 16px 0 24px;
    }
    @media${layout.mq.smUp} {
      font-size: ${rem(19)};
    }
  }
  a {
    color: currentColor;
    &:hover, &:focus {
      color: currentColor;
    }
  }
`

export default {
  wrapper
}
