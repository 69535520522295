import { css } from 'emotion'
import media from 'style/utils/media'
import { color, layout, type } from 'style/tokens'
import * as blocks from 'style/blocks'
import fontScale from 'style/utils/font-scale'
import rem from 'style/utils/rem'

const root = css({
  paddingBottom: rem(120),
  paddingTop: layout.sizes.xxl,
  textAlign: 'center'
})

const statusCode = css(blocks.type.canon, fontScale(120, 240), {
  color: color.core.primary,
  display: 'block',
  marginBottom: layout.sizes.lg
})

const sell = css(
  blocks.type.pica,
  media(layout.mq.smOnly, {
    fontSize: type.sizes.xs
  })
)

export default {
  root,
  statusCode,
  sell
}
