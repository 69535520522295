import React, { useState, useRef, useEffect } from 'react'
import Helmet from 'react-helmet'
import { throttle } from 'throttle-debounce'

import useMediaQuery from 'hooks/use-media-query'

import { withTheme } from 'emotion-theming'

import UserNavigation from 'partials/user-navigation'
import SiteSearch from 'partials/site-search'
import InlineSiteSearch from 'partials/inline-site-search'
import Image from 'partials/image'
import Card from 'partials/trending-posts/card'
import CardMini from 'partials/bear-paw/card-mini'
import { AnchorButton } from 'partials/buttons'
import { SiteAdvertHeader } from 'partials/_base/site-advert'
import SWTCBannerMenu from 'partials/swtc-banner-menu'
import SWTCDropDownMenu from 'partials/swtc-dropdown-menu'

import LogoStylist from 'assets/logo-stylist'
import IconDown from 'assets/icon-down'
import IconClose from 'assets/icon-close'
import IconCaret from 'assets/icon-caret'

import isSubscribed from '_utility/is-subscribed'
import getMyStylistConfig from '_utility/get-mystylist-config'

import { getDateFormat } from 'utils/date'
import { isEmpty } from 'utils/object'

import RestrictBySize from 'higher-order/restrict-by-size'

import { features } from 'root/config'

import { EXCLUDED_URLS } from './helpers'

import { layout } from 'style/tokens'
import * as styles from './style'
import {
  Divider,
  CloseButton,
  VipButton,
  AllIssuesButton,
  DropDownButton,
  H3,
  Ad,
  H1
} from './components'

const SiteNavigation = ({
  showSwtcNav = false,
  shouldShowNav = true,
  hideHeader = false,
  theme,
  ...props
}) => {
  const [nav, setNav] = useState([])
  const [showNav, setShowNav] = useState(true)
  const [showSiteSearch, setShowSiteSearch] = useState(undefined)
  const [active, setActive] = useState([0])
  const [openDropDown, setOpenDropDown] = useState({
    state: false,
    items: {},
    index: null,
    label: null
  })

  const swtcSubscriber = isSubscribed([__SWTC_MONTHLY_ID__, __SWTC_YEARLY_ID__])
  const showSWTCAD = label => !swtcSubscriber && label.includes('Club')
  const showSwtcNavBar = shouldShowSwtcNav(props, showSwtcNav)

  const stylistExtraSubscriber = isSubscribed([
    __STYLIST_EXTRA_MONTHLY_ID__,
    __STYLIST_EXTRA_ANNUAL_ID__,
    __STYLIST_EXTRA_VIP_ID__
  ])

  const shouldShowCaretIcon = sectionType => {
    const defaultState =
      sectionType !== 'Articles' &&
      sectionType !== 'Workouts' &&
      sectionType !== 'Issue'

    if (!swtcSubscriber) return defaultState && sectionType !== 'Club'

    return defaultState
  }

  const isSM = useMediaQuery(layout.mq.mdDown)
  const searchRef = useRef()

  const { navigation } = getMyStylistConfig()

  const getComponent = (type, item, i) =>
    ({
      video: isSubscribed([__SWTC_MONTHLY_ID__, __SWTC_YEARLY_ID__]) && (
        <Videos {...item} />
      ),
      issue: <Issue {...item} isSM={isSM} />,
      list: <List {...item} isActive={isSM ? active.includes(i) : true} />,
      article: (
        <Articles
          {...(isSM ? { post_list: item.post_list?.slice(0, 3) } : item)}
        />
      )
    }[type])

  const toggleDropDown = (items, index, e, label) => {
    e.preventDefault()

    searchRef?.current?.handleCloseDialog(e)

    if (index === openDropDown.index) return close()

    setOpenDropDown({
      state: !isEmpty(items) ? true : !openDropDown.state,
      items,
      index,
      label
    })
  }

  const toggleAccordion = i => {
    if (active.includes(i)) {
      let arr = active
      arr = arr.filter(index => index !== i)
      return setActive([...arr])
    } else {
      return setActive([...active, i])
    }
  }

  const close = () =>
    setOpenDropDown({
      state: false,
      items: {},
      index: null,
      label: null
    })

  const clickedOff = {
    onClick: e => e.target === e.currentTarget && close(),
    onKeyPress: e => e.target === e.currentTarget && close(),
    role: 'presentation'
  }

  const formattedNav = () => {
    let newNav = navigation?.filter(
      item => item.label !== 'Magazine' && item.label !== 'Stylist Extra'
    )

    const magazine = navigation?.find(nav => nav.label === 'Magazine')
    const stylistExtra = navigation?.find(nav => nav.label === 'Stylist Extra')

    return (newNav = [
      ...newNav,
      {
        ...stylistExtra,
        second_level: [
          ...(stylistExtra?.second_level || []),
          {
            ...magazine?.second_level[0],
            label: "This Month's Issue"
          }
        ]
      }
    ])
  }

  useEffect(() => {
    const newNav = formattedNav()
    setNav(newNav)
  }, [])

  useEffect(
    () => {
      if (!shouldShowNav) return setShowNav(false)
      setShowNav(!shouldShow())
      isSM ? setShowSiteSearch(false) : setShowSiteSearch(true)
    },
    [isSM]
  )

  useEffect(() => {
    const handleScroll = () => {
      const pageScroll = document.body.getBoundingClientRect()
      pageScroll.top < -1000 && close()
    }
    const throttleScroll = throttle(50, () => handleScroll())

    window.addEventListener('scroll', throttleScroll)
    return () => window.removeEventListener('scroll', throttleScroll)
  })

  useEffect(
    () => {
      if (isSM && openDropDown.state) {
        document.documentElement.style.overflow = 'hidden'
      } else {
        document.documentElement.style.overflow = 'scroll'
      }
    },
    [openDropDown.state]
  )

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <SiteAdvertHeader />
      <nav
        className={styles.container(openDropDown.state, hideHeader)}
        {...clickedOff}
      >
        <LogoStylist
          aria-label="Return to Stylist Home"
          className="stylistLogo"
          onClick={() => (window.location.href = '/')}
        />
        <ul className={styles.dropDownOptions}>
          {nav?.map(({ second_level, label }, i) => {
            return (
              <li key={i}>
                <DropDownButton
                  onClick={e => toggleDropDown(second_level, i, e, label)}
                  onMouseEnter={e => toggleDropDown(second_level, i, e, label)}
                  onFocus={e => toggleDropDown(second_level, i, e, label)}
                  state={nav[i] === nav[openDropDown.index]}
                >
                  {label}
                  {!isSM && <IconDown />}
                </DropDownButton>
              </li>
            )
          })}
        </ul>
        <Divider />
        <ul className={styles.navLinks}>
          {showSiteSearch && (
            <SiteSearch
              ref={searchRef}
              onClick={() => close()}
              swtcNavBar={showSwtcNavBar}
            />
          )}
          <UserNavigation theme={theme} />
          <VipButton href="/subscription/packages/641586">Subscribe</VipButton>
        </ul>
        {openDropDown.state &&
          (openDropDown.label === 'Stylist Extra' && !stylistExtraSubscriber ? (
            <StylistExtraCTA isSM={isSM} close={close} />
          ) : (
            <div
              className={styles.dropDownMenu({
                isStylistExtraTab: openDropDown.label === 'Stylist Extra'
              })}
              {...clickedOff}
            >
              {openDropDown.items
                .filter(item => {
                  if (!swtcSubscriber && item.label.includes('Workouts'))
                    return null

                  return item
                })
                .map(({ label, third_level }, i) => {
                  const sectionType = label.split(' ').splice(-1)[0]
                  const activeList = active.includes(i)

                  return (
                    <ul
                      className={styles.menuSection(sectionType, activeList)}
                      key={i}
                    >
                      <h2>{label}</h2>
                      {isSM &&
                        shouldShowCaretIcon(sectionType) && (
                          <IconCaret onClick={() => toggleAccordion(i)} />
                        )}
                      {showSWTCAD(label) ? (
                        <Ad
                          href={
                            '/training-club/sales?utm_medium=stylist.co.uk&utm_source=nav'
                          }
                          aria-label="Sign up to Strong Women Training Club "
                        />
                      ) : (
                        third_level.map(({ type, ...item }) =>
                          getComponent(type, item, i)
                        )
                      )}
                    </ul>
                  )
                })}
              {openDropDown.label === 'Stylist Extra' &&
                stylistExtraSubscriber && (
                  <AnchorButton
                    customStyle={styles.stylistExtraButton}
                    text="View all of stylist extra"
                    url="/stylist-extra"
                  />
                )}
              {isSM &&
                nav[openDropDown.index]?.label === 'Discover' && (
                  <InlineSiteSearch />
                )}
              <CloseButton onClick={() => close()}>
                {isSM ? 'Close' : <IconClose />}
              </CloseButton>
            </div>
          ))}
        {showNav &&
          showSwtcNavBar && (
            <div className={styles.swtcNav(openDropDown.state)}>
              <RestrictBySize minHeight={640} minWidth={800} method="and">
                <SWTCBannerMenu />
              </RestrictBySize>
              <RestrictBySize maxHeight={639} maxWidth={799} method="or">
                <SWTCDropDownMenu />
              </RestrictBySize>
            </div>
          )}
      </nav>
    </>
  )
}

export default withTheme(SiteNavigation)

const List = ({ label, url, isActive }) =>
  isActive && (
    <li key={label}>
      <a href={url}>{label}</a>
    </li>
  )

const Videos = ({ post_list }) =>
  post_list.map(({ id, ...post }) => <CardMini {...post} key={id} />)

const Articles = ({ post_list }) =>
  post_list.map(({ id, ...post }) => <Card {...post} key={id} />)

const Issue = ({ post_list }) =>
  post_list.map(({ id, acf, link }) => {
    const { day, month, year } = getDateFormat(acf.publishing_date)
    const digitalMagazineLink =
      '/digital-magazine?utm_medium=stylist.co.uk&utm_source=nav'

    return (
      <article className={styles.issueWrapper} key={id}>
        <H3>{`Published ${day}/${month}/${year}`}</H3>
        <a href={link} className="image">
          <Image image={acf.issue_cover} />
        </a>
        <AllIssuesButton href={digitalMagazineLink}>
          View all issues
          <IconCaret />
        </AllIssuesButton>
      </article>
    )
  })

const StylistExtraCTA = ({ isSM, close }) => (
  <div className={styles.stylistExtraCTA}>
    <div className={styles.stylistExtraCTAWrapper}>
      <div className={styles.stylistExtraCTAContent}>
        <H1>Unlock access to stylist extra</H1>
        <ul>
          <li>Exclusive Articles And In-Depth Reports</li>
          <li>Your Monthly Digital Stylist Magazine</li>
          <li>Weekly Sunday Supplement Email</li>
          <li>Interactive Q+A Events With Stylist Editors</li>
        </ul>
        <AnchorButton text="Subscribe Now" url="/subscription/extra/716862" />
      </div>
      <div className={styles.stylistExtraCTAImage}>
        <img
          src="/public/stylist-extra-cta-image.png"
          alt="Stylist Extra on Phones"
        />
      </div>
      <CloseButton onClick={() => close()}>
        {isSM ? 'Close' : <IconClose />}
      </CloseButton>
    </div>
  </div>
)

const shouldShowSwtcNav = (props, showSwtcNav) => {
  if (showSwtcNav) return true

  if (!props.acf?.package_ids) return false

  return features.SWTC_ALEXANDRIA_IDS.some(id =>
    props.acf?.package_ids.includes(id)
  )
}

const shouldShow = () => {
  return EXCLUDED_URLS.includes(window.location.pathname)
}
