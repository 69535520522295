import React, { Component } from 'react'
import isEqual from 'react-fast-compare'
import PropTypes from 'prop-types'

import AdvertContext from '_utility/context-advert'
import Inview from 'partials/in-view'

import {
  getGPTSlotSize,
  getGPTTargeting,
  getRenderSizeFromTemplate,
  isFluid
} from './_helpers'

import Advert from './advert'
import styles from './style'

export class AdvertSlot extends Component {
  state = {
    hasErrored: false,
    isEmpty: false,
    renderedSize: null,
    slotSize: null
  }

  // need to check deep equality here for state.slotSize
  shouldComponentUpdate = (nextProps, nextState) =>
    !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)

  componentDidMount() {
    if (this.props.sizes) this.detectRenderingSize()
  }

  componentDidCatch = () => this.setState({ hasErrored: true })

  detectRenderingSize = () => {
    const { sizes } = this.props
    sizes.forEach(({ viewport = 0, slot }, i) => {
      const mediaQuery = window.matchMedia(`(min-width: ${viewport}px)`)
      if (mediaQuery.matches) {
        this.setState({ slotSize: slot })
      }
      mediaQuery.addListener(({ matches }) =>
        this.setState({
          slotSize: matches ? slot : sizes[i - 1].slot,
          isEmpty: false
        })
      )
    })
  }

  onSlotRenderEnded = e => {
    // slotContentChanged helps define if we need to update the ad
    // if the content hasn't changed _but_ it's empty, hide the ad slot
    if (!e.slotContentChanged) {
      return this.setState({
        isEmpty: e.isEmpty,
        renderedSize: null // not sure about this
      })
    }
    // we're using the creativeTemplateId to work out
    // what fluid ad size we're rendering, otherwise we
    // get the standard array of [width, height]
    const renderedSize = isFluid(e.size)
      ? getRenderSizeFromTemplate(e.creativeTemplateId)
      : e.size
    // this flag helps us when rendering the final container style
    const renderType = isFluid(e.size) ? 'fluid' : 'standard'

    this.setState({ renderedSize, renderType })
  }

  render() {
    const {
      context,
      id,
      outOfPage,
      ready,
      sizes,
      style = {},
      targeting,
      gptTargeting,
      ozoneBidderConsent,
      type
    } = this.props
    const {
      hasErrored,
      isEmpty,
      renderedSize,
      renderType,
      slotSize
    } = this.state

    if (!sizes || hasErrored) return null

    const container =
      typeof style.container === 'function'
        ? style.container({ renderedSize, renderType })
        : style.container

    const shouldRender =
      ready && slotSize && (Array.isArray(slotSize[0]) || slotSize[0] > 0)

    return (
      <div css={container} aria-hidden="true">
        <div
          className={`${styles.root(isEmpty)} ${styles.placeholder({
            context,
            renderType,
            sizes
          })} ${style.advert || ''}`}
          style={styles.render({
            context,
            renderedSize
          })}
        >
          {type === 'lazy' && (
            <Inview observer={{ rootMargin: '670px 0px' }} once>
              {({ target, inview }) => (
                <div
                  className={styles.container({
                    renderedSize,
                    sizes
                  })}
                  ref={target}
                >
                  {shouldRender &&
                    inview && (
                      <Advert
                        adUnitPath={this.props.adUnitPath}
                        bidders={this.props.bidders}
                        id={id}
                        outOfPage={outOfPage}
                        slotRenderEnded={this.onSlotRenderEnded}
                        slotSize={getGPTSlotSize({ slotSize })}
                        targeting={getGPTTargeting({
                          slotSize,
                          targeting: { ...targeting, ...gptTargeting }
                        })}
                        ozoneBidderConsent={ozoneBidderConsent}
                      />
                    )}
                </div>
              )}
            </Inview>
          )}
          {type === 'static' && (
            <div
              className={styles.container({
                renderedSize,
                sizes
              })}
            >
              {shouldRender && (
                <Advert
                  adUnitPath={this.props.adUnitPath}
                  bidders={this.props.bidders}
                  id={id}
                  outOfPage={outOfPage}
                  slotRenderEnded={this.onSlotRenderEnded}
                  slotSize={getGPTSlotSize({ slotSize })}
                  targeting={getGPTTargeting({
                    slotSize,
                    targeting: { ...targeting, ...gptTargeting }
                  })}
                  ozoneBidderConsent={ozoneBidderConsent}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

AdvertSlot.defaultProps = {
  type: 'lazy'
}

AdvertSlot.propTypes = {
  context: PropTypes.string,
  id: PropTypes.string.isRequired,
  outOfPage: PropTypes.bool,
  sizes: PropTypes.arrayOf(
    PropTypes.shape({
      slot: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
      viewport: PropTypes.number
    })
  ).isRequired,
  style: PropTypes.shape({
    container: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
  }),
  targeting: PropTypes.object,
  type: PropTypes.oneOf(['static', 'lazy'])
}

export default props => (
  <AdvertContext.Consumer>
    {({
      adUnitPath,
      ready,
      shouldRender,
      gptTargeting,
      ozoneBidderConsent
    }) => {
      if (!shouldRender) return null
      return (
        <AdvertSlot
          {...props}
          adUnitPath={adUnitPath}
          ready={ready}
          gptTargeting={gptTargeting}
          ozoneBidderConsent={ozoneBidderConsent}
        />
      )
    }}
  </AdvertContext.Consumer>
)
