import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import styles from './style'
import { Box } from 'design-system'
import IconClose from 'assets/icon-close'
import { trackEvent } from 'utils/analytics-trackers'
import { defaultBannerSettings, defaultCookieSettings } from './helpers'

const Banner = ({
  children,
  bannerId = defaultBannerSettings.BANNER_ID,
  customStyle,
  shouldShow = true,
  disable,
  onBannerShow,
  onUserClose,
  useCookies = true,
  cookieConfig = { viewsCookie: {}, closedCookie: {} },
  isAbTest,
  buttonCSS = '',
  visible,
  forceLoad = false
}) => {
  if (!children) return null

  const [shouldMount, setShouldMount] = useState(true)
  const [isVisible, setVisible] = useState(false)

  useEffect(
    () => {
      setVisible(false)
    },
    [visible]
  )

  useEffect(() => {
    if (forceLoad) {
      setVisible(true)
      setShouldMount(true)
    }
  }, [])

  useEffect(
    () => {
      if (forceLoad) return

      let unmountTimeout

      if (shouldUnmount()) {
        // unmount banner if it won't ever show
        unmountTimeout = smoothUnmount()
      } else {
        // wait for reveal conditions before showing
        shouldReveal() && showBanner()
      }

      // must return cleanup to avoid memory leak
      return () => clearTimeout(unmountTimeout)
    },
    [shouldShow]
  )

  const shouldUnmount = () => disable || defaultDisable()
  const shouldReveal = () => shouldShow && defaultShouldShow()
  const defaultDisable = () =>
    useCookies && (wasClosedByUser() || viewCountLimitReached())
  const defaultShouldShow = () =>
    useCookies && !wasClosedByUser() && !viewCountLimitReached()

  // ensure cookieConfig.whatever is not undefined (optional chaining plz!!!)
  if (!cookieConfig.viewsCookie) cookieConfig.viewsCookie = {}
  if (!cookieConfig.closedCookie) cookieConfig.closedCookie = {}

  const closedCookie = {
    name: `${bannerId}_banner_closed`,
    value: defaultCookieSettings.CLOSED.value
  }
  const closedCookieOptions = {
    // override defaults with options passed via props
    ...defaultCookieSettings.OPTIONS,
    ...defaultCookieSettings.CLOSED.options,
    ...cookieConfig.closedCookie
  }

  const viewsCookie = {
    name: `${bannerId}_banner_${defaultCookieSettings.VIEW_COUNT.value}`,
    value: defaultCookieSettings.VIEW_COUNT.value,
    maxViews: defaultCookieSettings.VIEW_COUNT.maxViews
  }
  const viewsCookieOptions = {
    // override defaults with options passed via props
    ...defaultCookieSettings.OPTIONS,
    ...defaultCookieSettings.VIEW_COUNT.options,
    ...cookieConfig.viewsCookie
  }

  const showBanner = () => {
    // do nothing if banner was already visible
    if (isVisible) return
    setVisible(true)
    !forceLoad && incrementViewsCookie()
    onBannerShow && onBannerShow()
    trackShow()
  }

  const handleClose = () => {
    // default onClose behavior
    useCookies && setClosedByUserCookie()
    trackClose()
    // onClose behavior passed via props, if any
    onUserClose && onUserClose()
    smoothUnmount()
  }

  const smoothUnmount = () => {
    setVisible(false)

    // only unmount after closing animation is done
    const animationTimeout = setTimeout(
      () => setShouldMount(false),
      defaultBannerSettings.ANIMATION_DURATION
    )

    // return setTimeout for useEffect cleanup of async-y stuff
    return animationTimeout
  }

  const wasClosedByUser = () =>
    Cookies.get(closedCookie.name) === closedCookie.value

  const viewCountLimitReached = () => getViewsCookie() >= viewsCookie.maxViews

  const setClosedByUserCookie = () =>
    // custom options should override default options when possible
    Cookies.set(closedCookie.name, closedCookie.value, closedCookieOptions)

  const incrementViewsCookie = () =>
    Cookies.set(viewsCookie.name, getViewsCookie() + 1, viewsCookieOptions)

  const getViewsCookie = () => parseInt(Cookies.get(viewsCookie.name), 10) || 0

  const trackShow = () =>
    trackEvent(
      'Show',
      isAbTest ? `abtest-${bannerId}` : `"${bannerId}" banner`,
      `Seen ${getViewsCookie()} time${getViewsCookie() === 1 ? '' : 's'}`
    )

  const trackClose = () =>
    trackEvent(
      'Close',
      `"${bannerId}" banner`,
      `Closed after ${getViewsCookie()} view${
        getViewsCookie() === 1 ? '' : 's'
      }`
    )

  return (
    shouldMount && (
      <Box
        css={`
          ${styles.root(
            isVisible,
            defaultBannerSettings.ANIMATION_DURATION
          )} ${customStyle};
        `}
        data-testid="banner"
      >
        {children}
        <Box
          data-testid="button-close-banner"
          onClick={handleClose}
          css={styles.closeBtn}
        >
          <IconClose
            customStyle={`
            height: 10px;
            width: 10px;
            ${buttonCSS}
          `}
          />
        </Box>
      </Box>
    )
  )
}

Banner.propTypes = {
  children: PropTypes.node.isRequired,
  bannerId: PropTypes.string.isRequired,
  disable: PropTypes.bool,
  customStyle: PropTypes.string,
  shouldShow: PropTypes.bool,
  onBannerShow: PropTypes.func,
  onUserClose: PropTypes.func,
  useCookies: PropTypes.bool,
  cookieConfig: PropTypes.object
}

export default Banner
