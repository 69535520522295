import parseuri from 'parseUri'

export default {
  type: 'facebook',
  testType: url => parseuri(url).host === 'www.facebook.com',
  extractData: ({ embed_link }) => ({
    url: embed_link,
    variation: embed_link.indexOf('videos/') > -1 ? 'video' : 'post'
  })
}
