import React from 'react'

const IconPlay = () => (
  <svg
    width="137.821"
    height="137.821"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 137.821 137.821"
  >
    <circle fill="transparent" cx="50%" cy="50%" r="49" />
    <path
      color="#fff"
      d="m68.91,0a68.91,68.91 0 1 0 68.91,68.91a68.9,68.9 0 0 0 -68.91,-68.91zm32.149,75.579l-48.9,28.064a6.681,6.681 0 0 1 -9.92,-5.835l0,-57.8a6.685,6.685 0 0 1 9.92,-5.835l48.9,29.732a6.69,6.69 0 0 1 0,11.674z"
      id="Icon_awesome-play-circle"
    />
  </svg>
)

export default IconPlay
