import React from 'react'
import LogoStylist from 'assets/logo-stylist'
import { siteData } from 'root/config'
import HomepageAnchor from 'higher-order/homepage-anchor'

import styles from './style'

const ErrorView = () => (
  <main className={styles.root}>
    <section className={styles.content}>
      <LogoStylist className={styles.logo} />
      <h1 className={styles.title}>{'Sorry, we’ve run into a problem'}</h1>
      <div className={styles.body}>
        <p>
          {
            'There’s an issue with this page, we’ve been notified and our team are looking into it.'
          }
        </p>
        <p>
          {`In the meantime, you can find more great ${
            siteData.site.name
          } content `}
          <HomepageAnchor>{'here.'}</HomepageAnchor>
        </p>
      </div>
    </section>
  </main>
)

export default ErrorView
