import { css } from 'emotion'
import media from 'style/utils/media'
import * as blocks from 'style/blocks'
import { anim, color, type, layout } from 'style/tokens'
import { postTypes } from '../../partials/widget-officer/helpers'
import { isLongform } from 'utils/croissant'

const headingStandard = css(
  {
    fontSize: type.sizes.md
  },
  media(layout.mq.smUp, {
    fontSize: type.sizes.lg
  })
)

const headingLongform = css(
  {
    fontSize: type.sizes.lg
  },
  media(layout.mq.smUp, {
    fontSize: type.sizes.xl
  })
)

const heading = ({ postType }) =>
  css(
    blocks.type.canon,
    {
      '& > a': {
        color: color.core.primary,
        textDecoration: 'none',
        transition: `color ${anim.duration.instant} ${anim.easing.initial}`,
        '&:hover,&:focus': {
          color: color.coreLight.primary
        }
      }
    },
    postType === postTypes.STANDARD && headingStandard,
    isLongform(postType) && headingLongform
  )

export default {
  heading
}
