import React from 'react'
import PropTypes from 'prop-types'

import SquigglyLine from 'partials/squiggly-line'

const Divider = ({ category }) => <SquigglyLine category={category} />

Divider.propTypes = {
  category: PropTypes.string
}

export default Divider
