export const defaultBannerSettings = {
  BANNER_ID: 'stylist',
  ANIMATION_DURATION: 300
}

export const defaultCookieSettings = {
  CLOSED: {
    options: {
      expires: 14
    },
    value: 'closed_by_user'
  },
  VIEW_COUNT: {
    options: {
      expires: 0.5
    },
    value: 'view_count',
    maxViews: 3
  },
  OPTIONS: {
    expires: 365,
    secure: true,
    sameSite: 'none'
  }
}
