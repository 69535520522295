import React from 'react'

import { siteData } from 'root/config'

import { hasEntries } from 'utils/array'
import { transformImage } from 'utils/image'
import toAbsolute from 'utils/link-to-absolute'
import pruneFalsyValues from 'utils/prune-falsy-values'
import {
  isAuthor,
  isFrontPage,
  isPost,
  isReview,
  isTaxonomy,
  getReviewedItemType
} from 'utils/croissant'
import { convertToUTC } from 'utils/date'

const publisher = {
  '@context': 'https://schema.org',
  '@type': 'NewsMediaOrganization',
  name: 'The Stylist Group',
  alternateName: 'Stylist',
  url: 'https://www.stylist.co.uk/',
  logo:
    'https://images-stylist.s3-eu-west-1.amazonaws.com/app/uploads/2019/10/01144717/stylist_logo_black.png',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '020 7611 9700',
      contactType: 'customer service',
      areaServed: 'GB',
      availableLanguage: 'en'
    }
  ],
  sameAs: [
    'https://twitter.com/StylistMagazine',
    'https://www.instagram.com/stylistmagazine',
    'https://www.linkedin.com/company/stylistmagazine',
    'https://en.wikipedia.org/wiki/Stylist_(magazine)',
    'https://www.facebook.com/StylistMagazine'
  ]
}

const getSchema = props => {
  const heroImage = props.acf?.hero_images?.[0]?.sizes || {}
  const section = props.acf?.category?.name
  const tags = props.acf?.tags
  const keywords = hasEntries(tags) && tags.map(tag => tag.name)
  const metadata = siteData.site
  const unformattedAuthors = props._embedded?.author

  const formattedCreator = hasEntries(unformattedAuthors)
    ? unformattedAuthors.map(_author => {
        return {
          '@type': 'Person',
          name: _author.name
        }
      })
    : {}

  const formattedAuthor = hasEntries(unformattedAuthors)
    ? unformattedAuthors.map(_author => {
        return {
          '@type': 'Person',
          name: _author.name,
          description: _author.description
        }
      })
    : {}

  // Category/Tag/Series pages
  if (isTaxonomy(props.taxonomy)) {
    return {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      headline: props.name,
      description:
        props.description || (props.sell !== props.name ? props.sell : null),
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': props.link
      },
      publisher
    }
  }

  if (isReview(props))
    return {
      '@context': 'http://schema.org',
      '@type': 'Review',
      headline: props.title?.rendered,
      description: props.acf?.seo_description || props.acf?.sell,
      datePublished: convertToUTC(props.date_gmt),
      dateModified: convertToUTC(props.modified_gmt),
      creator: formattedCreator,
      url: props.link,
      thumbnailUrl: transformImage(heroImage.thumbnail, { w: 256, h: 256 }),
      keywords: keywords,
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': props.link
      },
      reviewRating: {
        '@type': 'Rating',
        bestRating: 5,
        ratingValue: props.acf.review_rating,
        worstRating: 1
      },
      itemReviewed: {
        '@type': getReviewedItemType(props),
        name: props.acf?.review_name,
        image: {
          '@type': 'ImageObject',
          url: transformImage(heroImage.letterbox, { w: 1200, h: 630 }),
          width: 1200,
          height: 630
        }
      },
      author: formattedAuthor,
      publisher
    }

  if (isPost(props.type))
    return {
      '@context': 'http://schema.org',
      '@type': 'Article',
      headline: props.title?.rendered,
      description: props.acf?.sell,
      datePublished: convertToUTC(props.date_gmt),
      dateModified: convertToUTC(props.modified_gmt),
      creator: formattedCreator,
      url: props.link,
      thumbnailUrl: transformImage(heroImage.thumbnail, { w: 256, h: 256 }),
      articleSection: section,
      keywords: keywords,
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': props.link
      },
      image: {
        '@type': 'ImageObject',
        url: transformImage(heroImage.letterbox, { w: 1200, h: 630 }),
        width: 1200,
        height: 630
      },
      author: formattedAuthor,
      publisher,
      ...(hasEntries(props?.acf?.package_ids) && {
        isAccessibleForFree: 'False',
        hasPart: {
          '@type': 'WebPageElement',
          isAccessibleForFree: 'False',
          cssSelector: '.paywall'
        }
      })
    }

  if (isFrontPage(props))
    return {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      headline: metadata.name,
      description: metadata.description,
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': metadata.url
      },
      image: {
        '@type': 'ImageObject',
        url: toAbsolute(require('public/default-share.jpg')),
        width: 1200,
        height: 630
      },
      publisher
    }

  if (isAuthor(props))
    return {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      headline: props.name,
      description: props.description,
      url: props.link,
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': props.link
      },
      publisher
    }
}

const Schema = props => {
  return (
    <script type="application/ld+json">
      {JSON.stringify(pruneFalsyValues(getSchema(props)))}
    </script>
  )
}

const AdditionalSchema = props => {
  const additionalMeta = props?.acf?.seo_schema || props.seo_schema

  if (!additionalMeta) return null

  return (
    <script type="application/ld+json">
      {JSON.stringify(pruneFalsyValues(additionalMeta))}
    </script>
  )
}

export { Schema, AdditionalSchema }
