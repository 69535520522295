import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import parse from 'html-react-parser'

import { Box, Vladivostok } from 'design-system'
import IconClose from 'assets/icon-close'
import Button from 'partials/button'
import Image from 'partials/image'
import IconLoading from 'partials/icon-loading'

import useScrollDistance from 'hooks/use-scroll-distance'

import { trackEvent } from 'utils/analytics-trackers'
import TelemetrySingleSignUp from '_utility/telemetry-single-sign-up'

import style from './style'

const Modal = ({
  backgroundColour,
  buttonColour,
  buttonLink,
  buttonText,
  buttonTextColour,
  externalLink,
  image,
  text,
  textColour,
  logo,
  id,
  title,
  emailCapture,
  telemeteryId,
  emailSuccessMessage,
  emailRepeatMessage,
  forceLoad = false
}) => {
  const [visible, setVisible] = useState(forceLoad)
  const [scrollThreshold, setThreshold] = useState(0)
  const [alreadySeen, setSeen] = useState(false)
  const scrolledDistance = useScrollDistance().scrollY

  const VIEWS_COOKIE = {
    name: `DESKTOP_MODAL_${id}`,
    maxViews: 3,
    options: {
      expires: 3,
      secure: true,
      sameSite: 'none'
    }
  }

  useEffect(
    () => {
      if (
        // never show if cookies aren't allowed
        !navigator.cookieEnabled ||
        // no useEffect if already visible
        visible ||
        // prevent banner from re-appearing on scroll if cookies somehow fail
        alreadySeen
      )
        return
      // set the scroll threshold only once, ignore future document resizes
      if (!scrollThreshold)
        setThreshold(window.document.body.offsetHeight * 0.4)

      if (
        Boolean(scrollThreshold) &&
        scrolledDistance > scrollThreshold &&
        getViewsCookie() < VIEWS_COOKIE.maxViews
      ) {
        showModal()
      }
    },
    [scrolledDistance]
  )

  const showModal = () => {
    !forceLoad && incrementViewsCookie()
    trackShow()
    setVisible(true)
    setSeen(true)
  }

  const incrementViewsCookie = () =>
    Cookies.set(VIEWS_COOKIE.name, getViewsCookie() + 1, VIEWS_COOKIE.options)

  const getViewsCookie = () => parseInt(Cookies.get(VIEWS_COOKIE.name), 10) || 0

  const trackShow = () => trackEvent('Show', 'CMS popup', id)

  const trackClose = method =>
    trackEvent(
      'Close',
      'CMS popup',
      `Closed ${id} via ${method} after ${getViewsCookie()} times seen`
    )

  const hide = (method = '?') => {
    trackClose(method)
    setVisible(false)
  }

  const handleClickOutside = e =>
    e.target === e.currentTarget && hide('click outside modal')

  const modalCampaign = title.replace(/\s+/g, '_').toLowerCase()

  return (
    visible && (
      <Box
        position="fixed"
        top="0"
        right="0"
        bottom="0"
        left="0"
        zIndex={99}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg="rgba(33,41,52,.85)"
        onClick={handleClickOutside}
        className={style.root}
      >
        <Box
          display="flex"
          position="relative"
          height={[0, 0, '580px', '720px']}
          width={[0, 0, '780px', '980px']}
          bg="white"
          fontSize="24px"
          zIndex={100}
        >
          <Box width="50%" overflow="hidden">
            <Image
              image={image}
              width="100%"
              height="auto"
              crop="portrait"
              type="static"
            />
          </Box>
          <Box
            width="51%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            background={backgroundColour}
            p="20px"
            overflow="hidden"
            color={textColour}
            justifyContent="space-between"
            textAlign="center"
          >
            <Box maxWidth="250px">
              <Image image={logo} width="100%" height="auto" type="static" />
            </Box>
            <Box dangerouslySetInnerHTML={{ __html: text }} />
            {emailCapture ? (
              <TelemetrySingleSignUp
                campaign={modalCampaign}
                internalCTA={`cms_email_signup_popup_${id}`}
                source="stylist"
                verticalId={telemeteryId}
              >
                {({
                  email,
                  handleFormSubmit,
                  handleInputChange,
                  status,
                  message
                }) => (
                  <Box
                    is="form"
                    onSubmit={handleFormSubmit}
                    width="300px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-around"
                    alignItems="center"
                    textAlign="center"
                    background="none"
                  >
                    {status !== 'ready' && (
                      <p className={style.message}>
                        {(status === 'success' && parse(emailSuccessMessage)) ||
                          (status === 'repeat' && parse(emailRepeatMessage)) ||
                          (status === 'fail' && message)}
                      </p>
                    )}
                    {(status === 'ready' || status === 'fail') && (
                      <>
                        <Vladivostok
                          is="input"
                          name="email"
                          onChange={handleInputChange}
                          required
                          type="email"
                          value={email}
                          placeholder={'Enter your email address here'}
                          border="transparent"
                          borderRadius={0}
                          fontSize="xs"
                          fontWeight="normal"
                          color="#001338"
                          height={35}
                          width="100%"
                          p={3}
                        />
                        {status === 'loading' && (
                          <IconLoading color={'white'} />
                        )}
                        <Button
                          type="submit"
                          small
                          width="100%"
                          className={style.submitButton(
                            buttonTextColour,
                            buttonColour
                          )}
                        >
                          {buttonText || 'SIGN ME UP'}
                        </Button>
                      </>
                    )}
                  </Box>
                )}
              </TelemetrySingleSignUp>
            ) : (
              <Button
                onClick={() => {
                  trackEvent(`Clicked action`, 'CMS popup', id)
                  externalLink
                    ? window.open(buttonLink) && setVisible(false)
                    : (window.location = buttonLink)
                }}
                className={style.button(buttonTextColour, buttonColour)}
              >
                {buttonText}
              </Button>
            )}
          </Box>
          <Box
            onClick={() => hide('modal "X" button')}
            position="absolute"
            top="15px"
            right="15px"
            p={1}
          >
            <IconClose
              customStyle={`
                color: ${textColour};
                height: 14px;
                width: 14px;
                cursor pointer;
              `}
            />
          </Box>
        </Box>
      </Box>
    )
  )
}

export default Modal
