import { css } from 'emotion'
import { layout } from 'style/tokens'
import rem from 'style/utils/rem'

const gutter = rem(layout.grid.gutter / 2)

const justifyMap = {
  around: 'space-around',
  between: 'space-between',
  center: 'center',
  end: 'flex-end',
  start: 'flex-start'
}

const root = ({ justify, gutters, fullWidth, customStyle }) =>
  css(
    {
      display: 'flex',
      justifyContent: justifyMap[justify],
      flexWrap: 'wrap',
      marginLeft: gutters ? `-${gutter}` : null,
      marginRight: gutters ? `-${gutter}` : null,
      '& > div': {
        padding: !gutters && 0
      },
      width: fullWidth ? '100%' : 'auto'
    },
    customStyle
  )

export default {
  root
}
