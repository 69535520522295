/* eslint-disable max-len */
import React from 'react'
import IconBase from 'assets/icon-base'

const IconFacebook = props => (
  <IconBase {...props}>
    <path d="M135.3 98.573h-22.7v80.38H78.21v-80.38H61.85V70.34H78.21V52.044c0-13.074 6.43-33.545 34.703-33.545l25.467.105V46.03h-18.474c-3.044 0-7.307 1.467-7.307 7.705V70.34h25.705L135.3 98.557v.015z" />
  </IconBase>
)

export default IconFacebook
