import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { injectGlobal } from 'emotion'

import loadJS from 'utils/load-js'
import InView from 'partials/in-view'

injectGlobal`
  .iframely-responsive {
    height: 0;
    left: 0;
    padding-bottom: 56.25%;
    position: relative;
    top: 0;
    width: 100%;
  }
  .iframely-responsive > * {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

class IframelyLoader extends PureComponent {
  componentDidMount() {
    loadJS({
      id: 'iframely-embeds',
      src: 'https://embeds.stylist.co.uk/embed.js'
    })
  }

  onEnterView = () => {
    // if iframely embed script has loaded
    if (window.iframely && window.iframely.load) {
      window.iframely.load()
    }
  }

  render() {
    const { embed, children } = this.props

    if (!embed) return null

    return (
      <InView
        onEnterView={this.onEnterView}
        observer={{ rootMargin: '670px 0px' }}
        once
      >
        {({ target, inview }) =>
          children({
            target,
            embed: inview ? embed : null
          })
        }
      </InView>
    )
  }
}

IframelyLoader.propTypes = {
  children: PropTypes.func.isRequired,
  embed: PropTypes.string.isRequired
}

export default IframelyLoader
