import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'emotion-theming'

import { siteData } from 'root/config'

import HomepageAnchor from 'higher-order/homepage-anchor'

import LogoStylist from 'assets/logo-stylist'

import SocialLinks from 'partials/social-links'
import Anchor from 'partials/anchor'

import styles from './style'

export const ReducedSiteFooter = ({ customStyle }) => {
  return (
    <footer className={styles.root} role="contentinfo">
      <ul
        css={`
          ${styles.menuRoot} ${customStyle};
        `}
      >
        <li className={styles.listItem}>
          <Anchor
            url={'https://www.stylist.co.uk/about/cookie-policy'}
            className={styles.link}
          >
            {'Cookie Policy'}
          </Anchor>
        </li>
        <li className={styles.listItem}>
          <Anchor
            url={'https://www.stylist.co.uk/about/privacy-policy'}
            className={styles.link}
          >
            {'Privacy Policy'}
          </Anchor>
        </li>
        <li className={styles.listItem}>
          <Anchor
            url={
              'https://www.dcthomsonshop.co.uk/subscriptions/womens-magazines/stylist/stylist-subscription'
            }
            className={styles.link}
          >
            {'Subscriptions'}
          </Anchor>
        </li>
        <li className={styles.listItem}>
          <Anchor
            url={'https://www.stylist.co.uk/about/website-terms-and-conditions'}
            className={styles.link}
          >
            {'Website Terms & Conditions'}
          </Anchor>
        </li>
        <li className={styles.listItem}>
          <Anchor
            url={'https://www.thestylistgroup.com/contact'}
            className={styles.link}
          >
            {'Contact'}
          </Anchor>
        </li>
        <li className={styles.listItem}>
          <Anchor
            url={'https://www.stylist.co.uk/emails'}
            className={styles.link}
          >
            {'Subscribe'}
          </Anchor>
        </li>
        <li className={styles.listItem}>
          <button
            className={styles.listLink}
            onClick={() => window.Optanon.ToggleInfoDisplay()}
          >
            Cookie Preferences
          </button>
        </li>
      </ul>
      <SocialLinks isReducedFooter={true} />
      <section className={styles.main}>
        <div className={styles.sectionCenter}>
          <HomepageAnchor className={styles.linkStandard}>
            <LogoStylist className={styles.logoStandard} />
          </HomepageAnchor>
          <p className={styles.subtext}>
            {`Copyright © 2010–${new Date().getFullYear()} ${
              siteData.site.name
            }`}
          </p>
        </div>
      </section>
    </footer>
  )
}

ReducedSiteFooter.propTypes = {
  series: PropTypes.object
}

export default withTheme(ReducedSiteFooter)
