import React from 'react'
import PropTypes from 'prop-types'

import { siteData } from 'root/config'
import IconTwitter from 'assets/icon-twitter'
import IconFacebook from 'assets/icon-facebook'
import IconInstagram from 'assets/icon-instagram'
import IconYoutube from 'assets/icon-youtube'
import Anchor from 'partials/anchor'

import { display } from 'style/blocks'
import styles from './style'

const SocialLinks = props => {
  const mapIcons = {
    facebook: IconFacebook,
    twitter: IconTwitter,
    instagram: IconInstagram,
    youtube: IconYoutube
  }

  const renderListItem = key => {
    const url = siteData.social[key].page
    const Icon = mapIcons[key]
    return (
      <li className={styles.item} key={url}>
        <Anchor
          className={props.isReducedFooter ? styles.linkMyStylist : styles.link}
          url={url}
        >
          <span className={display.hidden}>{`Visit us on ${key}`}</span>
          <Icon customStyle={styles.icon} />
        </Anchor>
      </li>
    )
  }

  return (
    <ul className={styles.root(props)}>
      {Object.keys(siteData.social).map(renderListItem)}
    </ul>
  )
}

SocialLinks.propTypes = {
  customStyle: PropTypes.string
}

export default SocialLinks
