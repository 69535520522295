import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { getMaxSlotSize, getRelevantBidders } from '../_helpers'
import isMobileUser from 'utils/is-mobile-user'

class Advert extends Component {
  slot = null
  events = [
    'impressionViewable',
    'slotOnload',
    'slotRenderEnded',
    'slotRequested',
    'slotVisibilityChanged'
  ]

  componentDidMount() {
    // define ad units for both
    window.googletag.cmd.push(this.bindEvents)
    window.googletag.cmd.push(this.defineSlot)

    if (!this.props.bidders) {
      return window.googletag.cmd.push(() =>
        window.googletag.pubads().refresh([this.slot])
      )
    }

    window.pbjs.que.push(this.handleRequestBids)
  }

  componentWillUnmount() {
    window.googletag.destroySlots([this.slot])
  }
  shouldComponentUpdate = () => false

  handleRequestBids = () => {
    window.pbjs.aliasBidder('appnexus', 'groupm', { gvlid: 98 })

    this.setAdUnits()

    window.pbjs.requestBids({
      timeout: 800,
      adUnitCodes: [`ad-${this.props.id}`], // adUnitCode should be slot div name like "ad-side_2"
      bidsBackHandler: this.handlePrebidCb
    })
  }
  handlePrebidCb = () => {
    // setTargetingForGPTAsync should be called with [adUnitCode] e.g. "ad-side_2"
    window.pbjs.setTargetingForGPTAsync([`ad-${this.props.id}`])
    window.googletag.pubads().refresh([this.slot])
  }

  setAdUnits = () => {
    const { adUnitPath, bidders, slotSize, id, targeting = {} } = this.props

    let sizes = slotSize

    if (slotSize.some(size => size === 'fluid')) {
      const target = targeting.fluidSlotSize.split(',')
      sizes = slotSize.map(
        (size, i) =>
          size === 'fluid' ? getMaxSlotSize(target && target[i]) : size
      )
    }

    const videoConfig = {
      video: {
        playerSize: [640, 360],
        mimes: ['video/mp4'],
        context: 'outstream'
      }
    }

    // hardcode video capability into 'ad-content_1' for mobile users
    const shouldAllowVideoAds = () => isMobileUser() && id === 'content_1'

    const adUnit = {
      code: `ad-${id}`, // adUnit.code should match div name e.g. "ad-side_2"
      mediaTypes: {
        banner: { sizes },
        ...(shouldAllowVideoAds() && videoConfig)
      },
      bids: getRelevantBidders({
        bidders,
        adUnitPath,
        targeting
      })
    }

    window.pbjs.addAdUnits([adUnit])
  }

  bindEvents = () => {
    this.events.forEach(event => {
      if (typeof this.props[event] === 'function') {
        window.googletag.pubads().addEventListener(event, e => {
          if (this.slot && this.slot === e.slot) this.props[event](e)
        })
      }
    })
  }

  defineSlot = () => {
    const { adUnitPath, id, slotSize, targeting, outOfPage } = this.props

    if (outOfPage) {
      this.slot = window.googletag.defineOutOfPageSlot(adUnitPath, `ad-${id}`)
    } else {
      this.slot = window.googletag.defineSlot(adUnitPath, slotSize, `ad-${id}`)
    }

    this.slot.addService(window.googletag.pubads())

    Object.keys(targeting || {}).forEach(key => {
      this.slot.setTargeting(key, targeting[key])
    })

    window.googletag.pubads().enableSingleRequest()
    window.googletag.enableServices()
  }

  render = () => <div id={`ad-${this.props.id}`} />
}

Advert.defaultProps = {
  bidders: ['ozone', 'appnexus', 'groupm']
}

Advert.propTypes = {
  adUnitPath: PropTypes.string.isRequired,
  bidders: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  id: PropTypes.string.isRequired,
  impressionViewable: PropTypes.func,
  slotOnload: PropTypes.func,
  slotRenderEnded: PropTypes.func,
  slotRequested: PropTypes.func,
  slotSize: PropTypes.array.isRequired,
  slotVisibilityChanged: PropTypes.func
}

export default Advert
