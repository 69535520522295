import React from 'react'
import PropTypes from 'prop-types'

import useCountdown from 'hooks/use-countdown'

import style from './style.js'

const Countdown = ({ date, time, formattedDateTime, className }) => {
  const dateTime = date && time ? `${date}T${time}:00.00Z` : formattedDateTime
  const { days, hours, minutes, seconds } = useCountdown(dateTime, 1000)
  return (
    <div className={`${style.countdownNumbers} ${className}`}>
      <div className={style.countdownNumberContainer}>
        <p className={style.countdownNumber}>{days}</p>
        <p className={style.countdownMetric}>Days</p>
      </div>
      <div className={style.countdownNumberContainer}>
        <p className={style.countdownNumber}>{hours}</p>
        <p className={style.countdownMetric}>Hours</p>
      </div>
      <div className={style.countdownNumberContainer}>
        <p className={style.countdownNumber}>{minutes}</p>
        <p className={style.countdownMetric}>Minutes</p>
      </div>
      <div className={style.countdownNumberContainer}>
        <p className={style.countdownNumber}>{seconds}</p>
        <p className={style.countdownMetric}>Seconds</p>
      </div>
    </div>
  )
}

Countdown.propTypes = {
  date: PropTypes.string,
  time: PropTypes.string,
  dateTime: PropTypes.string
}

export default Countdown
