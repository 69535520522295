import { stringify } from 'query-str-es5'
import toAbsolute from 'utils/link-to-absolute'
import { formatDate } from 'utils/date'

export const getPopularParams = query => {
  const now = new Date()
  // start date is two days previous to today
  const start_date = formatDate(new Date(now.getTime() - 1728e5)).date
  const end_date = formatDate(now).date
  return {
    start_date,
    end_date,
    from_date: start_date,
    results: 5,
    ...query
  }
}

// helper function that constructs a Croissant API endpoint based on its type
// [posts, categories, tags, etc.]
export default (type, query, absolute = true) => {
  // default to posts, allow:
  // croissantEndpoint('posts', { filter: 'thing' })
  // croissantEndpoint({ filter: 'thing' })
  if (typeof type === 'object') {
    query = type
    type = 'posts'
  }
  const path = `/api/v1/${type}`
  const endpoint = absolute ? toAbsolute(path) : path
  // return endpoint with filter if necessary
  if (query)
    return stringify(
      { visibility_exclude: 'hidden,link-only,sitemap-only', ...query },
      endpoint
    )
  return endpoint
}
