import { css } from 'emotion'
import media from 'style/utils/media'
import * as blocks from 'style/blocks'
import { layout } from 'style/tokens'

const root = ({ width, customStyle }) =>
  css(
    blocks.layout.position.centerX,
    {
      boxSizing: 'content-box',
      paddingLeft: layout.sizes.sm,
      paddingRight: layout.sizes.sm,
      position: 'relative',
      maxWidth: layout.trunk[width]
    },
    media(layout.mq.smUp, {
      paddingLeft: layout.sizes.md,
      paddingRight: layout.sizes.md
    }),
    customStyle
  )

export default {
  root
}
