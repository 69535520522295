import { hasEntries } from 'utils/array'

const isEmpty = val => {
  if (Array.isArray(val)) return !hasEntries(val)
  if (typeof val === 'string') return val.length === 0
  if (typeof val === 'object')
    return !hasEntries(Object.getOwnPropertyNames(val))
  return false
}

// prune null and undefined references from the object
export default (data, { pruneFalse = false, pruneEmpty = false } = {}) => {
  const obj = { ...data }
  Object.keys(obj).forEach(key => {
    const val = obj[key]
    if (
      val === null ||
      val === undefined ||
      (pruneFalse && val === false) ||
      (pruneEmpty && isEmpty(val))
    ) {
      delete obj[key]
    }
  })
  return obj
}
