import { useEffect, useContext } from 'react'
import AssetsContext from '_utility/context-assets'
import CMPContext from '_utility/context-cmp'

const Facebook = ({ appId }) => {
  const { loadAsset } = useContext(AssetsContext)
  const { purposeConsents } = useContext(CMPContext)

  useEffect(
    () => {
      if (!parseInt(purposeConsents[3 - 1], 10)) return

      window.fbAsyncInit = () => {
        window.FB.init({
          appId,
          xfbml: true,
          version: 'v2.9'
        })
        window.FB.AppEvents.logPageView()
      }
      loadAsset('https://connect.facebook.net/en_US/sdk.js', 'facebook-jssdk')
    },
    [purposeConsents]
  )

  return null
}

export default Facebook
