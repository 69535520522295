import { rem, em } from 'polished'
import deepmerge from 'deepmerge'

import { darken, lighten } from 'style/utils/color'
import pruneFalsyValues from 'utils/prune-falsy-values'

const theme = {
  primary: '#3dd2be',
  secondary: '#4c6085',
  tertiary: '#1a4353'
}
const sans =
  '-apple-system, Roboto, BlinkMacSystemFont, Helvetica Neue, sans-serif'
const serif = 'Georgia, Times New Roman, serif'

const padding = {
  xs: rem(8),
  sm: rem(12),
  md: rem(16), // 16px
  lg: rem(20),
  xl: rem(24)
}

const animations = {
  buttonHover: 'all .2s'
}

const space = [
  rem(0),
  rem(4),
  rem(8),
  rem(16),
  rem(32),
  rem(64),
  rem(128),
  rem(256)
]

const breakpoints = [em(550), em(800), em(1000), em(1200)]

const maxWidths = {
  small: rem(550),
  medium: rem(920),
  large: rem(1400)
}

const fontSizes = {
  xxxs: rem(12),
  xxs: rem(14),
  xs: rem(16), // 16px
  sm: rem(20),
  md: rem(24),
  lg: rem(32),
  xl: rem(48),
  xxl: rem(64),
  xxxl: rem(80),
  xxxxl: rem(96)
}

const fontWeights = {
  normal: 400,
  semibold: 600,
  bold: 700,
  black: 900
}

const lineHeights = {
  sm: 1,
  md: 1.2,
  lg: 1.5
}

const letterSpacings = {
  sm: em(0.3),
  md: em(1),
  lg: em(1.5),
  xl: em(2),
  xxl: em(2.6),
  xxxl: em(3)
}

export const fonts = {
  NoeDisplay: `'Noe Display', ${serif}`,
  HurmeGeometricSans1: `'Hurme Geometric Sans 1', ${sans}`,
  HurmeGeometricSans2: `'Hurme Geometric Sans 2', ${sans}`,
  HurmeGeometricSans3: `'Hurme Geometric Sans 3', ${sans}`,
  Ovo: `'Ovo', serif`,
  TitlingGothic: `'titling-gothic-fb-extended', gill sans, sans-serif`,
  FuturaPT: `'futura-pt',  ${sans}`,
  sourceSerifProSerif: 'source-serif-pro'
}

const colors = {
  black: '#1d1d1d',
  white: '#ffffff',

  tonal: ['#f3f3f3', '#e2e2e2', '#a0a0a0', '#717171', '#43464b', '#262728'],

  primary: theme.primary,
  primaryDark: darken(theme.primary),
  primaryLight: lighten(theme.primary),

  secondary: theme.secondary,
  secondaryDark: darken(theme.secondary),
  secondaryLight: lighten(theme.secondary),

  tertiary: theme.tertiary,

  success: '#85d7a3',
  fail: '#e85063',

  sponsoredBackground: '#f3f3f3',

  grey: '#636363',
  offsetGrey: '#BFBFBF',
  backgroundGrey: '#F3F3F3',
  borderGrey: '#D5D5D5',

  error: '#FF5E5B'
}

const buttonSizes = {
  membersArea: '250px'
}

const strongWomen = {
  primary: '#FF8088',
  primaryDark: darken('#FF8088'),
  primaryLight: lighten('#FF8088')
}

const curiosityAcademy = {
  primary: '#001338',
  accent: '#fd7d73'
}

const defaultTheme = {
  breakpoints,
  space,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  fonts,
  colors,
  maxWidths,
  buttonSizes,
  padding,
  animations,
  strongWomen,
  curiosityAcademy
}

export const getTheme = (props = {}) => {
  const series = props.taxonomy === 'series' ? props : props.acf?.series || {}

  if (series) {
    if (series.slug === 'live-luxe') {
      return defaultTheme
    }

    return deepmerge(defaultTheme, {
      colors: pruneFalsyValues({
        accent: series.accent_colour || colors.black,
        accentDark: darken(series.accent_colour) || lighten(colors.black),
        primary: series.primary_colour,
        primaryDark: darken(series.primary_colour),
        primaryLight: lighten(series.primary_colour),
        textAccentColour: series.text_accent_colour || colors.white
      })
    })
  }
}
