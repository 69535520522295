import { css } from 'emotion'
import { layout, color } from 'style/tokens'

import { articles, workouts, issue } from './components'

export const container = (open, hide) => css`
  /* position: sticky;
  top: ${hide ? '-104px' : '0'};
  transition: top 0.5s ease-in; */
  z-index: 10;
  position: relative;
  min-height: 104px;
  width: 100vw;
  background-color: ${color.base.white};
  padding: 16px 60px 0px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content 1fr auto;
  grid-template-rows: repeat(2, min-content);
  align-items: center;
  font-family: 'futura-pt Medium', -apple-system, BlinkMacSystemFont,
    Helvetica Neue, sans-serif;
  color: ${color.base.black};
  grid-row-gap: 16px;
  .stylistLogo {
    max-width: 342px;
    width: inherit;
    grid-row: 1 / 2;
  }
  > * {
    ul,
    li {
      font-size: 13px;
      line-height: 14.3px;
      letter-spacing: 1.5px;
    }
    h2 {
      font-weight: bold;
      font-size: 25px;
      line-height: 27.5px;
      letter-spacing: 6.3px;
      text-transform: uppercase;
      padding-bottom: 24px;
    }
  }
  @media ${layout.mq.mdUp} and ${layout.mq.xlDown} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, min-content);
    padding: 16px 32px 0px;
    grid-row-gap: 20px;
    .stylistLogo {
      grid-row: 1 / 3;
      grid-column: 1/2;
    }
  }
  @media ${layout.mq.mdDown} {
    min-height: min-content;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
    grid-auto-rows: min-content;
    grid-auto-flow: row;
    grid-row-gap: 0px;
    padding: 8px 16px 0px;
    border-bottom: 1px solid rgb(208,208,208, .32);
    .stylistLogo {
      width: 100%;
    }
  }
  @media ${layout.mq.smOnly} {
    .stylistLogo {
      width: 80%;
    }
  }
    // sheer cover
    &::after {
    content: '';
    width: 100%;
    position: absolute;
    height: 100vh;
    opacity: 0.5;
    background: black;
    z-index: -1;
    top: 100%;
    visibility: ${open ? 'visible' : 'hidden'};
  }
`

export const dropDownOptions = css`
  width: 100%;
  grid-row: 1;
  /* end of parent related css */
  display: flex;
  list-style-type: none;
  margin-left: 60px;
  height: 20px;
  align-items: flex-start;
  background-color: ${color.base.white};
  
  li:not(:last-child) {
    margin-right: 40px;
  }
  @media ${layout.mq.mdUp} and ${layout.mq.xlDown} {
    grid-row: 2 / 3;
    grid-column: 2 / end;
    margin-left: 0;
    justify-content: flex-end;
    li:not(:last-child) {
      margin-right: 20px;
    }
  }
  @media ${layout.mq.mdDown} {
    grid-row: 3 / 4;
    grid-column: 1 / end;
    /* end of parent related css */
    justify-content: space-between;
    margin: 0;
    padding: 8px 0px;
    height: 100%;
    li {
      margin: 0 !important;
      position: relative;
    }
  }
`

export const navLinks = css`
  justify-self: end;
  grid-row: 1;
  /* end of parent related css */
  display: flex;
  align-items: center;
  list-style-type: none;
  @media ${layout.mq.mdUp} and ${layout.mq.xlDown} {
    grid-row: 1 / 2;
    grid-column: 2 / end;
  }
  @media ${layout.mq.mdDown} {
    flex-flow: row-reverse;
    grid-column: 2 / end;
    /* end of parent related css */
  }
`

export const dropDownMenu = props => css`
  grid-row: 3 / -1;
  grid-column: 1 / -1;
  /* end of parent related css */
  width: 100vw;
  height: 100%;
  background-color: ${color.base.white};
  margin: 0 -60px;
  padding: 0px 32px 32px;
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: ${
    props.isStylistExtraTab
      ? 'repeat(3, auto)'
      : 'repeat(3, minmax(auto, 350px))'
  } ;
  grid-row-gap: 32px;
  grid-column-gap: 120px;
  overflow: scroll;
  grid-auto-flow: ${props.isStylistExtraTab ? 'column' : 'row'};
  justify-content: ${props.isStylistExtraTab ? 'left' : 'initial'};
  
  @keyframes slide-in-top {
    0% {
      transform: translateY(-32px);
    }
    100% {
      transform: translateY(0);
    }
  }
  animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  @media ${layout.mq.xxlUp} {
    padding: 0px 16px 64px 147px;
  }
  @media ${layout.mq.mdUp} and ${layout.mq.xlDown} {
    grid-row: 4 / -1;
    grid-column-gap: 60px;
    margin: 0 -32px;
  }
  @media ${layout.mq.lgDown} {
    grid-template-rows: min-content auto auto;
  }
  @media ${layout.mq.mdDown} {
    grid-row: 4/ 5;
    grid-column: 1 / -1;
    margin: 0 -16px;
    /* end of parent related css */
    display: flex;
    flex-direction: column;
    color: ${color.base.white};
    height: calc(100vh - 84px);
    background-color: black;
    padding: 24px 16px 64px;
    animation: none;
    &::after {
      content: none;
    }
  }
`
export const stylistExtraCTA = css`
  grid-row: 3 / -1;
  grid-column: 1 / -1;
  @media ${layout.mq.mdUp} and ${layout.mq.xlDown} {
    grid-row: 4 / -1;
    grid-column-gap: 60px;
    margin: 0 -32px;
  }
  @media ${layout.mq.mdUp} {
    position: absolute;
    top: -100%;
      @keyframes slide-in-top {
      0% {
        transform: translateY(-32px);
      }
      100% {
        transform: translateY(0);
      }
    }
    animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @media ${layout.mq.mdDown} {
    grid-row: 4/ 5;
    grid-column: 1 / -1;
    margin: 0 -16px;
    padding: 26px 32px 0px;
    height: calc(100vh - 123px);
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }
  @media ${layout.mq.smOnly} {
    height: calc(100vh - 84px);
  }
  @media ${layout.mq.xxlUp} {
    padding: 0px 32px 0px 147px;
  }
  
  width: 100vw;
  height: 100%;
  background-color: ${color.base.black};
  margin: 0 -60px;
  min-height: 435px;
  padding: 0px 32px;
  color: ${color.base.white};
  position: relative;
  
  button {
    margin: 0 auto;
    padding: none;
    @media ${layout.mq.mdUp} {
      top: 16px;
      position: absolute;
    }
    @media ${layout.mq.mdDown} {
      height: 50px;
    }
  }
`

export const stylistExtraCTAWrapper = css`
  max-width: 1400px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  column-gap: 32px;
  justify-content: space-between;
  overflow: hidden;
  @media ${layout.mq.mdDown} {
    flex-direction: column;
    row-gap: 24px;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: unset;
  }
`

export const stylistExtraCTAContent = css`
  width: max-content;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  ul {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-left: 2em;
    li {
      font-size: 20px;
      line-height: 30px;
      &::marker {
        font-size: 24px;
      }
    }
  }
  @media ${layout.mq.mdUp} {
    min-width: 300px;
  }
  @media ${layout.mq.mdDown} {
    width: 100%;
  }
  @media ${layout.mq.smOnly} {
    width: 100%;
  }
  a {
    margin: 0;
    border-radius: 24px;
  }
`
export const stylistExtraCTAImage = css`
  margin-bottom: -106px;
  @media ${layout.mq.lgDown} {
    margin: 0;
  }
`

export const stylistExtraButton = `
  grid-column: 2/end;
  grid-row: -1/end;
  height: max-content;
  align-self: end;
  bottom: 64px;
  margin: 0 !important;
  @media (max-width: 850px) {
    grid-column: 1/end;
  }
  @media ${layout.mq.mdDown} {
    margin: auto !important;
  }
`

export const menuSection = (sectionType, active) => css`
  h2 {
    grid-row: 1 / 2;
  }
  li {
  grid-column: 1 / -1;
  font-weight: normal;
  text-transform: uppercase;
    a {
      text-decoration: none;
      color: currentColor;
      width: max-content;
      &:hover {
        padding-bottom: 3px;
        border-bottom: 1px solid black;
      }
    }
  }
/* end of parent related css */
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-auto-rows: min-content;
  box-sizing: border-box;
  [data-cy='anchorButton']:hover {
    padding-bottom: 12px;
    border: 2px solid #31a898; 
  }
  svg {
    transform: ${active ? `rotate(0deg)` : `rotate(180deg)`};
    transition: transform .75s ease-in-out;
  }
  > * {
    &:not(:first-of-type) {
      padding-top: 16px;
    }
  }
  @media ${layout.mq.mdDown} {
    color: ${color.base.white};
    svg {
      grid-column: 2 / -1;
      height: 28px;
      width: 28px;
    }
    li {
      padding-bottom: 32px;
    }
  }
  ${sectionType === 'Issue' && issue}
  ${sectionType === 'Articles' && articles}
  ${sectionType === 'Workouts' && workouts}
`
export const issueWrapper = css`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  .image {
    max-width: 336px;
    cursor: pointer;
  }
  @media ${layout.mq.mdUp} {
    h3 {
      display: none;
    }
  }
`

export const swtcNav = openDropDown => css`
  grid-row: 2 / 3;
  grid-column: 1 / -1;
  width: 100vw;
  margin: 0 -60px;
  z-index: 1;
  display: ${openDropDown ? 'none' : 'inline-block'};
  @media ${layout.mq.mdUp} and ${layout.mq.xlDown} {
    grid-row: 3 / 4;
    margin: 0 -32px;
  }
  @media ${layout.mq.mdDown} {
    grid-row: 4 / 5;
    margin: 0 -16px;
  }
`
