/* eslint-disable max-len */
import React from 'react'
import IconBase from 'assets/icon-base'

const IconYoutube = props => (
  <IconBase {...props}>
    <path
      opacity=".1"
      d="M122.93 100.894L82.197 73.63l46.45 24.293-5.72 2.97"
    />
    <path d="M82.206 122.047l-.008-48.416 46.45 24.3-46.442 24.124zM184.19 65.243s-1.68-11.874-6.833-17.104c-6.537-6.87-13.865-6.9-17.225-7.3-24.056-1.75-60.14-1.75-60.14-1.75h-.076s-36.084 0-60.14 1.744c-3.36.4-10.686.435-17.225 7.3-5.15 5.23-6.83 17.102-6.83 17.102S14 79.186 14 93.13v13.073c0 13.945 1.72 27.89 1.72 27.89s1.676 11.874 6.83 17.103c6.54 6.864 15.13 6.646 18.956 7.366 13.753 1.322 58.448 1.73 58.448 1.73s36.122-.054 60.178-1.797c3.36-.402 10.688-.435 17.225-7.3 5.154-5.228 6.834-17.103 6.834-17.103s1.72-13.944 1.72-27.89v-13.07c0-13.945-1.712-27.89-1.712-27.89z" />
  </IconBase>
)

export default IconYoutube
