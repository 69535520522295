/* eslint-disable max-len */
import React from 'react'
import IconBase from 'assets/icon-base'

const IconSearch = props => (
  <IconBase {...props}>
    <path d="M130.1 121.55l70.14 70.14-8.55 8.54-70.15-70.14c-28.53 24.1-71.77 22.2-99.28-5.3C-6.7 95.82-7.25 49.38 21.07 21.07 49.36-7.25 95.8-6.7 124.8 22.27c27.5 27.5 29.4 70.75 5.3 99.28zm-12.84-4.3c23.6-23.58 23.14-62.3-1-86.43-24.15-24.15-62.86-24.6-86.45-1-23.58 23.58-23.13 62.3 1 86.43 24.16 24.15 62.87 24.6 86.46 1z" />
  </IconBase>
)

export default IconSearch
