import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Base from 'partials/_base'
import { Wrapper } from 'partials/grid'

import * as blocks from 'style/blocks'
import styles from './style'

export const CustomError = ({ code, message }) => (
  <Fragment>
    <Helmet>
      <title>{`${code} ${message}`}</title>
    </Helmet>
    <Wrapper>
      <div className={styles.root}>
        <h1 className={blocks.display.hidden}>{message}</h1>
        <span className={styles.statusCode}>{code}</span>
        <p className={styles.sell}>
          {message ? message : 'Whoa. We can’t find that.'}
        </p>
      </div>
    </Wrapper>
  </Fragment>
)

CustomError.defaultProps = {
  code: 404,
  message: 'Page Not Found'
}
CustomError.propTypes = {
  code: PropTypes.number,
  message: PropTypes.string
}

export default props => (
  <Base {...props}>
    <CustomError {...props} />
  </Base>
)
