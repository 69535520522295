import React from 'react'
import PropTypes from 'prop-types'

import styles from './column-style'

const validColumnWidths = PropTypes.oneOf([
  1,
  11 / 12,
  5 / 6,
  3 / 4,
  2 / 3,
  7 / 12,
  1 / 2,
  5 / 12,
  1 / 3,
  1 / 4,
  1 / 6,
  1 / 12
])

const Col = props => {
  const { children, tag, datacy = 'col' } = props
  const Tag = tag
  return (
    <Tag className={styles.root(props)} data-cy={datacy}>
      {children}
    </Tag>
  )
}

Col.propTypes = {
  children: PropTypes.node.isRequired,
  order: PropTypes.number,
  offsetXs: validColumnWidths,
  offsetSm: validColumnWidths,
  offsetMd: validColumnWidths,
  offsetLg: validColumnWidths,
  offsetXl: validColumnWidths,
  xs: validColumnWidths,
  sm: validColumnWidths,
  md: validColumnWidths,
  lg: validColumnWidths,
  xl: validColumnWidths,
  tag: PropTypes.string,
  customStyle: PropTypes.string
}
Col.defaultProps = {
  tag: 'div'
}

export default Col
