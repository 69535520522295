import { css } from 'emotion'
import * as blocks from 'style/blocks'
import { layout, type } from 'style/tokens'

const root = ({ index, font = type.stack.tertiary, theme }) =>
  css(
    blocks.type.wysiwyg(false, font),
    {
      marginBottom: layout.sizes.md,
      '& a': blocks.type.anchor(theme.colors.primary, theme.colors.primaryDark)
    },
    index === 0 && {
      '& > p:first-child::first-letter': {
        float: 'left',
        fontFamily: type.stack.primary,
        fontSize: '300%',
        fontWeight: type.weight.semibold,
        lineHeight: 0.85,
        marginRight: layout.sizes.xxs,
        marginTop: layout.sizes.xxs,
        textTransform: 'capitalize'
      }
    }
  )

export default {
  root
}
