import React from 'react'

export const LogoStylistReduced = props => (
  <svg viewBox="0 0 159 208" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M32.1 177.8c9.3 8.7 23.4 13.9 42.3 13.8 15.4 0 27.8-5 36.4-13.3a43.5 43.5 0 0 0 13.2-31.7c0-22.4-10.4-33.3-31.4-52.7-16.8-15.5-34.3-30-34.3-54 0-23 18-39.8 43.7-39.9 16.6 0 24.5 5.6 32.6 12.5l1.3 1.1L144 2h3.5l3.2 46.4-3 .5a118 118 0 0 0-22.3-32.3c-7.3-6.7-15-9.4-24-9.3-8.4 0-14.8 2.6-19 7A23.7 23.7 0 0 0 76 31c0 18 9.9 27.4 28 43.3 18.6 16.4 41.4 35.6 41.3 63.7 0 29.7-23.9 62-75.8 62C21.8 200 0 173.5 0 148.3c0-26 19.5-42.7 44-42.7 15.4 0 25.8 7.8 25.9 19.6 0 7.2-6.2 12.5-12.7 12.5-6 0-11.4-4.8-11.4-10.8.1-5 2.8-10.2 11.4-11.4l1.3-.1v-3l-1-.3a34.5 34.5 0 0 0-12.3-1.7c-7.7 0-14.5 3.2-19.3 9a37 37 0 0 0-7.5 23.9c0 13.6 4.4 25.8 13.7 34.5z" />
  </svg>
)

export default props => (
  <svg viewBox="0 0 157 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5 28.4c1.5 1.4 3.7 2.3 6.7 2.3a8 8 0 0 0 5.7-2.2 7 7 0 0 0 2-5c0-3.6-1.6-5.4-4.9-8.5-2.6-2.5-5.4-4.8-5.4-8.6 0-3.7 2.9-6.4 7-6.4 2.5 0 3.7.9 5 2l.2.2L22.6.3h.5l.5 7.4-.5.1a18.9 18.9 0 0 0-3.4-5.2 5.2 5.2 0 0 0-3.8-1.5 4 4 0 0 0-3 1.2c-.7.7-1 1.6-1 2.7 0 2.9 1.6 4.3 4.4 6.9 3 2.6 6.5 5.7 6.5 10.2 0 4.7-3.8 9.9-11.9 9.9C3.4 32 0 27.8 0 23.7 0 19.6 3 17 6.9 17c2.4 0 4 1.2 4 3.1 0 1.2-1 2-2 2-.9 0-1.7-.7-1.7-1.7 0-.8.4-1.6 1.8-1.8h.2V18H9c-.7-.3-1.1-.3-2-.3-1.1 0-2.2.5-3 1.4A6 6 0 0 0 3 22.9c0 2.2.7 4.2 2.1 5.5zm26.4-.7l-4.1.8-.2.7h11.5l.2-.7-3.8-.8L40 4l4 .8 2.9 7.3.4-.1.6-8.5H28.3L26 12h.4l5.4-7.2 4.6-.8-5 23.7zm27.9 0l3.4.7-.2.8H51.7l.2-.7 3.7-.8 2.1-9.7L53.4 5l-2.5-.9.2-.7h9.7l-.1.7-3.5 1 3.6 12L69 5l-2.5-.8.2-.7h7.6l-.2.7-3 .7-9.7 12.6-2.1 10.2zm33.9-7.2l-5.7 7.1-8.6.9 5-23.6 3.7-.7.2-.7H77.1l-.1.7 3.3.7-4.9 22.8-3.6.8-.2.7H91l2.6-8.6h-.4zm10.7-16h-2.6c-4.7 0-7.3 2-7.5 4.5v.3h.7v-.1c.6-.6 1.1-.7 1.6-.7.9 0 1.6.8 1.6 1.6 0 1-.8 1.8-1.9 1.8-1.2 0-2.5-.8-2.6-3 0-1.9.7-3.2 2-4 1.3-1 3.3-1.4 6-1.4h10.2l-.2.7-3.7.7-4.9 22.8 3.5.7-.2.8H95.1l.1-.7 3.8-.8 5-23.1zm30.6 7.5h.4l5.5-7.2 4.5-.8-5 23.7-4.1.8-.1.7h11.5l.1-.7-3.8-.8 5-23.7 4.1.8 2.8 7.3.4-.1.6-8.5h-19.6l-2.3 8.5zm-21 16.4c1.4 1.4 3.6 2.3 6.6 2.3a8 8 0 0 0 5.7-2.2 7 7 0 0 0 2-5c0-3.6-1.6-5.4-4.9-8.5-2.6-2.5-5.4-4.8-5.4-8.6 0-3.7 2.9-6.4 6.9-6.4 2.6 0 3.8.9 5.1 2l.2.2L131 .3h.5l.5 7.4-.5.1a18.9 18.9 0 0 0-3.4-5.2 5.2 5.2 0 0 0-3.8-1.5 4 4 0 0 0-3 1.2c-.7.7-1 1.6-1 2.7 0 2.9 1.6 4.3 4.4 6.9 3 2.6 6.5 5.7 6.5 10.2 0 4.7-3.8 9.9-12 9.9-7.4 0-10.8-4.2-10.8-8.3 0-4.1 3-6.8 6.9-6.8 2.4 0 4 1.2 4 3.1 0 1.2-1 2-2 2-.9 0-1.7-.7-1.7-1.7 0-.8.4-1.6 1.8-1.8h.2V18h-.2c-.7-.3-1.1-.3-2-.3-1.1 0-2.2.5-3 1.4a6 6 0 0 0-1.1 3.8c0 2.2.7 4.2 2.1 5.5z" />
  </svg>
)
