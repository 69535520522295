import React from 'react'

import TimeAgo from '_utility/time-ago'

import slugify from 'utils/slugify'
import { convertToUTC, isValidDate } from 'utils/date'
import { hasEntries } from 'utils/array'

import Anchor from 'partials/anchor'

import styles from './style'

const PostMeta = ({
  authors = [],
  date,
  customStyle = '',
  customLinkStyle = '',
  showTerm = true
}) => {
  const authorHasEntries = hasEntries(authors)

  if (!authorHasEntries) return null

  return (
    <dl css={styles.root({ customStyle })}>
      {authorHasEntries &&
        authors.map(_a => {
          return (
            <>
              <dt className={styles.term(showTerm)}>{'Posted by'}</dt>
              <dd className={styles.definition}>
                <Anchor
                  className={styles.link(customLinkStyle)}
                  url={`/author/${slugify(_a.slug)}`}
                >
                  {_a.name}
                </Anchor>
              </dd>
            </>
          )
        })}
      {isValidDate(date) && (
        <>
          <dt className={styles.term(false)}>{'Published'}</dt>
          <dd className={styles.definition}>
            <TimeAgo date={convertToUTC(date)} />
          </dd>
        </>
      )}
    </dl>
  )
}

export default PostMeta
