import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import CMPContext from '_utility/context-cmp'

import snippet from './_snippet'

const GoogleTagManager = ({ apikey, data }) => {
  const { purposeConsents } = useContext(CMPContext)

  // Store and/or access information on a device (Purpose 1)
  // Create a personalized ads profile (Purposes 3)
  // Measure ad performance (Purpose 7)
  // Measure content performance (Purpose 8)
  // Apply market research to generate audience insights (Purpose 9)
  // Develop and improve products (Purpose 10)
  const requiredConsents = [1, 3, 7, 8, 9, 10]
  const hasAllConsentsRequired = requiredConsents.every(consent =>
    Boolean(parseInt(purposeConsents[consent - 1]), 10)
  )

  if (!apikey) return null

  useEffect(
    () => {
      if (!hasAllConsentsRequired) return
      snippet(window, document, 'script', 'dataLayer', apikey)
      if (window.dataLayer && data) window.dataLayer.push(data)
    },
    [apikey, purposeConsents]
  )

  return null
}

GoogleTagManager.propTypes = {
  apikey: PropTypes.string,
  data: PropTypes.object
}

export default GoogleTagManager
