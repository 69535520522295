import React, { useEffect, useState } from 'react'
import { loadCSS } from 'fg-loadcss'

const defaultState = {
  purposeConsents: '',
  vendorConsents: '',
  tcString: '',
  consentSaved: false
}

const CMPContext = React.createContext({ ...defaultState })

const getConsent = () => {
  return new Promise(resolve => {
    let res = null
    window.OneTrust.getVendorConsentsRequestV2(e => (res = e))
    return resolve(res)
  })
}

export const CMPProvider = ({ children }) => {
  const [value, setValue] = useState({ ...defaultState })

  useEffect(() => {
    loadCSS(require(`public/cookiepro.css`))
    const interval = window.setInterval(() => {
      if (typeof window.OneTrust?.getVendorConsentsRequestV2 === 'function') {
        window.clearInterval(interval)

        window.OneTrust.OnConsentChanged(() => setConsents())
        setConsents()
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const setConsents = async () => {
    const consents = await getConsent()

    if (consents) {
      return setValue({
        ...value,
        consentSaved: true,
        purposeConsents: consents.purpose.consents,
        vendorConsents: consents.vendor.consents,
        tcString: consents.tcString
      })
    }
  }

  return <CMPContext.Provider value={value}>{children}</CMPContext.Provider>
}

export default CMPContext
