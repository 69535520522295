import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { isFrontPage } from 'utils/croissant'
import { shouldReload, getRegionPath } from './utils'

const RegionPreferenceContext = React.createContext({})

export const RegionPreferenceProvider = props => {
  const [selectedRegion, setSelectedRegion] = useState('')
  const [cookiesEnabled, setCookiesEnabled] = useState(true)

  const REGIONS = ['GB', 'US']

  useEffect(() => {
    setInitialPreference()
  }, [])

  const setInitialPreference = () => {
    // if no cookies, no need to set region
    if (!navigator.cookieEnabled) return setCookiesEnabled(false)

    // if user has preference, use it
    let region = Cookies.get('PREFERRED_REGION')

    // if no preference, set pref based on URL
    if (!region) {
      region = window.location.pathname === '/us' ? 'US' : 'GB'
    }
    setRegion(region)
  }

  const setRegion = (region, opts = { reload: shouldReload() }) => {
    // do nothing if changing to same region
    if (!region || selectedRegion === region) return

    setSelectedRegion(region)
    Cookies.set('PREFERRED_REGION', region, {
      expires: 365,
      sameSite: 'none',
      secure: true
    })
    // reload if requested, but only in front page
    if (opts.reload && isFrontPage({ _tapestry: props._tapestry }))
      goToRegion(region)
  }

  const getUserLocation = () =>
    props?._tapestry?.requestData?.headers?.['cloudfront-viewer-country']

  const goToRegion = region =>
    typeof window !== undefined &&
    (window.location.pathname = getRegionPath(region))

  return (
    <RegionPreferenceContext.Provider
      value={{
        selectedRegion,
        cookiesEnabled,
        regions: REGIONS,
        setRegion,
        getRegionPath,
        userLocation: getUserLocation()
      }}
    >
      {props.children}
    </RegionPreferenceContext.Provider>
  )
}

export default RegionPreferenceContext
