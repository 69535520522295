import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'emotion-theming'
import { stringify } from 'query-str-es5'

import withDialog from 'higher-order/with-dialog'
import IconSearch from 'assets/icon-search'
import IconClose from 'assets/icon-close'

import { trackEvent } from 'utils/analytics-trackers'
import triggerHotjarEvent from 'utils/hotjar-tracker'

import { display } from 'style/blocks'

import styles from './style'

const SiteSearch = ({
  handleCloseDialog,
  handleOpenDialog,
  handleClickOffDialog,
  dialogOpen,
  theme,
  swtcNavBar,
  ...props
}) => {
  const [value, setValue] = useState('')
  const searchInput = useRef(null)

  const handleSubmit = e => {
    // always prevent form submission, we'll handle this ourselves
    e.preventDefault()
    // prevent submission if input is empty
    if (!value.length) return searchInput.focus()
    trackEvent(
      'Pressed Enter On Search',
      'Site Search Navigation',
      'Site search'
    )
    // otherwise go to search page with query
    window.location = stringify({ q: value.trim() }, '/search')
  }

  const handleChange = e => setValue(e.currentTarget.value)

  return (
    <div
      className={styles.wrapper(props.customStyle)}
      data-cy="siteSearch"
      {...props}
    >
      <button
        className={styles.buttonOpen}
        onClick={e => {
          trackEvent('Open', 'Navigation', 'Site search')
          handleOpenDialog(e)
        }}
      >
        <span className={display.hidden}>{'Open site search'}</span>
        <IconSearch customStyle={styles.iconOpen} />
      </button>
      <div
        aria-label="Search Stylist"
        className={styles.root({ dialogOpen, swtcNavBar })}
        onTransitionEnd={() => searchInput.current.focus()}
        onClick={handleClickOffDialog}
        tabIndex={-1}
      >
        <div
          className={styles.container({ dialogOpen, swtcNavBar })}
          id="site-search"
        >
          <form
            autoComplete="off"
            className={styles.form}
            onSubmit={handleSubmit}
          >
            <label className={styles.label} htmlFor="search-input">
              <span className={display.hidden}>{'Search'}</span>
              <IconSearch customStyle={styles.icon} />
            </label>
            <input
              className={styles.input}
              id="search-input"
              onClick={() => triggerHotjarEvent('use_site_search')}
              onChange={handleChange}
              ref={searchInput}
              type="search"
              value={value}
              placeholder="Search stylist.co.uk for trends, topics and articles…"
            />
            <button className={styles.submit} type="submit">
              {'Go'}
            </button>
          </form>
          <button
            className={styles.close}
            type="button"
            onClick={handleCloseDialog}
            data-cy="closeSiteSearch"
          >
            <span className={display.hidden}>{'Close site search'}</span>
            <IconClose customStyle={styles.icon} />
          </button>
        </div>
      </div>
    </div>
  )
}

SiteSearch.propTypes = {
  handleOpenDialog: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleContainerTransitionEnd: PropTypes.func.isRequired
}

export default withDialog(withTheme(SiteSearch))
