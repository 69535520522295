/**
 * An extrapolating scale of values to use for any
 * layout properties
 */
const sizes = {
  xxs: '0.25rem', // ~ 4px
  xs: '0.5rem', // ~ 8px
  sm: '1rem', // ~ 16px
  md: '1.5rem', // ~ 24px
  lg: '2rem', // ~ 32px
  xl: '3rem', // ~ 48px
  xxl: '5rem' // ~ 80px
}

/**
 * Wrapper width sizes
 */
const trunk = {
  xs: '25rem', // ~ 400px
  sm: '43.75rem', // ~ 700px
  md: '56.25rem', // ~ 900px
  lg: '68.75rem' // ~ 1100px
}

/**
 * Basic media-query definitions
 * To be utilised with our utility media method
 * media(mq.smOnly, {})
 */
const mq = {
  xxSmOnly: '(max-width: 21.25em)', // ~ 340px
  smOnly: '(max-width: 34.313em)', // ~ 549px
  smUp: '(min-width: 34.375em)', // ~ 550px
  mdDown: '(max-width: 49.9375em)', // ~ 799px
  mdUp: '(min-width: 50em)', // ~ 800px
  lgDown: '(max-width: 62.4375em)', // ~ 999px
  lgUp: '(min-width: 62.5em)', // ~ 1000px
  xlDown: '(max-width: 74.9375em)', // ~ 1199px
  xlUp: '(min-width: 75em)', // ~ 1200px
  xxlUp: '(min-width: 87.5em)', // ~ 1400px
  leaderboardUp: '(min-width: 46.25rem)', // ~ 740px
  leaderboardDown: '(max-width: 46.1875rem)' // ~ 739px
}

/**
 * Grid values
 */
const grid = {
  gutter: 24
}

/**
 * Vertical spacing
 */
const vSpace = {
  xs: sizes.sm,
  sm: sizes.lg,
  md: sizes.xl,
  lg: sizes.xxl
}

/**
 * Global layout
 */
const site = {
  headerHeight: 64,
  headerMobileHeight: 51,
  leaderboardHeight: 100,
  billboardHeight: 260
}

const stylistLiveSite = {
  headerHeight: 100,
  headerMobileHeight: 51,
  leaderboardHeight: 100,
  billboardHeight: 260
}

export default {
  trunk,
  sizes,
  mq,
  grid,
  vSpace,
  site,
  stylistLiveSite
}
