import { css } from 'emotion'
import { layout } from 'style/tokens'

const base = css`
  color: #E2E2E2;
  border-top: 0px;
  @media${layout.mq.mdDown} {
    margin: 0 16px;
  }
`

export default {
  base
}
