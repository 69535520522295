import { css } from 'emotion'
import { layout, type } from 'style/tokens'

const H2 = css`
  h2 {
    font-size: 32px;
    line-height: 35.2px;
    letter-spacing: 3.8px;
    font-weight: ${type.weight.bold};
    text-align: center;
    text-transform: uppercase;
  }
`

const H3 = css`
  h3 {
    font-size: 64px;
    letter-spacing: 1.2px;
    line-height: 64px;
    font-weight: normal;
    font-weight: ${type.weight.bold};
  }
`
const P = css`
  p {
    font-size: 19px;
    letter-spacing: 0.3px;
    line-height: 26.6px;
    font-weight: normal;
    text-align: center;
  }
`
const Button = css`
  a {
    width: max-content;
    padding: 16px 24px;
    font-weight: normal;
    font-size: 13px;
    border-color: white;
    letter-spacing: 1.5px;
    line-height: 14.3px;
    background: white;
    &:hover {
      color: white;
    }
  }
`

const ticket = backgroundColor => css`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: white; 
  min-height: 403px;
  width: 100%;
  background: ${backgroundColor};
  position: relative;
  @media(min-width: 900px) {
    &::after,
    &::before {
      content: '';
      height: 200px;
      width: 66px;
      position: absolute;
      background: currentColor;
    }
    &::before {
      top: 25%;
      left: -1px;
      border-radius: 0 10em 10em 0;
    }
    &::after {
      top: 25%;
      right: -1px;
      border-radius: 10em 0 0 10em;
    }
  }
  ${H2}
  ${H3}
  ${P}
  ${Button}
  * {
    max-width: 600px;
  }
  @media${layout.mq.smOnly} {
    padding: 0 16px;
  }
`

export default {
  ticket
}
