import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'emotion-theming'

import { siteData } from 'root/config'
import Anchor from 'partials/anchor'
import SocialLinks from 'partials/social-links'
import HomepageAnchor from 'higher-order/homepage-anchor'

import LogoStylist from 'assets/logo-stylist'
import LogoTheStylistGroup from 'assets/logo-the-stylist-group'
import LogoIpso from 'assets/logo-ipso'

import MenuInline from './menu-inline'

import styles from './style'

export const SiteFooter = ({ theme }) => (
  <footer className={styles.root({ theme })} role="contentinfo">
    <MenuInline menu={siteData.menus.channels} />
    <MenuInline menu={siteData.menus.legal} hasCookiePreferences={true} />
    <SocialLinks />
    <section className={styles.main}>
      <div className={styles.sectionLeft}>
        <MenuInline
          customStyle={styles.menuExternal}
          menu={siteData.menus.corporate}
          variant="reduced"
        />
      </div>
      <div className={styles.sectionCenter}>
        <HomepageAnchor className={styles.linkStandard}>
          <LogoStylist className={styles.logoStandard} />
        </HomepageAnchor>
        <p className={styles.subtext}>
          {`Copyright © 2010–${new Date().getFullYear()} ${siteData.site.name}`}
        </p>
      </div>
      <div className={styles.sectionRight}>
        <Anchor className={styles.linkRegulator} url="https://www.ipso.co.uk">
          <LogoIpso className={styles.logoRegulator} />
        </Anchor>
        <Anchor
          className={styles.linkCorporate}
          url="https://www.thestylistgroup.com"
        >
          <LogoTheStylistGroup className={styles.logoCorporate} />
        </Anchor>
      </div>
    </section>
  </footer>
)

SiteFooter.propTypes = {
  series: PropTypes.object
}

export default withTheme(SiteFooter)
