import React, { useState } from 'react'
import parse from 'html-react-parser'
import { Vladivostok, Box } from 'design-system'

import Banner from 'partials/banner'
import Button from 'partials/button'
import Image from 'partials/image'
import IconLoading from 'partials/icon-loading'

import useScrollDistance from 'hooks/use-scroll-distance'
import useOrientation from 'hooks/use-orientation'
import useWindowHeight from 'hooks/use-window-height'

import { trackEvent } from 'utils/analytics-trackers'
import TelemetrySingleSignUp from '_utility/telemetry-single-sign-up'

import styles from './style'

const PopupBanner = ({
  backgroundColour,
  buttonColour,
  buttonLink,
  buttonText,
  buttonTextColour,
  externalLink,
  text,
  textColour,
  logo,
  id,
  title,
  emailCapture,
  telemeteryId,
  emailSuccessMessage,
  emailRepeatMessage,
  forceLoad = false
}) => {
  const shouldShow = () => {
    if (forceLoad) return true

    const orientation = useOrientation()
    const threshold =
      orientation === 'landscape'
        ? useWindowHeight() / 2 || 450
        : useWindowHeight() || 667

    return useScrollDistance().scrollY > threshold
  }
  const [close, setCloseBanner] = useState(false)

  const bannerCampaign = title.replace(/\s+/g, '_').toLowerCase()

  return (
    <Banner
      shouldShow={shouldShow()}
      customStyle={styles.root(backgroundColour)}
      bannerId={id}
      visible={close}
      forceLoad={forceLoad}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-evenly"
        height="100%"
        alignItems="center"
        width="95vw"
        color="#000"
      >
        <Box className={styles.logo}>
          <Image image={logo} crop="landscape" type="static" />
        </Box>
        <Box
          width={['80%', '60%']}
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          mb="5px"
        >
          <Vladivostok
            color={textColour}
            textAlign="center"
            fontSize={['xs', 'sm']}
            fontWeight="light"
            pb="10px"
          >
            <Box dangerouslySetInnerHTML={{ __html: text }} />
          </Vladivostok>
        </Box>
        {emailCapture ? (
          <TelemetrySingleSignUp
            campaign={bannerCampaign}
            internalCTA={`cms_email_signup_popup_${id}`}
            source="stylist"
            verticalId={telemeteryId}
          >
            {({
              email,
              handleFormSubmit,
              handleInputChange,
              status,
              message
            }) => (
              <Box
                is="form"
                onSubmit={handleFormSubmit}
                width={status !== 'ready' ? '60%' : '300px'}
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                textAlign="center"
                background="none"
                mb={status !== 'ready' && '20px'}
              >
                {status !== 'ready' && (
                  <p className={styles.message}>
                    {(status === 'success' && parse(emailSuccessMessage)) ||
                      (status === 'repeat' && parse(emailRepeatMessage)) ||
                      (status === 'fail' && message)}
                  </p>
                )}
                {(status === 'ready' || status === 'fail') && (
                  <>
                    <Vladivostok
                      is="input"
                      name="email"
                      onChange={handleInputChange}
                      required
                      type="email"
                      value={email}
                      placeholder={'Enter your email address here'}
                      border="transparent"
                      borderRadius={0}
                      fontSize="xs"
                      fontWeight="normal"
                      color="#001338"
                      height={35}
                      width="100%"
                      p={3}
                    />
                    {status === 'loading' && <IconLoading color={'white'} />}
                    <Button
                      type="submit"
                      small
                      width="100%"
                      className={styles.submitButton(
                        buttonTextColour,
                        buttonColour
                      )}
                    >
                      {'SIGN ME UP'}
                    </Button>
                  </>
                )}
              </Box>
            )}
          </TelemetrySingleSignUp>
        ) : (
          <Button
            onClick={() => {
              trackEvent(
                `Clicked on sign me up button on SWTC banner popup`,
                'SWTC banner popup',
                'SWTC banner popup'
              )
              externalLink
                ? window.open(buttonLink) && setCloseBanner(true)
                : (window.location = buttonLink)
            }}
            className={styles.button(buttonTextColour, buttonColour)}
            small
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </Banner>
  )
}

export default PopupBanner
