import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useWindowWidth from '../../custom-hooks/useWindowWidth'
import useWindowHeight from '../../custom-hooks/use-window-height'

const RestrictBySize = ({
  children,
  maxWidth = 90210,
  minWidth = 1,
  maxHeight = 85340,
  minHeight = 1,
  method = 'and'
}) => {
  const [shouldRender, setShouldRender] = useState(false)

  const viewportWidth = useWindowWidth()
  const viewportHeight = useWindowHeight()

  useEffect(
    () => {
      setShouldRender(isRenderableSize())
    },
    [viewportWidth, viewportHeight]
  )

  const isRenderableSize = () => {
    if (method === 'and')
      return Boolean(
        viewportWidth >= minWidth &&
          viewportWidth <= maxWidth &&
          viewportHeight >= minHeight &&
          viewportHeight <= maxHeight
      )

    if (method === 'or')
      return Boolean(
        (viewportWidth >= minWidth && viewportWidth <= maxWidth) ||
          (viewportHeight >= minHeight && viewportHeight <= maxHeight)
      )
  }

  if (shouldRender) return children
  return null
}

RestrictBySize.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  minHeight: PropTypes.number,
  method: PropTypes.oneOf(['and', 'or'])
}

export default RestrictBySize
