import React from 'react'

const SeriesContext = React.createContext({ series: {} })

export const getSeries = props =>
  props.taxonomy === 'series' ? props : props.acf?.series

export const SeriesProvider = ({ children, ...props }) => (
  <SeriesContext.Provider value={{ series: getSeries(props) || {} }}>
    {children}
  </SeriesContext.Provider>
)

export default SeriesContext
