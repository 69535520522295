import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'

import SeriesContext from '_utility/context-series'
import AdvertSlot from 'partials/advert-slot'

import { isLongform } from 'utils/croissant'
import { layout } from 'style/tokens'

let index = 0

class FondueSlot extends PureComponent {
  render() {
    const { type, pageData, series } = this.props
    // bump the advert count to allow targeting position
    index += 1

    if (type === 'mpu') {
      return (
        <AdvertSlot
          style={{
            container: css`
              @media ${layout.mq.smOnly} {
                margin-left: calc(-50vw + 50%);
                width: 100vw;
              }
            `
          }}
          context="in-content"
          sizes={[
            {
              placeholder: [1, 1],
              slot: [[300, 250], [300, 600], [9, 16], [1, 1]]
            },
            {
              placeholder: [1, 1],
              slot: [[300, 250], [1, 1]],
              viewport: 550
            },
            series.name
              ? {
                  placeholder: [970, 250],
                  slot: [[970, 250], [10, 1]],
                  viewport: 980
                }
              : {
                  placeholder: [300, 250],
                  slot: isLongform(pageData.type)
                    ? [[300, 250], [970, 250]]
                    : [300, 250],
                  viewport: 980
                }
          ]}
          targeting={{ pos: `content_${index}` }}
          id={`content_${index}`}
        />
      )
    }

    if (type === 'mobile-mpu') {
      return (
        <AdvertSlot
          style={{
            container: css`
              @media ${layout.mq.smOnly} {
                margin-bottom: ${layout.sizes.md};
                margin-left: calc(-50vw + 50%);
                margin-top: ${layout.sizes.md};
                width: 100vw;
              }
              @media ${layout.mq.smUp} {
                display: none;
              }
            `
          }}
          context="in-content"
          sizes={[
            {
              placeholder: [1, 1],
              slot: [[300, 250], [1, 1]]
            },
            { slot: [0, 0], viewport: 550 }
          ]}
          targeting={{ pos: `content_${index}` }}
          id={`content_${index}`}
        />
      )
    }

    return null
  }
}

FondueSlot.propTypes = {
  type: PropTypes.string
}

export default props => (
  <SeriesContext.Consumer>
    {({ series }) => <FondueSlot {...props} series={series} />}
  </SeriesContext.Consumer>
)
