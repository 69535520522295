import { parse } from 'query-str-es5'
import parseuri from 'parseUri'
import { emails } from 'root/config'
import { POST, GET, api } from './api'

/**
 * pass referrer data to API and
 * fall back to contextual values
 */
export const getSessionData = params => {
  const uri = parseuri(window.location)
  const search = parse(uri.query)

  const data = {
    campaign: params.campaign,
    internalCta: params.internalCta,
    medium: params.medium || 'web',
    pageUrl: `${uri.protocol}://${uri.host}${uri.path}`,
    referrerUrl: document.referrer,
    source: params.source,
    postId: getPostId(uri.path)
  }

  const utmMapping = {
    campaign: 'campaign',
    cta: 'internalCTA',
    medium: 'medium',
    source: 'source'
  }

  // override data obj with url query params, if any exist
  Object.keys(search).forEach(key => {
    if (key.indexOf('utm') > -1 || key.indexOf('itm') > -1) {
      data[utmMapping[key.slice(4)]] = search[key]
    }
  })

  return data
}

// check for secret ID validity
export const isValidSID = sid => {
  if (!sid) return false
  return !RegExp(/[^a-zA-Z0-9]/).test(sid)
}

// return subscription messaging from status code
export const getMessage = status => {
  switch (status) {
    case '200':
    case 200:
      return emails.messaging.success.generic
    case '409':
    case 409:
      return emails.messaging.error['user-registered']
    case 'unsubscribe':
      return emails.messaging.error['unsubscribe']
    case 'critical':
      return emails.messaging.error['critical']
    default:
      return emails.messaging.error.generic
  }
}

export const isRepeatSignup = statusCode =>
  statusCode === 409 || statusCode === '409'

export const getPostId = path => {
  const regex = /\/.+?\/.+?\/(\d+)$/
  const match = regex.exec(path)
  const postId = match ? parseInt(match[match.length - 1], 10) : 0
  return postId
}

const TEAM_ID = 10

export { POST, GET, api, TEAM_ID }
