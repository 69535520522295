import React from 'react'
import PropTypes from 'prop-types'

import Anchor from 'partials/anchor'
import Image from 'partials/image'
import SponsorKeyline from 'partials/sponsor-keyline'
import { layout } from 'style/tokens'
import { hasEntries } from 'utils/array'

import IconPlay from 'assets/icon-play'

import styles from './style'

const TrendingCard = ({ equalCardSizes = false, ...props }) => {
  const heroImage = props.acf?.hero_images?.[0]
  const shortHeadline = props.acf?.short_headline
  const sponsorName = props.acf?.sponsor_name
  const sponsorLabel = props.acf?.sponsor_label
  const seriesBadge = props.acf?.series?.series_badge?.url
  const isPaid =
    props?.onExtraHubPage !== undefined
      ? false
      : hasEntries(props.acf?.package_ids)
  const isVideoPost = props?.acf?.video_url

  return (
    <article className={styles.root(equalCardSizes)}>
      <Anchor
        aria-label={shortHeadline}
        className={styles.blockLink(equalCardSizes)}
        url={props.link}
      />
      <div className={styles.image(equalCardSizes)}>
        {heroImage && (
          <Image
            badge={seriesBadge}
            crop="landscape"
            image={heroImage}
            imageSizes={[550, 264]}
            sizes={`${layout.mq.smUp} 50vw, ${layout.mq.lgUp} 264px`}
            isPaid={isPaid}
            preload
          />
        )}
        {props?.onExtraHubPage &&
          isVideoPost && (
            <div className={styles.playButton}>
              <IconPlay />
            </div>
          )}
      </div>
      <div className={styles.content(equalCardSizes)}>
        {shortHeadline && (
          <h3
            className={styles.title(equalCardSizes)}
            dangerouslySetInnerHTML={{
              __html: shortHeadline
            }}
          />
        )}
        {sponsorName && (
          <SponsorKeyline brand={sponsorName} label={sponsorLabel} />
        )}
      </div>
    </article>
  )
}

TrendingCard.propTypes = {
  acf: PropTypes.shape({
    category: PropTypes.object,
    hero_images: PropTypes.arrayOf(PropTypes.object),
    short_headline: PropTypes.string
  }),
  link: PropTypes.string,
  title: PropTypes.shape({
    rendered: PropTypes.string
  })
}

export default TrendingCard
