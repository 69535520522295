import { css } from 'emotion'
import { type } from 'style/tokens'

const root = backgroundColour => css`
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  background-color: ${backgroundColour};

  @media (max-width: 1024px) and (orientation: landscape) {
    // 1024px height of a mini ipad
    display: none;
  }
`

const buttonCSS = `
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: ${type.stack.quinary};
  font-size: 12px;
  font-weight: bold;
  padding: 0.5rem 4rem;
  border: none;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
`

const button = (buttonTextColour, buttonColour) => css`
  color: ${buttonTextColour};
  background-color: ${buttonColour};
  ${buttonCSS};
`
const submitButton = (buttonTextColour, buttonColour) => css`
  background-color: ${buttonColour};
  color: ${buttonTextColour};
  width: 100%;
  margin-top: 16px !important;
  ${buttonCSS};
`

const headline = css`
  font-family: ${type.stack.quinary};
  color: #000;
  font-weight: ${type.weight.extrabold};
`

const logo = css`
  max-width: 200px;
  margin: 20px;
`
const message = css`
  margin: 0 auto 32px auto;
  font-size: 14px !important;
  a {
    color: currentColor;
  }
`

export default {
  root,
  button,
  headline,
  logo,
  submitButton,
  message
}
