import React from 'react'
import PropTypes from 'prop-types'

import getSponsorLabel from 'utils/select-sponsor-info'
import styles from './style'

const SponsorKeyline = ({ label, brand, customStyle }) => (
  <dl
    css={`
      ${styles.root} ${customStyle};
    `}
  >
    <dt className={styles.term}>{getSponsorLabel(label)}</dt>
    <dd className={styles.definition}>{brand}</dd>
  </dl>
)

SponsorKeyline.propTypes = {
  brand: PropTypes.string.isRequired
}

export default SponsorKeyline
