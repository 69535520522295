/**
 *
 * Widget Officer
 * Maps the content's widgets to components for rendering
 *
 *              ________________
 *              \      __      /         __
 *               \_____()_____/         /  )
 *               '============`        /  /
 *                #---\  /---#        /  /
 *               (# @\| |/@  #)      /  /
 *                \   (_)   /       /  /
 *                |\ '---` /|      /  /
 *        _______/ \\_____// \____/ o_|
 *       /       \  /     \  /   / o_|
 *      / |           o|        / o_| \
 *     /  |  _____     |       / /   \ \
 *    /   |  |===|    o|      / /\    \ \
 *   |    |   \@/      |     / /  \    \ \
 *   |    |___________o|__/----)   \    \/
 *   |    '              ||  --)    \     |
 *   |___________________||  --)     \    /
 *        |           o|   ''''   |   \__/
 *        |            |          |
 *
 */

import React from 'react'
import PropTypes from 'prop-types'

import { getTheme } from 'design-system'

import { hasEntries } from 'utils/array'
import { isParagraph } from 'utils/component'
import truncateParagraph from 'utils/truncate-paragraph'

import SectionWrapper from 'partials/section-wrapper'
import VerticalGradient from 'partials/vertical-gradient'

import { getView, getSalesPageView } from './helpers'

import styles from './style'

const GenerateWidget = ({ widget, index, postType, theme, ...props }) => {
  const {
    acf_fc_layout,
    hide_widget_from_page,
    fondueId,
    publish_to_apple_news,
    ...rest
  } = widget

  if (hide_widget_from_page) return null

  const Widget =
    postType === 'salespage'
      ? getSalesPageView({ acf_fc_layout })
      : getView({ acf_fc_layout })

  return (
    <SectionWrapper
      customStyle={styles.widget(widget, postType)}
      data-widget={acf_fc_layout}
      key={`${acf_fc_layout}-${index}`}
    >
      <Widget
        {...rest}
        index={index}
        postType={postType}
        setReference={props.setReference}
        theme={theme}
      />
    </SectionWrapper>
  )
}

const WidgetOfficer = ({
  customStyle,
  postType,
  widgets,
  truncateContent = false,
  theme = getTheme(),
  ...rest
}) => {
  // catch missing widgets
  if (!hasEntries(widgets)) return null

  let formattedWidgets = [...widgets]
  let restOfWidgets = []

  if (truncateContent && isParagraph(widgets[0].acf_fc_layout)) {
    const firstFormattedWidget = {
      ...formattedWidgets[0],
      paragraph: truncateParagraph(widgets[0].paragraph)
    }

    restOfWidgets = widgets.slice(1)
    formattedWidgets = [firstFormattedWidget]
  }

  return (
    <div css={styles.root(customStyle)}>
      <VerticalGradient showGradient={truncateContent}>
        {hasEntries(formattedWidgets) &&
          formattedWidgets.map((widget, index) => (
            <GenerateWidget
              widget={widget}
              index={index}
              postType={postType}
              key={`widget-${index}`}
              theme={theme}
              {...rest}
            />
          ))}
      </VerticalGradient>
      <div className="paywall" style={{ display: 'none' }}>
        {hasEntries(restOfWidgets) &&
          restOfWidgets.map((widget, index) => (
            <GenerateWidget
              widget={widget}
              index={index}
              postType={postType}
              key={`widget-${index}`}
              theme={theme}
              {...rest}
            />
          ))}
      </div>
    </div>
  )
}

WidgetOfficer.propTypes = {
  postType: PropTypes.string,
  widgets: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        acf_fc_layout: PropTypes.string.isRequired
      })
    )
  ]).isRequired
}

export default WidgetOfficer
