/* eslint-disable max-len */
import React from 'react'
import IconBase from 'assets/icon-base'

const IconInstagram = props => (
  <IconBase {...props}>
    <path d="M100 20c21.7 0 24.4 0 33 .5 8.5.4 14.3 1.8 19.4 3.7 5.2 2 9.7 4.8 14 9.3 4.6 4.4 7.3 9 9.4 14 2 5.2 3.3 11 3.7 19.5.4 8.6.4 11.3.4 33s0 24.5-.5 33c-.4 8.5-1.8 14.3-3.7 19.4-2 5.3-4.8 9.7-9.3 14.2-4.4 4.4-9 7.2-14 9.2-5.2 2-11 3.3-19.6 3.7-8.6.4-11.3.5-33 .5s-24.5 0-33-.5c-8.5-.4-14.3-1.7-19.4-3.7-5.3-2-9.7-4.8-14.2-9.2-4.4-4.5-7.2-9-9.2-14.2-2-5-3.3-11-3.7-19.4-.4-8.5-.5-11.3-.5-33s0-24.4.5-33c.4-8.5 1.7-14.3 3.7-19.4 2-5.2 4.8-9.7 9.2-14 4.5-4.6 9-7.3 14.2-9.4 5-2 11-3.3 19.4-3.7 8.5-.4 11.3-.4 33-.4zm0 14.5c-21.4 0-24 0-32.3.4-7.8.3-12 1.6-15 2.7-3.6 1.4-6.3 3.2-9 6-3 2.8-4.6 5.4-6 9.2-1.2 2.7-2.5 7-2.8 14.7-.5 8.4-.6 11-.6 32.3 0 21.4 0 24 .5 32.3.2 7.8 1.5 12 2.6 15 1.5 3.6 3.2 6.3 6 9 2.8 3 5.5 4.6 9.2 6 2.8 1.2 7 2.5 15 2.8 8.3.5 10.8.6 32.2.6 21.3 0 24 0 32.3-.5 7.8-.2 12-1.5 14.8-2.6 4-1.5 6.5-3.2 9.3-6 2.8-2.8 4.6-5.5 6-9.2 1-2.8 2.4-7 2.8-15 .5-8.3.5-10.8.5-32.2 0-21.3 0-24-.4-32.3-.3-7.8-1.6-12-2.7-14.8-1.4-4-3.2-6.5-6-9.3-2.8-2.8-5.4-4.6-9.2-6-2.7-1-7-2.4-14.7-2.8-8.4-.5-11-.5-32.3-.5z" />
    <path d="M100 59c22.7 0 41 18.3 41 41s-18.3 41-41 41-41-18.3-41-41 18.3-41 41-41zm0 67.7c14.7 0 26.6-12 26.6-26.7s-12-26.6-26.6-26.6c-14.7 0-26.7 12-26.7 26.6 0 14.7 12 26.7 26.7 26.7zM152.3 57.3c0 5.3-4.3 9.6-9.6 9.6s-9.6-4.4-9.6-9.7c0-5.3 4.4-9.6 9.7-9.6 5.3 0 9.6 4.3 9.6 9.6" />
  </IconBase>
)

export default IconInstagram
