import React, { Component } from 'react'
import { bugsnag } from '_utility/error-boundary'

import findTabbableDescendants from './helpers/tabbable'
import { openDialog, closeDialog, transition } from './helpers/state-changes'

const TAB_KEY = 9
const ESCAPE_KEY = 27

export const blockPanelEvents = event => event.stopPropagation()

const withDialog = ComposedComponent => {
  class WithDialogContainer extends Component {
    constructor() {
      super()

      this.state = {
        dialogOpen: false,
        dialogClosing: false
      }

      // element references
      this.tabbable = null
      this.trigger = null
      this.htmlNode = null

      // bind instance methods

      /* istanbul ignore next */
      if (typeof window !== 'undefined') {
        // <html> will be used to stop page scrolling when the dialog
        // is open, using 'overflow: hidden;'
        this.htmlNode = document.documentElement
      }
    }

    componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKeyDown)
      this.setHtmlNodeOverflow()
    }

    setHtmlNodeOverflow = style => {
      this.htmlNode.style.overflow = style || ''
    }

    handleOpenDialog = event => {
      if (typeof event.preventDefault !== 'undefined') {
        event.preventDefault()
      }

      document.addEventListener('keydown', this.handleKeyDown)

      this.storeTriggerReference(
        typeof event.currentTarget !== 'undefined' ? event.currentTarget : event
      )
      this.setState(openDialog)
      this.setHtmlNodeOverflow('hidden')
    }

    handleClickOffDialog = event => {
      if (event.currentTarget === event.target) {
        this.handleCloseDialog(event)
      }
    }

    handleCloseDialog = event => {
      event.preventDefault()

      document.removeEventListener('keydown', this.handleKeyDown)

      this.setState(transition, () => this.setState(closeDialog))

      this.setHtmlNodeOverflow()
      try {
        this.trigger.focus()
      } catch (err) {
        bugsnag.notify(err)
      }
    }

    handleKeyDown = event => {
      if (event.keyCode === TAB_KEY) {
        if (this.tabbable === null || this.tabbable.length === 0) {
          return
        }
        this.scopeTab(event)
      } else if (event.keyCode === ESCAPE_KEY) {
        this.handleCloseDialog(event)
      }
    }

    findTabbable = event => {
      if (this.state.dialogOpen) {
        const { currentTarget } = event
        this.tabbable = findTabbableDescendants(currentTarget)
        currentTarget.focus()
      }
    }

    scopeTab(event) {
      const tabbableCount = this.tabbable.length
      const finalTabbable = this.tabbable[
        event.shiftKey ? 0 : tabbableCount - 1
      ]
      const leavingFinalTabbable = finalTabbable === document.activeElement

      if (!leavingFinalTabbable) {
        return
      }

      event.preventDefault()
      const target = this.tabbable[event.shiftKey ? tabbableCount - 1 : 0]
      target.focus()
    }

    storeTriggerReference = element => {
      this.trigger = element
    }

    render() {
      return (
        <ComposedComponent
          {...this.props}
          {...this.state}
          handleOpenDialog={this.handleOpenDialog}
          handleClickOffDialog={this.handleClickOffDialog}
          handleCloseDialog={this.handleCloseDialog}
          handleContainerTransitionEnd={this.findTabbable}
          handlePanelTransitionEnd={blockPanelEvents}
        />
      )
    }
  }

  return WithDialogContainer
}

export default withDialog
