/* eslint-disable max-len */
import React from 'react'

const IconPadlock = () => (
  <svg
    id="Padlock_Locked_small_"
    data-name="Padlock Locked (small)"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="33.218"
    viewBox="0 0 25 33.218"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_1866"
          data-name="Rectangle 1866"
          width="25"
          height="33.217"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Group_1177" data-name="Group 1177" clipPath="url(#clip-path)">
      <path
        id="Path_606"
        data-name="Path 606"
        d="M23.8,12.846a4.072,4.072,0,0,0-2.168-1.136V9.186A9.2,9.2,0,0,0,12.443,0h0A9.2,9.2,0,0,0,3.253,9.186v2.547A4.108,4.108,0,0,0,0,15.752V29.108a4.109,4.109,0,0,0,4.11,4.11H20.891A4.115,4.115,0,0,0,25,29.108V15.752a4.083,4.083,0,0,0-1.2-2.905M12.441,2.055h0a7.141,7.141,0,0,1,7.131,7.131v2.457H5.308V9.186a7.141,7.141,0,0,1,7.133-7.131m1.326,20.374v2.843a1.438,1.438,0,0,1-2.877,0V22.429a2.354,2.354,0,0,1-.959-1.9,2.4,2.4,0,0,1,4.795,0,2.355,2.355,0,0,1-.959,1.9"
        transform="translate(0 0)"
      />
    </g>
  </svg>
)

export default IconPadlock
