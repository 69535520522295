import { css } from 'emotion'
import { layout } from 'style/tokens'

const base = `
  width: 100%;
  margin: 0;
`

const wrapper = css`
  @media${layout.mq.smOnly} {
    padding-left: 0;
    padding-right: 0;
  }
`

export default {
  wrapper,
  base
}
