import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Box } from 'design-system'

import { bugsnag } from '_utility/error-boundary'
import Button from 'partials/button'
import InView from 'partials/in-view'
import IconLoading from 'partials/icon-loading'

import { GET } from 'utils/telemetry'
import { isSupported } from 'utils/feature-detection'
import { dedupeArray, hasEntries } from 'utils/array'
import croissantEndpoint from 'utils/croissant/construct-endpoint'

class ListPostsLoader extends Component {
  state = {
    allPostsFetched: false,
    page: 1,
    params: this.props.params,
    posts: this.props.posts,
    status: this.props.fetchOnMount ? 'loading' : 'mounting'
  }

  componentDidMount = () => {
    const { fetchOnMount } = this.props

    this.setState({
      loadAutomatically: isSupported('intersection'),
      status: fetchOnMount ? 'loading' : 'ready'
    })
    // kick off a fetch event to get first round of posts
    if (fetchOnMount) this.loadPosts()
  }

  loadPosts = () => {
    if (this.state.page === 2) {
      this.setState({ status: 'complete' })

      return null
    }

    this.setState({ status: 'loading' })

    const endpoint = croissantEndpoint(
      this.props.baseApi,
      this.state.params,
      false
    )

    GET(endpoint)
      .then(this.handleSuccess)
      .catch(this.handleFail)
  }

  updateAPIParams = params => {
    if (params.hasOwnProperty('offset')) {
      return {
        ...params,
        offset: params.offset + params.per_page
      }
    }
    return {
      ...params,
      page: params.page + 1
    }
  }

  handleSuccess = resp => {
    // we're always expecting a list of posts, never singular data
    if (!Array.isArray(resp)) return

    this.setState(({ posts, page, params }) => ({
      page: page + 1,
      params: this.updateAPIParams(params),
      posts: dedupeArray([...posts, ...resp], 'id'),
      status:
        resp.length < params.per_page || page + 1 == this.props.maximumPageCount
          ? 'complete'
          : 'ready'
    }))
  }
  handleFail = err => {
    this.setState({ status: 'error' })
    bugsnag.notify(err)
  }

  render() {
    const { children, minimumPostCount, fetchOnMount } = this.props
    const { posts, page, status, loadAutomatically } = this.state

    if (!fetchOnMount && !hasEntries(posts)) return null

    if (posts.length < minimumPostCount && status !== 'loading') {
      return <div data-cy="postsContainer">{children({ posts, status })}</div>
    }

    return (
      <div data-cy="postsContainer">
        {children({ posts, page, status })}
        {status === 'loading' && (
          <Box display="flex" height={80} overflow="hidden">
            <IconLoading color="primary" size={8} />
          </Box>
        )}
        {status === 'ready' &&
          loadAutomatically && (
            <InView
              observer={{ rootMargin: '670px 0px' }}
              onEnterView={this.loadPosts}
            />
          )}
        {status === 'ready' &&
          !loadAutomatically && (
            <Button
              disabled={status === 'loading'}
              fullWidth
              onClick={this.loadPosts}
            >
              {'Load more posts'}
            </Button>
          )}
      </div>
    )
  }
}

ListPostsLoader.defaultProps = {
  baseApi: 'posts',
  fetchOnMount: false,
  maximumPageCount: 10,
  minimumPostCount: 24,
  params: {
    per_page: 24
  },
  posts: []
}
ListPostsLoader.propTypes = {
  baseApi: PropTypes.string,
  fetchOnMount: PropTypes.bool,
  maximumPageCount: PropTypes.number,
  minimumPostCount: PropTypes.number,
  params: PropTypes.shape({
    offset: PropTypes.number,
    page: PropTypes.number,
    per_page: PropTypes.number
  }),
  posts: PropTypes.array
}

export default ListPostsLoader
