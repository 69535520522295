import { css } from 'emotion'
import { anim, color, layout } from 'style/tokens'
import rem from 'style/utils/rem'

const root = props =>
  css(
    {
      color: 'currentColor',
      fontSize: 0
    },
    props.customStyle
  )

const item = css({
  display: 'inline-block',
  fontSize: rem(16)
})

const link = css({
  alignItems: 'center',
  color: 'inherit',
  display: 'flex',
  height: layout.sizes.lg,
  justifyContent: 'center',
  transition: `color ${anim.duration.instant} ${anim.easing.initial}`,
  width: layout.sizes.xl,
  '&:hover,&:focus': {
    color: color.core.secondary
  }
})

const linkMyStylist = css({
  alignItems: 'center',
  color: 'inherit',
  display: 'flex',
  height: layout.sizes.lg,
  justifyContent: 'center',
  transition: `color ${anim.duration.instant} ${anim.easing.initial}`,
  width: layout.sizes.xl,
  '&:hover,&:focus': {
    color: color.core.primary
  }
})

const icon = css({
  height: layout.sizes.md,
  width: layout.sizes.md
})

export default {
  root,
  item,
  link,
  icon,
  linkMyStylist
}
