import React from 'react'
import PropTypes from 'prop-types'

import { BackgroundImage } from 'design-system'
import rem from 'style/utils/rem'

const calcRatioValue = ratio => `calc(${ratio} * 4%)`

const Badge = ({ containerRatio, ratio, url, ...props }) => {
  {
    /*
      calculate the top/right position of the badge in relation to its container
      we need to know the ratio to get the correct percentage value

           1w
     ┏━━━━━━━━━━━━━┓
     ┃           ▇ ┃
     ┃             ┃ 1h === (containerRatio * 1w)
     ┃             ┃
     ┗━━━━━━━━━━━━━┛
   */
  }
  const right = Array.isArray(containerRatio)
    ? containerRatio.map(calcRatioValue)
    : calcRatioValue(containerRatio)

  return (
    <BackgroundImage
      css={`
        max-width: ${rem(100)};
        min-width: ${rem(40)};
        pointer-events: none;
      `}
      bg="transparent"
      position="absolute"
      ratio={ratio}
      right={right}
      top="4%"
      url={url}
      width="10%"
      zIndex={1}
      {...props}
    />
  )
}
Badge.defaultProps = {
  containerRatio: 1 / 1,
  ratio: 1 / 1
}
Badge.propTypes = {
  url: PropTypes.string.isRequired
}

export default Badge
