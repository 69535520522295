import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'emotion-theming'
import Anchor from 'partials/anchor'
import styles from './style'

const Button = ({
  children,
  disabled,
  fullWidth,
  handleClick,
  small,
  url,
  theme,
  customStyle = {},
  datacy = '',
  ...rest
}) =>
  url ? (
    <Anchor
      className={styles.root(fullWidth, small, theme, customStyle)}
      url={url}
      data-cy={datacy}
      {...rest}
    >
      {children}
    </Anchor>
  ) : (
    <button
      data-cy={datacy}
      className={styles.root(fullWidth, small, theme, customStyle)}
      disabled={disabled}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </button>
  )

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleClick: PropTypes.func,
  small: PropTypes.bool,
  url: PropTypes.string
}

export default withTheme(Button)
