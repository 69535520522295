import { useState } from 'react'
import PropTypes from 'prop-types'
import { bugsnag } from '_utility/error-boundary'
import {
  api,
  getMessage,
  getSessionData,
  POST,
  isRepeatSignup
} from 'utils/telemetry'
import generateToken from 'utils/telemetry/generate-token'
import { trackEvent } from 'utils/analytics-trackers'

const TelemetrySingleSignUp = ({
  verticalId,
  children,
  customMessages = {},
  onSuccess = () => null,
  onRepeatSignup = () => null,
  origin,
  campaign,
  internalCTA,
  medium,
  source
}) => {
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('ready')
  const [message, setMessage] = useState('')
  const [secretId, setSecretId] = useState('')

  const setVerticalSubscription = () => {
    setStatus('loading')

    // create payload to match Telemetry's API spec
    const payload = {
      referrer: getSessionData({
        campaign,
        internalCta: internalCTA,
        medium,
        source
      }),
      verticalId,
      email
    }

    // call to sign up API, passing token
    POST(api.signupSingle, { ...payload, token: generateToken(email) })
      .then(res => {
        trackEvent(
          'Add new subscriber',
          origin || 'Email Inline Signup',
          `Subscribed to ${verticalId} from ${internalCTA}`
        )
        setStatus('success')
        // run custom onSuccess logic
        onSuccess()
        // keep secretId for preference edits if applicable
        setSecretId(res.data.secretID)
        setMessage(customMessages.success || getMessage(res.status))
      })
      .catch(err => {
        // handle the special case of '409': repeat signup
        if (isRepeatSignup(err.message)) {
          trackEvent(
            'Repeat Signup',
            origin || 'Email Inline Signup',
            `Repeat subscribe to ${verticalId} from ${internalCTA}`
          )
          setStatus('repeat')
          setMessage(customMessages.repeat || getMessage(err.message))
          onRepeatSignup()
        } else {
          trackEvent(
            'Subscription error',
            origin || 'Email Inline Signup',
            `${status}: ${getMessage(status)}`
          )

          setStatus('fail')
          setMessage(customMessages.fail || getMessage(err.message))
        }

        bugsnag.notify(err)
      })
  }

  const handleInputChange = e => {
    e.preventDefault()
    setEmail(e.target.value)
  }
  const handleFormSubmit = async e => {
    e.preventDefault()
    setVerticalSubscription()
  }

  return children({
    handleInputChange: handleInputChange,
    handleFormSubmit: handleFormSubmit,
    email,
    status,
    message,
    secretId
  })
}

TelemetrySingleSignUp.propTypes = {
  children: PropTypes.func.isRequired,
  verticalId: PropTypes.number.isRequired
}

export default TelemetrySingleSignUp
