import loadable from '@loadable/component'
import { layout } from 'style/tokens'
import FondueSlot from 'partials/fondue-slot'
import { isLongform, isSalesPage } from 'utils/croissant'

import Button from 'widgets/button'
import Divider from 'widgets/divider'
import Embed from 'widgets/embed'
import Heading from 'widgets/heading'
import Html from 'widgets/html'
import Image from 'widgets/image'
import Listicle from 'widgets/listicle'
import Missing from 'widgets/_missing'
import Paragraph from 'widgets/paragraph'
import PullQuote from 'widgets/pull-quote'
import RelatedArticles from 'widgets/related-articles'
import LoopingVideo from 'widgets/looping-video'
import Faq from 'widgets/faq'
import Ticket from 'widgets/ticket'
import CountdownTimer from 'widgets/countdown-timer'
import SolidColorQuote from 'widgets/solid-color-quote'
import LineDivider from 'widgets/line-divider'
import HorizontalListicle from 'widgets/horizontal-listicle'
import FreeText from 'widgets/free-text'
import ImageCollection from 'widgets/image-collection'

const InteractiveImage = loadable(() => import('widgets/interactive-image'))
const NewsletterSignUp = loadable(() => import('widgets/newsletter-sign-up'))
const ProductCollection = loadable(() => import('widgets/product-collection'))
const TelemetryAcquisition = loadable(() =>
  import('widgets/telemetry-acquisition')
)

export const postTypes = {
  LONGFORM: 'longform',
  STANDARD: 'standard',
  SALESPAGE: 'salespage'
}

export const layouts = {
  INTERACTIVE_IMAGE: 'interactive_image',
  PRODUCT_CAROUSEL: 'product-carousel',
  EMBED: 'embed',
  FONDUE: 'fondue',
  SLIDER: 'slider',
  PARAGRAPH: 'paragraph',
  HEADING: 'heading'
}

export const layoutInteractions = {
  PARALLAX: 'parallax',
  DIPPY: 'dippy',
  TRIPPY: 'trippy',
  GRID: 'grid'
}

export const widths = {
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA_LARGE: 'extra_large',
  FULL: 'full'
}

export const orientations = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait'
}

export const getView = ({ acf_fc_layout }) => {
  const views = {
    button: Button,
    embed: Embed,
    heading: Heading,
    divider: Divider,
    'horizontal-rule': Divider,
    html: Html,
    image: Image,
    interactive_image: InteractiveImage,
    listicle: Listicle,
    looping_video: LoopingVideo,
    signup: NewsletterSignUp,
    newsletter_signup: NewsletterSignUp,
    paragraph: Paragraph,
    'product-carousel': ProductCollection,
    'pull-quote': PullQuote,
    telemetry_acquisition: TelemetryAcquisition,
    fondue: FondueSlot,
    related_articles: RelatedArticles
  }
  return views[acf_fc_layout] || Missing
}

export const getSalesPageView = ({ acf_fc_layout }) => {
  const views = {
    embed: Embed,
    button: Button,
    heading: Heading,
    paragraph: FreeText,
    faqs_generator: Faq,
    'pull-quote': SolidColorQuote,
    countdown: CountdownTimer,
    ticket: Ticket,
    divider: LineDivider,
    listicle: HorizontalListicle,
    'images-repeater': ImageCollection
  }
  return views[acf_fc_layout] || Missing
}

export const getWidth = (widget, postType) => {
  const { acf_fc_layout, width = widths.MEDIUM } = widget

  let widthDimension = width

  const widgetWidths = {
    small: layout.trunk.xs,
    medium: layout.trunk.sm,
    large: layout.trunk.md,
    extra_large: layout.trunk.lg,
    full: '100%'
  }

  if (
    acf_fc_layout === layouts.INTERACTIVE_IMAGE ||
    acf_fc_layout === layouts.PRODUCT_CAROUSEL
  ) {
    widthDimension = widths.LARGE
  }

  if (isSalesPage(postType)) {
    acf_fc_layout === 'countdown' ||
    acf_fc_layout === 'pull-quote' ||
    acf_fc_layout === 'images-repeater'
      ? (widthDimension = widths.EXTRA_LARGE)
      : (widthDimension = widths.LARGE)
  }

  // this is hardcoded to ensure proper width of the embed paragraph because,
  // for some reason, embed is the only widget w/ its own paragraph coming from the CMS
  if (acf_fc_layout === layouts.EMBED) {
    widthDimension = widths.MEDIUM
  }

  return widgetWidths[widthDimension]
}

export const shouldHaveFullWidth = (postType, layout, layoutInteraction) =>
  isLongform(postType) &&
  (layout === layouts.INTERACTIVE_IMAGE &&
    (layoutInteraction === layoutInteractions.PARALLAX ||
      layoutInteraction === layoutInteractions.DIPPY ||
      layoutInteraction === layoutInteractions.TRIPPY ||
      layoutInteraction === layoutInteractions.GRID))
