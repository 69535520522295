import { css } from 'emotion'

const form = theme => css`
  height: min-content;
  position: relative;
  font-family: ${theme.fonts.HurmeGeometricSans3};
  &:after {
    content: '';
    position: absolute;
    bottom: -9px;
    left: 0;
    width: calc(100% - 64px);
    height: 2px;
    background: ${theme.colors.textAccentColour};
  }
`

const icon = css`
  margin: 0 15px 0 0;
`

const submitButton = theme => css`
  position: absolute;
  right: 0;
  height: 33px;
  border: none;
  background: ${theme.colors.textAccentColour};
  width: 50px;
  text-transform: uppercase;
  color: ${theme.colors.primary};
  font-size: 12px;
  font-family: inherit;
  padding: 4px 0 0 0;
`

const inputField = css`
  background: none;
  border: none;
  color: currentColor;
  font-family: inherit;
  font-size: 14px;
  padding: 4px 0;
  width: 147px;
  outline: none;

  &::placeholder {
    color: currentColor;
  }
`

export default { form, icon, submitButton, inputField }
