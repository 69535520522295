import parseuri from 'parseUri'

const YTDomains = ['www.youtube.com', 'youtu.be']

export default {
  type: 'youtube',
  testType: url => YTDomains.includes(parseuri(url).host),
  extractData: ({ embed_link }) => {
    const match = RegExp(
      '(?:youtube(?:-nocookie)?.com/(?:[^/]+/.+/|(?:v|e(?:mbed)?)/|.*[?&]v=)|youtu.be/)([^"&?/ ]{11})',
      'g'
    ).exec(embed_link)
    return { id: Array.isArray(match) ? match[1] : null }
  }
}
