/**
 * Event object example:
 *
 * ga('send', {
    hitType: 'event',
    eventCategory: 'Videos',
    eventAction: 'play',
    eventLabel: 'Fall Campaign',
    nonInteraction: true
  });
 */
export const trackEvent = (
  eventAction,
  eventCategory,
  eventLabel,
  nonInteraction
) => {
  // cancel event tracking if all required fields are missing
  if (!eventAction || !eventCategory || !eventLabel) return null

  if (typeof window !== 'undefined' && window.ga) {
    const options = {
      eventCategory,
      eventAction,
      eventLabel
    }

    if (nonInteraction) {
      options.nonInteraction = Boolean(nonInteraction)
    }
    // window.ga_debug = { trace: true }
    window.ga('send', {
      hitType: 'event',
      ...options
    })
  }
}

export const trackPageView = () => {
  if (typeof window !== 'undefined' && window.ga) {
    window.ga('send', 'pageview', window.location.pathname)
  }
}

export const trackPermutiveEvent = platform => {
  // cancel if permutive not running
  if (!window.permutive || typeof window.permutive.track !== 'function') {
    return
  }

  window.permutive.track('interaction', {
    name: `${platform} share`,
    type: 'click'
  })
}

export const trackPermutiveSubscription = args => {
  // cancel if permutive not running
  if (!window.permutive || typeof window.permutive.track !== 'function') {
    return
  }

  window.permutive.track('subscription', args)
}

export const trackUserPermutiveEvent = args => {
  if (!window.permutive || typeof window.permutive.track !== 'function') {
    return
  }

  window.permutive.track('member', args)
}

export const trackPurchase = args => {
  if (typeof window !== 'undefined' && window.ga) {
    window.ga('ecommerce:addTransaction', args)
    window.ga('ecommerce:send')
  }
}
