import React from 'react'
import PropTypes from 'prop-types'

import Button from 'partials/button'

import styles from './style'

const WidgetButton = ({ url, label, ...rest }) =>
  url && label ? (
    <div className={styles.root}>
      <Button url={url} {...rest}>
        {label}
      </Button>
    </div>
  ) : null

WidgetButton.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string
}

export default WidgetButton
