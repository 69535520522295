import React from 'react'
import parseuri from 'parseUri'
import PropTypes from 'prop-types'

import linkToAbsolute from 'utils/link-to-absolute'
import { isSponsored } from 'utils/croissant'

// expect array of domains
// [staging.something.com,something.com,something.herokuapp.com]
const domains = (__DOMAINS__ || '').split(',')

/*
  <Anchor url="/somewhere" className={styles.link} title="something">
    {'Text or component'}
  </Anchor>
*/
const Anchor = ({ children, forceAbsolute, url, postType, ...rest }) => {
  // we shouldn't render a `<a>` element without a URL
  if (!url) return null

  let href = url

  // treat all other props as DOM attributes
  // we need to assign this here as we may modify it later
  const attributes = { ...rest }
  const isAbsolute = /^https?:\/\//i.test(url)
  const isMailTo = /^mailto:/i.test(url)
  const uri = parseuri(url)
  // test if the hostname is not one of the "internal" domains we use
  // e.g. staging.something.com
  const isExternal = isAbsolute && domains.every(domain => uri.host !== domain)
  // test if the hostname is one we use for tracking click throughs
  const isTracked = uri.host === __NATIVE_AD_TRACKING_DOMAIN__
  // Check for a sponsored post type
  const isSponsoredPostType = isSponsored(postType)

  if ((forceAbsolute || isExternal) && !isMailTo && !isTracked) {
    // set "external link" attributes to open in a new window
    attributes.target = '_blank'
    attributes.rel = `noopener noreferrer ${
      isSponsoredPostType ? 'sponsored' : ''
    }`.trim()
  }

  if (!isExternal && !isMailTo && !isTracked) {
    // if given url is absolute but an "internal" domain
    // only use the path to treat it as a relative link
    // unless forceAbsolute prop is added
    const relativePath = (!uri.anchor && uri.query.length)
      ? `${uri.path}?${uri.query}`
      : uri.relative

    if (forceAbsolute) {
      if (uri.protocol && uri.host) {
        href = `${uri.protocol}://${uri.host}${relativePath}`
      } else {
        href = linkToAbsolute(relativePath)
      }
    } else {
      href = relativePath
    }
  }

  return (
    <a href={href} {...attributes}>
      {children}
    </a>
  )
}

Anchor.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  forceAbsolute: PropTypes.bool,
  url: PropTypes.string
}

export default Anchor
