import { css } from 'emotion'
import media from 'style/utils/media'
import * as blocks from 'style/blocks'
import { color, layout, type } from 'style/tokens'
import rem from 'style/utils/rem'

const root = props =>
  css(
    blocks.type.pica,
    {
      color: color.tonal[5],
      marginBottom: layout.sizes.md,
      textAlign: 'center',
      width: '100%',
      fontWeight: type.weight.semibold,
      letterSpacing: rem(4, true)
    },
    media(layout.mq.smUp, {
      textAlign: props.alignment
    }),
    props.xlarge && { fontSize: type.sizes.lg },
    props.large && { fontSize: type.sizes.md },
    props.small && { fontSize: type.sizes.xs },
    props.highlight && { color: color.core.primary },
    props.customStyle
  )

export default {
  root
}
