import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'design-system'

const FillViewport = ({ children, disable }) => {
  const [scrollBarWidth, setScrollBarWidth] = useState(0)

  useEffect(() => {
    if (typeof window !== undefined) {
      const currentScrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth
      if (currentScrollbarWidth > 0) setScrollBarWidth(currentScrollbarWidth)
    }
  })

  return disable ? (
    children
  ) : (
    <Box
      ml={[
        `calc(-50vw + 50%)`,
        null,
        `calc(-50vw + 50% + ${scrollBarWidth / 2}px)`
      ]}
      width={['100vw', null, `calc(100vw - ${scrollBarWidth}px)`]}
    >
      {children}
    </Box>
  )
}

FillViewport.propTypes = {
  children: PropTypes.node.isRequired,
  disable: PropTypes.bool
}

FillViewport.defaultProps = {
  disable: false
}

export default FillViewport
