import { css } from 'emotion'
import * as blocks from 'style/blocks'
import { color, layout, type } from 'style/tokens'
import rem from 'style/utils/rem'
import media from 'style/utils/media'

const root = css({
  display: 'flex',
  height: '100vh'
})

const content = css({
  margin: 'auto',
  maxWidth: rem(500),
  textAlign: 'center',
  padding: rem(20)
})

const logo = css({
  marginBottom: layout.sizes.xl,
  maxWidth: rem(140)
})

const title = css(
  blocks.type.canon,
  {
    color: color.core.primary,
    fontSize: type.sizes.lg,
    marginBottom: layout.sizes.lg
  },
  media(layout.mq.smUp, {
    fontSize: type.sizes.xl
  })
)

const body = css(blocks.type.wysiwyg)

export default {
  root,
  content,
  logo,
  title,
  body
}
