import React from 'react'

import style from './style'

const SolidColorQuote = ({ text, quote_author, quote_bg_colour }) => {
  return (
    <blockquote className={style.quote(quote_bg_colour)}>
      <q>{text}</q>
      <cite>{quote_author}</cite>
    </blockquote>
  )
}

export default SolidColorQuote
