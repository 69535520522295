const getUserData = () => {
  if (typeof window === 'undefined') return {}

  if (!window.__ALEXANDRIA__) return {}

  return {
    email: window?.__ALEXANDRIA__?.userData?.email || false,
    firstName: window?.__ALEXANDRIA__?.userData?.firstName || false,
    lastName: window?.__ALEXANDRIA__?.userData?.lastName || false,
    subscriptions: window?.__ALEXANDRIA__.userData?.subscriptions || [],
    memberSince: window?.__ALEXANDRIA__?.userData?.memberSince || null,
    secretId: window?.__ALEXANDRIA__?.userData?.secretId || null,
    isAuthenticated: window?.__ALEXANDRIA__?.isAuthenticated || false,
    adId: window?.__ALEXANDRIA__?.userData?.adId || false
  }
}

export default getUserData
