import { css } from 'emotion'
import * as blocks from 'style/blocks'
import { type, layout, anim } from 'style/tokens'

import { bgImageOverlay } from 'style/utils/background-image'
import media from 'style/utils/media'
import rem from 'style/utils/rem'

const root = css({
  position: 'relative',
  display: 'flex'
})

const singleTitle = css(
  blocks.type.canon,
  {
    transition: `color ${anim.duration.quick} ${anim.easing.initial}`,
    fontWeight: type.weight.semibold,
    fontSize: rem(15),
    lineHeight: 1.3
  },
  media(layout.mq.mdUp, {
    fontSize: rem(21),
    lineHeight: 1.3
  })
)

const content = css(
  {
    paddingLeft: rem(12),
    width: '65%'
  },
  media(layout.mq.mdUp, {
    paddingLeft: `${layout.sizes.md}`
  })
)

const bgrImage = css(
  {
    width: '35%',
    minHeight: rem(90)
  },
  media('(min-width: 25rem)', {
    minHeight: rem(110)
  }),
  media(layout.mq.smUp, {
    minHeight: rem(140)
  }),
  media(layout.mq.mdUp, {
    minHeight: rem(120)
  }),
  media(layout.mq.lgUp, {
    minHeight: rem(130)
  }),
  media('(min-width: 71.75rem)', {
    minHeight: rem(140)
  })
)

const blockLink = css(
  blocks.layout.fill,
  blocks.display.absoluteAnchor,
  {
    zIndex: 1
  },
  blocks.state.interact({
    [`& ~ .${content.toString()} .${singleTitle.toString()}`]: {
      opacity: 0.8
    },
    [`& ~ .${bgImageOverlay.toString()}:after`]: {
      opacity: 1
    }
  })
)

export default {
  root,
  singleTitle,
  content,
  bgImageOverlay,
  bgrImage,
  blockLink
}
